import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchGastos = (id_empresa, id_edificio, selectedYear) => {
  const [loading, setLoading] = useState(true);
  const [gastos, setGastos] = useState([]);
  const [error, setError] = useState(null);

  // Función para distribuir el monto según la frecuencia
  const distribuirMontoPorFrecuencia = (monto, frecuencia) => {
    const meses = 12;
    let distribucion = Array(meses).fill(0);

    switch (frecuencia.toLowerCase()) {
      case 'mensual':
        return distribucion.fill(monto);
      case 'bimensual':
        for (let i = 0; i < meses; i += 2) {
          distribucion[i] = monto;
        }
        return distribucion;
      case 'trimestral':
        for (let i = 0; i < meses; i += 3) {
          distribucion[i] = monto;
        }
        return distribucion;
      case 'semestral':
        distribucion[0] = monto;
        distribucion[6] = monto;
        return distribucion;
      case 'anual':
        distribucion[0] = monto;
        return distribucion;
      default:
        return distribucion;
    }
  };

  // Función para obtener el token de autenticación
  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('Token no encontrado. Por favor, inicia sesión.');
      return null;
    }
    return { headers: { Authorization: `Bearer ${token}` } };
  };

  // Función para obtener datos de la API
  const fetchData = async () => {
    const headers = getAuthHeaders();
    if (!headers) return;

    try {
      setLoading(true);
      const [responseConceptos, responseSubconceptos] = await Promise.all([
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/conceptos`, headers),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/subconceptos`, headers),
      ]);

      const conceptosData = responseConceptos.data;
      const subconceptosData = responseSubconceptos.data;

      // Mapear los datos de conceptos y subconceptos
      const mappedGastos = conceptosData.map(concepto => {
        const relatedSubconceptos = subconceptosData.filter(sub => sub.id_concepto === concepto.id_concepto);
        const subconceptos = relatedSubconceptos.map(sub => {
          const distribucion = distribuirMontoPorFrecuencia(sub.monto_subconcepto, sub.frecuencia_cobro);
          return {
            nombre: sub.nombre_subconcepto,
            valores: {
              Enero: distribucion[0],
              Febrero: distribucion[1],
              Marzo: distribucion[2],
              Abril: distribucion[3],
              Mayo: distribucion[4],
              Junio: distribucion[5],
              Julio: distribucion[6],
              Agosto: distribucion[7],
              Septiembre: distribucion[8],
              Octubre: distribucion[9],
              Noviembre: distribucion[10],
              Diciembre: distribucion[11],
            },
          };
        });
        return {
          concepto: concepto.nombre_concepto,
          subconceptos: subconceptos,
          open: false,
        };
      });

      setGastos(mappedGastos);
      setError(null); // Resetear el error si la solicitud es exitosa
    } catch (error) {
      console.error('Error al obtener los datos:', error);
      if (error.response && error.response.status === 403) {
        setError('No tienes autorización para acceder a estos recursos.');
        localStorage.removeItem('token'); // Eliminar el token si es inválido
      } else {
        setError('Error al obtener los datos de gastos.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Hook para ejecutar fetchData cuando cambien id_empresa, id_edificio o selectedYear
  useEffect(() => {
    if (id_empresa && id_edificio) {
      fetchData();
    }
  }, [id_empresa, id_edificio, selectedYear]);

  // Calcular el total general de los gastos
  const calcularTotalGeneral = () => {
    let totalGeneral = 0;
    gastos.forEach(gasto => {
      gasto.subconceptos.forEach(subconcepto => {
        totalGeneral += Object.values(subconcepto.valores).reduce((acc, curr) => acc + curr, 0);
      });
    });
    return totalGeneral;
  };

  // Calcular los gastos mensuales
  const calcularGastosMensuales = () => {
    return calcularTotalGeneral() / 12;
  };

  return {
    gastos,
    setGastos,
    loading,
    error, // Añadir el estado de error
    calcularTotalGeneral,
    calcularGastosMensuales,
    fetchData,
  };
};

export default useFetchGastos;
