import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, DialogContent, DialogActions, Snackbar, Alert } from '@mui/material';

const FormularioDeudas = ({ idEdificio, departamentos = [], handleClose, onDeudaCreada }) => {
  const [deuda, setDeuda] = useState({
    id_departamento: '',
    fecha_creacion: '',
    fecha_vencimiento: '',
    descripcion: '',
    monto_original: '',
    monto_mora: '',
    estado: 'pendiente',
    monto_pagado: 0,
    saldo_pendiente: '',
    estado_deuda: true
  });

  const [alerta, setAlerta] = useState({
    open: false,
    message: ''
  });

  useEffect(() => {
    const montoTotal = parseFloat(deuda.monto_original || 0) + parseFloat(deuda.monto_mora || 0);
    const saldo = montoTotal - parseFloat(deuda.monto_pagado || 0);
    setDeuda((prevDeuda) => ({
      ...prevDeuda,
      saldo_pendiente: saldo,
      estado_deuda: saldo > 0
    }));
  }, [deuda.monto_original, deuda.monto_mora, deuda.monto_pagado]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDeuda({ ...deuda, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://app.propia.pe/apiRest/deudas/insertar', {
        ...deuda,
        id_edificio: idEdificio
      });
      setAlerta({ open: true, message: 'Deuda agregada correctamente' });
      if (onDeudaCreada) {
        onDeudaCreada(); // Llama a la función pasada como prop para actualizar las deudas
      }
      handleClose();
    } catch (error) {
      console.error('Error al crear la deuda:', error);
      setAlerta({ open: true, message: 'Error al agregar la deuda' });
    }
  };

  const handleCloseAlert = () => {
    setAlerta({ ...alerta, open: false });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Número Departamento</InputLabel>
            <Select
              name="id_departamento"
              value={deuda.id_departamento}
              onChange={handleInputChange}
              required
            >
              {departamentos.map((departamento, index) => (
                <MenuItem key={index} value={departamento.id_departamento}>
                  {departamento.numero_departamento}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            name="fecha_creacion"
            label="Fecha de Creación"
            type="date"
            value={deuda.fecha_creacion}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
          />

          <TextField
            name="fecha_vencimiento"
            label="Fecha de Vencimiento"
            type="date"
            value={deuda.fecha_vencimiento}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
          />

          <TextField
            name="descripcion"
            label="Descripción"
            value={deuda.descripcion}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />

          <TextField
            name="monto_original"
            label="Monto Original"
            type="number"
            value={deuda.monto_original}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />

          <TextField
            name="monto_mora"
            label="Monto Mora"
            type="number"
            value={deuda.monto_mora}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />

          <TextField
            name="monto_pagado"
            label="Monto Pagado"
            type="number"
            value={deuda.monto_pagado}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />

          <TextField
            name="saldo_pendiente"
            label="Saldo Pendiente"
            type="number"
            value={deuda.saldo_pendiente}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type="submit" color="primary">Crear Deuda</Button>
        </DialogActions>
      </form>

      <Snackbar open={alerta.open} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%' }}>
          {alerta.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default FormularioDeudas;
