import React, { useRef, useEffect, useState } from 'react';
import Handsontable from 'handsontable';
import 'handsontable/dist/handsontable.full.css';
import axios from 'axios';
import { Box, Button } from "@mui/material";
import Header from "../../components/Header";

const RegistroPropietariosVarios = ({ idEdificio,fetchData  }) => {
    const hotTableRef = useRef(null);
    const [hotInstance, setHotInstance] = useState(null);

    useEffect(() => {
        const initialData = new Array(15).fill(0).map(() => ['', '', '', '', '', '', '', '']);

        const hot = new Handsontable(hotTableRef.current, {
            data: initialData,
            rowHeaders: true,
            colHeaders: [
                'Nombre', 'Apellido', 'DNI', 'Email', 'Celular', 'Tipo', 'Fecha de Nacimiento', 'Género'
            ],
            columns: [
                {}, {}, {}, {}, {}, {}, {}, {}
            ],
            dropdownMenu: true,
            filters: true,
            manualRowResize: true,
            manualColumnResize: true,
            contextMenu: true,
            stretchH: 'all',
            width: '100%',
            height: 500,
            minRows: 15,
            minCols: 8,
            licenseKey: 'non-commercial-and-evaluation'
        });

        setHotInstance(hot);

        return () => {
            hot.destroy();
        };
    }, []);

    const handleSaveData = async () => {
        const dataToSave = hotInstance.getData();
        try {
            for (let row of dataToSave) {
                if (!row[0]) continue; // Si no hay nombre, no intentes guardar esa fila

                // Asegúrate de que la fecha esté en el formato correcto 'yyyy-MM-dd'
                let fechaNacimiento = row[6];
                if (fechaNacimiento.includes('/')) {
                    // Si la fecha contiene '/', reemplazar por '-'
                    fechaNacimiento = fechaNacimiento.split('/').reverse().join('-');
                }

                const propietarioData = {
                    id_edificio: idEdificio, // Utiliza el id_edificio pasado como prop
                    nombre_propietario: row[0],
                    apellido_propietario: row[1],
                    dni_propietario: row[2],
                    email_propietario: row[3],
                    celular_propietario: row[4],
                    tipo_propietario: row[5],
                    fecha_nacimiento: fechaNacimiento, // Usa la fecha formateada
                    genero_propietario: row[7],
                };

                await axios.post('https://app.propia.pe/apiRest/propietarios/insertar', propietarioData);
            }
            alert('Propietarios agregados exitosamente');
            // Llama a fetchData para obtener los datos actualizados después de agregar
            fetchData();
        } catch (error) {
            console.error('Error al agregar los propietarios:', error.response ? error.response.data : error.message);
        }
    };
    
    
    return (
        <Box m="20px">
            <Header title="Registro de Propietarios Varios" subtitle="Añadir múltiples propietarios" />
            <div ref={hotTableRef}></div>
            <Button variant="contained" onClick={handleSaveData} style={{ marginTop: '20px' }}>
                Guardar Propietarios
            </Button>
        </Box>
    );
};

export default RegistroPropietariosVarios;
