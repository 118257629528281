import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTheme, TextField, Button, Container, Typography, Box, Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const FormularioSubconceptos = ({ id_empresa, id_edificio, onSuccess }) => {
  const [nombreSubconcepto, setNombreSubconcepto] = useState('');
  const [frecuenciaCobro, setFrecuenciaCobro] = useState('');
  const [montoSubconcepto, setMontoSubconcepto] = useState('');
  const [conceptos, setConceptos] = useState([]);
  const [selectedConcepto, setSelectedConcepto] = useState('');
  const theme = useTheme();

  useEffect(() => {
    const fetchConceptos = async () => {
      if (!id_empresa || !id_edificio) {
        console.error("ID de empresa o edificio no definido");
        return;
      }

      const token = localStorage.getItem('token');
      if (!token) {
        alert('Token de autorización no encontrado. Por favor, inicia sesión.');
        return;
      }

      try {
        const response = await axios.get(
          `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/conceptos`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        setConceptos(response.data);
      } catch (error) {
        console.error("Error al obtener los conceptos:", error);
        if (error.response?.status === 403) {
          alert('No tienes permiso para ver los conceptos. Verifica tu token.');
        }
      }
    };

    fetchConceptos();
  }, [id_empresa, id_edificio]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!id_empresa || !id_edificio) {
      alert('ID de empresa o de edificio no está definido.');
      return;
    }

    if (!frecuenciaCobro) {
      alert('Por favor, seleccione una frecuencia de cobro.');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      alert('Token de autorización no encontrado. Por favor, inicia sesión.');
      return;
    }

    try {
      await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/subconceptos/insertar`,
        {
          id_concepto: selectedConcepto,
          id_empresa,
          id_edificio,
          nombre_subconcepto: nombreSubconcepto,
          frecuencia_cobro: frecuenciaCobro,
          monto_subconcepto: montoSubconcepto
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      alert('Subconcepto agregado exitosamente!');
      setNombreSubconcepto('');
      setFrecuenciaCobro('');
      setMontoSubconcepto('');
      setSelectedConcepto('');
      if (onSuccess) onSuccess(); // Llamar a la función de éxito para actualizar la lista
    } catch (error) {
      console.error("Error al agregar el subconcepto:", error);
      if (error.response?.status === 403) {
        alert('No tienes permiso para agregar subconceptos. Verifica tu token.');
      } else {
        alert('Hubo un error al agregar el subconcepto. Por favor, intenta nuevamente.');
      }
    }
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>Agregar Subconcepto</Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={3}>
          <TextField
            fullWidth
            label="Nombre del Subconcepto"
            variant="outlined"
            value={nombreSubconcepto}
            onChange={(e) => setNombreSubconcepto(e.target.value)}
            required
            style={{
              backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100]
            }}
            InputProps={{
              style: {
                color: theme.palette.mode === 'dark' ? theme.palette.grey[100] : theme.palette.grey[900]
              }
            }}
            InputLabelProps={{
              style: {
                color: theme.palette.mode === 'dark' ? theme.palette.grey[100] : theme.palette.grey[900]
              }
            }}
          />
        </Box>

        <Box mb={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Frecuencia de Cobro</InputLabel>
            <Select
              value={frecuenciaCobro}
              onChange={(e) => setFrecuenciaCobro(e.target.value)}
              label="Frecuencia de Cobro"
              required
            >
              <MenuItem value="">
                <em>Seleccione la frecuencia</em>
              </MenuItem>
              <MenuItem value="mensual">Mensual</MenuItem>
              <MenuItem value="bimensual">Bimensual</MenuItem>
              <MenuItem value="trimestral">Trimestral</MenuItem>
              <MenuItem value="semestral">Semestral</MenuItem>
              <MenuItem value="anual">Anual</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box mb={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Concepto</InputLabel>
            <Select
              value={selectedConcepto}
              onChange={(e) => setSelectedConcepto(e.target.value)}
              label="Concepto"
              required
            >
              <MenuItem value="">
                <em>Seleccione un concepto</em>
              </MenuItem>
              {conceptos.map(concepto => (
                <MenuItem key={concepto.id_concepto} value={concepto.id_concepto}>
                  {concepto.nombre_concepto}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box mb={3}>
          <TextField
            fullWidth
            label="Monto del Subconcepto"
            variant="outlined"
            type="number"
            value={montoSubconcepto}
            onChange={(e) => setMontoSubconcepto(e.target.value)}
            required
            style={{
              backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100]
            }}
            InputProps={{
              style: {
                color: theme.palette.mode === 'dark' ? theme.palette.grey[100] : theme.palette.grey[900]
              }
            }}
            InputLabelProps={{
              style: {
                color: theme.palette.mode === 'dark' ? theme.palette.grey[100] : theme.palette.grey[900]
              }
            }}
          />
        </Box>

        <Box display="flex" justifyContent="center">
          <Button type="submit" variant="contained" color="primary">Agregar</Button>
        </Box>
      </form>
    </Container>
  );
};

export default FormularioSubconceptos;
