import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchMonthlyExpenses = (id_edificio, selectedMonth, selectedYear) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const response = await axios.get(`https://app.propia.pe/apiRest/gastos_mensuales/obtener`, {
                params: {
                    id_edificio, // Asegúrate de que este es el nombre correcto del parámetro que tu backend espera
                    month: selectedMonth,
                    year: selectedYear
                }
            });
            const filteredData = response.data.map((gasto, index) => ({
                id: `gasto-${index + 1}`, // Esto es solo para proporcionar un ID único a cada fila para el DataGrid
                concepto: gasto.concepto_gasto_mensual,
                fecha: gasto.fecha_gasto_mensual,
                monto: gasto.importe_gasto_mensual
            }));
            setData(filteredData);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id_edificio, selectedMonth, selectedYear]);

    return { data, loading };
};

export default useFetchMonthlyExpenses;
