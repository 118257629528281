import React, { useState, useEffect } from "react";
import axios from "axios";
import './AdminConsole.css';

const AdminConsole = () => {
    const [empresas, setEmpresas] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("token");

            if (!token) {
                setError("No se encontró un token de autenticación. Por favor, inicie sesión nuevamente.");
                console.log("No token found");
                setLoading(false);
                return;
            }

            console.log("Token found:", token);

            const headers = { Authorization: `Bearer ${token}` };
            console.log("Headers being sent:", headers);

            try {
                const [empresasRes, usuariosRes] = await Promise.all([
                    axios.get("https://app.propia.pe/apiRest/empresas/obtener", { headers }),
                    axios.get("https://app.propia.pe/apiRest/usuarios/obtener", { headers })
                ]);

                console.log("Empresas response:", empresasRes.data);
                console.log("Usuarios response:", usuariosRes.data);

                setEmpresas(empresasRes.data);
                setUsuarios(usuariosRes.data);
                setLoading(false);
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    setError("La sesión ha expirado. Por favor, inicie sesión nuevamente.");
                    localStorage.removeItem("token");
                    setTimeout(() => {
                        window.location.href = "/iniciar-sesion";
                    }, 3000);
                } else {
                    const errorMessage = err.response ? err.response.data.message || err.response.statusText : err.message;
                    console.error("Error in fetchData:", errorMessage);
                    setError("Error al cargar los datos. Verifica tu conexión o inicia sesión de nuevo.");
                }
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleCrearEmpresa = () => {
        // Lógica para crear empresa
    };

    const handleCrearUsuario = () => {
        // Lógica para crear usuario
    };

    if (loading) {
        return (
            <div className="dashboard-loading">
                <div className="spinner"></div>
                <p>Cargando datos...</p>
            </div>
        );
    }

    if (error) {
        return <div className="dashboard-error">{error}</div>;
    }

    // Filtrar usuarios sin empresa (ROLE_ADMIN_MAESTRO)
    const usuariosSinEmpresa = usuarios.filter(usuario => usuario.id_empresa === null);

    return (
        <div className="admin-dashboard">
            <h1 className="dashboard-title">Consola de Administración</h1>

            <section className="company-section">
                <h2 className="dashboard-section-title">Empresas</h2>
                <button onClick={handleCrearEmpresa} className="dashboard-btn" aria-label="Crear nueva empresa">
                    Crear Nueva Empresa
                </button>
                <ul className="dashboard-list">
                    {empresas.map(empresa => (
                        <li key={empresa.id_empresa} className="dashboard-item">
                            {empresa.nombre_empresa} - {empresa.email_empresa}
                            <ul>
                                {usuarios
                                    .filter(usuario => usuario.id_empresa === empresa.id_empresa)
                                    .map(usuario => (
                                        <li key={usuario.id_usuario}>
                                            {usuario.nombre_usuario} - {usuario.email} ({usuario.rol})
                                        </li>
                                    ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </section>

            <section className="user-section">
                <h2 className="dashboard-section-title">Usuarios</h2>
                <button onClick={handleCrearUsuario} className="dashboard-btn" aria-label="Crear nuevo usuario">
                    Crear Nuevo Usuario
                </button>
                <ul className="dashboard-list">
                    {usuariosSinEmpresa.length > 0 && (
                        <div>
                            <h3>Usuarios sin empresa</h3>
                            {usuariosSinEmpresa.map(usuario => (
                                <li key={usuario.id_usuario} className="dashboard-item">
                                    {usuario.nombre_usuario} - {usuario.email} ({usuario.rol})
                                </li>
                            ))}
                        </div>
                    )}
                </ul>
            </section>
        </div>
    );
};

export default AdminConsole;
