import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";

const EliminarPersonal = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="eliminar-personal-dialog-title"
      aria-describedby="eliminar-personal-dialog-description"
    >
      <DialogTitle id="eliminar-personal-dialog-title">Confirmar Eliminación</DialogTitle>
      <DialogContent>
        <DialogContentText id="eliminar-personal-dialog-description">
          ¿Estás seguro de que quieres eliminar este registro? Esta acción no se puede deshacer.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={onConfirm} color="secondary" variant="contained">
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EliminarPersonal;
