import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import axios from "axios";
import FormularioDepositos from "./FormularioDepositos"; // Asegúrate de que la ruta de importación sea correcta

const Depositos = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id_edificio, id_empresa } = useParams(); // Obtener id_empresa e id_edificio
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false); // Estado para controlar la apertura del diálogo

  useEffect(() => {
    fetchData();
  }, [id_edificio]);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token'); // Obtener el token del localStorage

      // Realizar la solicitud con axios y los headers adecuados
      const response = await axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/depositos`, {
        headers: { Authorization: `Bearer ${token}` }, // Pasar el token en los headers
      });

      const depositosData = response.data.map((deposito, index) => ({
        ...deposito,
        id: deposito.id_deposito, // Asegúrate de que el campo "id" esté bien definido para el DataGrid
      }));

      setData(depositosData);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSuccess = () => {
    fetchData();
    handleClose();
  };

  const columns = [
    { field: 'numero_deposito', headerName: 'Número', flex: 1 },
    { field: 'piso_deposito', headerName: 'Piso', flex: 1 },
    { field: 'area_deposito', headerName: 'Área (m2)', flex: 1 },
  ];

  return (
    <Box m="20px">
      <Header title="Depósitos" subtitle="Lista de los depósitos del edificio" />
      <Button 
        variant="contained" 
        onClick={handleOpen} 
        style={{ backgroundColor: colors.blueAccent[700], marginBottom: '20px' }}
      >
        Agregar Depósito
      </Button>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            fontSize: "13px",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <FormularioDepositos onClose={handleClose} idEdificio={parseInt(id_edificio)} onSubmitSuccess={handleSuccess} />
      </Dialog>
    </Box>
  );
};

export default Depositos;
