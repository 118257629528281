import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";

const Areas = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id_edificio } = useParams();
  const [asignacionData, setAsignacionData] = useState([]);
  

  

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://app.propia.pe/apiRest/asignacion/obtener"
      );
      const data = response.data.filter(
        (asignacion) => asignacion.id_edificio === parseInt(id_edificio)
      );

      const asignacionData = await getAsignacionData(data);
      setAsignacionData(asignacionData);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [id_edificio]);

  const getAsignacionData = async (data) => {
    try {
      const responsePropietarios = await axios.get(
        "https://app.propia.pe/apiRest/propietarios/obtener"
      );
      const responseDepartamentos = await axios.get(
        "https://app.propia.pe/apiRest/departamentos/obtener"
      );
      const responseCocheras = await axios.get(
        "https://app.propia.pe/apiRest/cocheras/obtener"
      );
      const responseDepositos = await axios.get(
        "https://app.propia.pe/apiRest/depositos/obtener"
      );

      const propietarios = responsePropietarios.data;
      const departamentos = responseDepartamentos.data;
      const cocheras = responseCocheras.data;
      const depositos = responseDepositos.data;

      const asignacionData = data.map((asignacion, index) => {
        const id_propietario = asignacion.id_propietario;
        const id_departamento = asignacion.id_departamento;
        const id_cochera = asignacion.id_cochera;
        const id_deposito = asignacion.id_deposito;

        const propietario = propietarios.find(
          (p) => p.id_propietario === id_propietario
        );

        const departamento = departamentos.find(
          (d) => d.id_departamento === id_departamento
        );

        const cochera = cocheras.find((c) => c.id_cochera === id_cochera);

        const deposito = depositos.find((d) => d.id_deposito === id_deposito);

        const nombre_propietario = propietario
          ? propietario.nombre_propietario
          : "N/A";
        const apellido_propietario = propietario
          ? propietario.apellido_propietario
          : "N/A";

        const numero_departamento = departamento
          ? departamento.numero_departamento
          : "N/A";

        const area = departamento ? departamento.area : 0;

        const numero_cochera = cochera ? cochera.numero_cochera : "N/A";

        const area_cochera = cochera ? cochera.area_cochera : 0;

        const numero_deposito = deposito ? deposito.numero_deposito : "N/A";

        const area_deposito = deposito ? deposito.area_deposito : 0;

        const area_total = area + area_cochera + area_deposito;
        

        return {
          ...asignacion,
          id: index + 1,
          nombre_propietario,
          apellido_propietario,
          numero_departamento,
          area,
          numero_cochera,
          area_cochera,
          numero_deposito,
          area_deposito,
            area_total,
        };
      });

      return asignacionData;
    } catch (error) {
      console.error(
        "Error al obtener los propietarios, departamentos, cocheras y depósitos:",
        error
      );
      return [];
    }
  };

  const totalAreas = asignacionData.reduce(
    (total, asignacion) => total + parseFloat(asignacion.area_total),
    0
    
  );
  
    

  console.log(totalAreas);


  const columns = [
    { field: "numero_departamento", headerName: "Departamento", flex: 1 },
    { field: "numero_cochera", headerName: "Cochera", flex: 1 },
    { field: "numero_deposito", headerName: "Depósito", flex: 1 },
    { field: "nombre_propietario", headerName: "Nombre del Propietario", flex: 1 },
    { field: "apellido_propietario", headerName: "Apellido del Propietario", flex: 1 },
    { field: "area", headerName: "Área del Departamento", flex: 1 },
    { field: "area_cochera", headerName: "Área de la Cochera", flex: 1 },
    { field: "area_deposito", headerName: "Área del Depósito", flex: 1 },
     { field: "area_total", headerName: "Área total", flex: 1 },
  ];

  return (
    <Box m="20px">
      <Header title="Areas" subtitle="Lista de las asignaciones del edificio" />

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={1}
        bgcolor={colors.blueAccent[700]}
        color="black"
        borderRadius={2}
        mt={2}
        width="150px"
      >
        <Box variant="h6">TOTAL ÁREAS: {totalAreas.toFixed(2)}</Box>
      </Box>
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            fontSize: "13px",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={asignacionData}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
        

       

        
      </Box>
      
    </Box>
  );
};

export default Areas;
