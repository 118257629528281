import React, { useState } from "react";
import { Box, TextField, Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import axios from "axios";

const RegistroPropietario = ({ idEdificio, fetchData }) => { // Recibiendo idEdificio y fetchData como props

  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [dni, setDni] = useState("");
  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");
  const [tipo, setTipo] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [genero, setGenero] = useState("");
  const [estado, setEstado] = useState(true);

  const handleRegistro = async (e) => {
    e.preventDefault();

    const propietarioData = {
      id_edificio: idEdificio,
      nombre_propietario: nombre,
      apellido_propietario: apellido,
      dni_propietario: dni,
      email_propietario: email,
      celular_propietario: celular,
      tipo_propietario: tipo,
      fecha_nacimiento: fechaNacimiento,
      genero_propietario: genero,
      estado: estado,
    };

    try {
      await axios.post("https://app.propia.pe/apiRest/propietarios/insertar", propietarioData);
      resetFields();
      alert("Propietario agregado exitosamente");
      fetchData(); // Llama a fetchData para actualizar los datos en el componente Propietarios
    } catch (error) {
      console.error("Error al agregar el propietario:", error);
    }
  };

  const resetFields = () => {
    setNombre("");
    setApellido("");
    setDni("");
    setEmail("");
    setCelular("");
    setTipo("");
    setFechaNacimiento("");
    setGenero("");
    setEstado(true);
  };

  return (
    <Box m="20px">
      <h2>Registro de Propietario</h2>
      <form onSubmit={handleRegistro}>
        <TextField
          label="Nombre"
          variant="outlined"
          fullWidth
          required
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Apellido"
          variant="outlined"
          fullWidth
          required
          value={apellido}
          onChange={(e) => setApellido(e.target.value)}
          margin="normal"
        />
        <TextField
          label="DNI"
          variant="outlined"
          fullWidth
          required
          value={dni}
          onChange={(e) => setDni(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Celular"
          variant="outlined"
          fullWidth
          required
          value={celular}
          onChange={(e) => setCelular(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Tipo"
          variant="outlined"
          fullWidth
          required
          value={tipo}
          onChange={(e) => setTipo(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Fecha de Nacimiento"
          type="date"
          fullWidth
          required
          value={fechaNacimiento}
          onChange={(e) => setFechaNacimiento(e.target.value)}
          margin="normal"
        />
        <FormControl fullWidth variant="outlined" required margin="normal">
          <InputLabel>Género</InputLabel>
          <Select
            label="Género"
            value={genero}
            onChange={(e) => setGenero(e.target.value)}
          >
            <MenuItem value="Masculino">Masculino</MenuItem>
            <MenuItem value="Femenino">Femenino</MenuItem>
            <MenuItem value="Prefiero no mencionar">Prefiero no mencionar</MenuItem>
            <MenuItem value="Otros">Otros</MenuItem>
          </Select>
        </FormControl>
        <Button type="submit" variant="contained" color="primary">
          Agregar
        </Button>
      </form>
    </Box>
  );
};

export default RegistroPropietario;
