import React from "react";
import { Select, MenuItem, TextField, InputLabel } from "@mui/material";

const ServiciosForm = ({ tiposServicios, formData, handleInputChange, handleFileChange }) => {
  return (
    <>
      <InputLabel>Tipo de Servicio</InputLabel>
      <Select
        name="id_tipo_servicio"
        value={formData.id_tipo_servicio || ""}
        onChange={handleInputChange}
        fullWidth
        margin="dense"
      >
        {tiposServicios.map((servicio) => (
          <MenuItem key={servicio.id_tipo_servicio} value={servicio.id_tipo_servicio}>
            {servicio.nombre_servicio}
          </MenuItem>
        ))}
      </Select>

      <TextField
        label="Descripción del Servicio"
        name="descripcion_servicio_publico"
        value={formData.descripcion_servicio_publico || ""}
        onChange={handleInputChange}
        fullWidth
        margin="dense"
        multiline
      />

      <TextField
        label="Fecha de Pago"
        name="fecha_pago_servicio_publico"
        type="date"
        value={formData.fecha_pago_servicio_publico || ""}
        onChange={handleInputChange}
        fullWidth
        margin="dense"
        InputLabelProps={{ shrink: true }}
      />

      <TextField
        label="Número de Suministro"
        name="numero_suministro"
        type="number"
        value={formData.numero_suministro || ""}
        onChange={handleInputChange}
        fullWidth
        margin="dense"
      />

      <TextField
        label="Monto"
        name="monto_servicio_publico"
        type="number"
        value={formData.monto_servicio_publico || ""}
        onChange={handleInputChange}
        fullWidth
        margin="dense"
        inputProps={{ step: "0.01" }}
      />

      <InputLabel htmlFor="comprobante">Comprobante</InputLabel>
      <input
        id="comprobante"
        type="file"
        accept="image/*"
        onChange={(e) => handleFileChange(e.target.files[0])}
        style={{ margin: "10px 0" }}
      />
    </>
  );
};

export default ServiciosForm;
