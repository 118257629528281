import React, { useState } from 'react';
import {
  Box,
  TextField,
  Container,
  MenuItem,
  Button,
  Typography,
  Card,
  CardMedia,
} from '@mui/material';

const FormularioEdificios = ({
  tipoEdificio,
  setTipoEdificio,
  nombreEdificio,
  setNombreEdificio,
  direccionEdificio,
  setDireccionEdificio,
  distritoEdificio,
  setDistritoEdificio,
  telefonoEdificio,
  setTelefonoEdificio,
  fechaConstruccion,
  setFechaConstruccion,
  cantidadDepartamentos,
  setCantidadDepartamentos,
  cantidadSotanos,
  setCantidadSotanos,
  cantidadCocheras,
  setCantidadCocheras,
  cantidadPisos,
  setCantidadPisos,
  imagenFile, // Imagen seleccionada
  setImagenFile, // Manejar selección de imagen
  imagenEdificio, // URL de la imagen ya subida o temporal
  setImagenEdificio, // Actualiza la URL temporal de la imagen
  tiposPropiedad, // Recibido desde el componente padre
  loading, // Estado de carga recibido desde el padre
}) => {
  // Manejar la selección del archivo
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImagenFile(file); // Actualizar el archivo en el estado del componente padre

    if (file) {
      const objectURL = URL.createObjectURL(file); // Generar una URL temporal para previsualización
      setImagenEdificio(objectURL); // Actualizar la URL para mostrar la previsualización
    }
  };

  return (
    <Container maxWidth="60%">
      <Box m="2rem">
        <Typography variant="h4" gutterBottom>
          Agregar Edificio
        </Typography>

        {/* Tipo de Propiedad */}
        <TextField
          select
          label="Tipo de Propiedad"
          variant="outlined"
          fullWidth
          required
          value={tipoEdificio}
          onChange={(e) => setTipoEdificio(e.target.value)}
          margin="normal"
          disabled={loading} // Deshabilitar mientras carga
        >
          {loading ? (
            <MenuItem disabled>Cargando tipos de propiedad...</MenuItem>
          ) : tiposPropiedad.length > 0 ? (
            tiposPropiedad.map((tipo) => (
              <MenuItem key={tipo.id_tipo_propiedad} value={tipo.id_tipo_propiedad}>
                {tipo.nombre_tipo_propiedad}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No hay tipos disponibles</MenuItem>
          )}
        </TextField>

        {/* Nombre del Edificio */}
        <TextField
          label="Nombre de Edificio"
          variant="outlined"
          fullWidth
          required
          value={nombreEdificio || ''}
          onChange={(e) => setNombreEdificio(e.target.value)}
          margin="normal"
        />

        {/* Dirección */}
        <TextField
          label="Dirección de Edificio"
          variant="outlined"
          fullWidth
          required
          value={direccionEdificio}
          onChange={(e) => setDireccionEdificio(e.target.value)}
          margin="normal"
        />

        {/* Distrito */}
        <TextField
          label="Distrito de Edificio"
          variant="outlined"
          fullWidth
          required
          value={distritoEdificio}
          onChange={(e) => setDistritoEdificio(e.target.value)}
          margin="normal"
        />

        {/* Teléfono */}
        <TextField
          label="Teléfono de Edificio"
          variant="outlined"
          fullWidth
          required
          value={telefonoEdificio}
          onChange={(e) => setTelefonoEdificio(e.target.value)}
          margin="normal"
        />

        {/* Fecha de Construcción */}
        <TextField
          label="Fecha de Construcción"
          type="date"
          variant="outlined"
          fullWidth
          required
          value={fechaConstruccion}
          onChange={(e) => setFechaConstruccion(e.target.value)}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />

        {/* Cantidades */}
        <TextField
          label="Cantidad de Departamentos"
          type="number"
          variant="outlined"
          fullWidth
          required
          value={cantidadDepartamentos}
          onChange={(e) => setCantidadDepartamentos(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Cantidad de Sótanos"
          type="number"
          variant="outlined"
          fullWidth
          value={cantidadSotanos}
          onChange={(e) => setCantidadSotanos(Number(e.target.value))}
          margin="normal"
        />
        <TextField
          label="Cantidad de Cocheras"
          type="number"
          variant="outlined"
          fullWidth
          value={cantidadCocheras}
          onChange={(e) => setCantidadCocheras(Number(e.target.value))}
          margin="normal"
        />
        <TextField
          label="Cantidad de Pisos"
          type="number"
          variant="outlined"
          fullWidth
          value={cantidadPisos}
          onChange={(e) => setCantidadPisos(Number(e.target.value))}
          margin="normal"
        />

        {/* Imagen */}
        <Box mt={2} textAlign="center">
          <Typography variant="h6" gutterBottom>
            Imagen del Edificio
          </Typography>
          <Card sx={{ maxWidth: 345, margin: '0 auto' }}>
            {imagenEdificio ? (
              <CardMedia
                component="img"
                height="200"
                image={imagenEdificio}
                alt="Imagen del Edificio"
              />
            ) : (
              <Box
                sx={{
                  height: 200,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#f0f0f0',
                }}
              >
                <Typography color="textSecondary">No hay imagen seleccionada</Typography>
              </Box>
            )}
            <input
              accept="image/*"
              type="file"
              style={{ display: 'none' }}
              id="upload-button"
              onChange={handleFileChange}
            />
            <label htmlFor="upload-button">
              <Button variant="outlined" component="span" color="primary">
                Seleccionar Imagen
              </Button>
            </label>
          </Card>
        </Box>
      </Box>
    </Container>
  );
};

export default FormularioEdificios;
