import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Select, MenuItem, FormControl, InputLabel, Snackbar } from '@mui/material';

const FormularioCuotaFija = ({ id_empresa, id_edificio, onAddSuccess }) => {
  const [cuotaFija, setCuotaFija] = useState('');
  const [fecha, setFecha] = useState('');
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Función para obtener el token del localStorage y configurarlo en los encabezados
  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  };

  useEffect(() => {
    fetchDepartamentos();
  }, [id_empresa, id_edificio]);

  const fetchDepartamentos = async () => {
    try {
      const url = `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos`;
      const response = await axios.get(url, getAuthHeaders());
      setDepartamentos(response.data);
    } catch (error) {
      console.error("Error al obtener los departamentos:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const url = `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/cuotas_fijas`;
      await axios.post(
        url,
        {
          id_edificio: id_edificio,
          id_departamento: selectedDepartamento,
          cuota_fija: cuotaFija,
          fecha_cuota_fija: fecha,
        },
        getAuthHeaders()
      );

      if (onAddSuccess) {
        onAddSuccess();
      }

      // Muestra el Snackbar
      setOpenSnackbar(true);

      // Reinicia los estados
      setCuotaFija('');
      setFecha('');
      setSelectedDepartamento('');
    } catch (error) {
      console.error('Error al agregar la cuota fija:', error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
        <InputLabel>Departamento</InputLabel>
        <Select
          value={selectedDepartamento}
          onChange={(e) => setSelectedDepartamento(e.target.value)}
          label="Departamento"
        >
          {departamentos.map((dep) => (
            <MenuItem key={dep.id_departamento} value={dep.id_departamento}>
              {dep.numero_departamento}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Cuota Fija"
        variant="outlined"
        type="number"
        value={cuotaFija}
        onChange={(e) => setCuotaFija(e.target.value)}
        fullWidth
        required
        sx={{ mb: 2 }}
      />
      <TextField
        label="Fecha"
        variant="outlined"
        type="date"
        value={fecha}
        onChange={(e) => setFecha(e.target.value)}
        InputLabelProps={{ shrink: true }}
        fullWidth
        required
        sx={{ mb: 2 }}
      />
      <Box mt={2}>
        <Button type="submit" variant="contained" color="primary">
          Agregar Cuota Fija
        </Button>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="Cuota fija ingresada de manera correcta"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default FormularioCuotaFija;
