import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import axios from "axios";
import FormularioCocheras from "./FormularioCocheras";
import EditarCocheras from "./EditarCocheras"; // Asegúrate de tener este componente para editar cocheras

const Cocheras = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id_empresa, id_edificio } = useParams(); // Obtener id_empresa e id_edificio
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEditar, setOpenEditar] = useState(false);
  const [cocheraParaEditar, setCocheraParaEditar] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    fetchData();
  }, [id_edificio]);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token'); // Obtener el token de localStorage
      const response = await axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/cocheras`, {
        headers: { Authorization: `Bearer ${token}` }, // Pasar el token en los headers
      });
      const cocherasData = response.data.map((cochera) => ({
        ...cochera,
        id: cochera.id_cochera, // Asegurarse de que el campo id esté definido para el DataGrid
      }));
      setData(cocherasData);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEdit = () => {
    const cocheraSeleccionada = data.find(cochera => selectedRows.includes(cochera.id));
    if (cocheraSeleccionada) {
      setCocheraParaEditar(cocheraSeleccionada);
      setOpenEditar(true);
    }
  };

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/cocheras/eliminar`, {
        headers: { Authorization: `Bearer ${token}` },
        data: selectedRows, // Enviar las cocheras seleccionadas para eliminarlas
      });
      fetchData(); // Recargar los datos
      setSelectedRows([]);
    } catch (error) {
      console.error("Error al eliminar las cocheras:", error);
    }
  };

  const handleCloseEditar = () => {
    setOpenEditar(false);
    setCocheraParaEditar(null);
  };

  const handleSuccessEdit = () => {
    fetchData();
    handleCloseEditar();
  };

  const columns = [
    { field: "numero_cochera", headerName: "Número", flex: 1 },
    { field: "piso_cochera", headerName: "Piso", flex: 1 },
    { field: "area_cochera", headerName: "Área (m2)", flex: 1 },
  ];

  const handleSuccess = () => {
    fetchData();
    handleClose();
  };

  return (
    <Box m="20px">
      <Header title="Cocheras" subtitle="Lista de las cocheras del edificio" />
      <Box sx={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
        {selectedRows.length > 0 && (
          <>
            <Button
              variant="contained"
              onClick={handleEdit}
              style={{ backgroundColor: colors.blueAccent[700], marginRight: "10px" }}
              disabled={selectedRows.length !== 1}
            >
              Editar
            </Button>
            <Button
              variant="contained"
              onClick={handleDelete}
              style={{ backgroundColor: colors.blueAccent[700], marginRight: "10px" }}
            >
              Eliminar
            </Button>
          </>
        )}
        <Button variant="contained" onClick={handleOpen} style={{ backgroundColor: colors.blueAccent[700] }}>
          Agregar Cochera
        </Button>
      </Box>
      <Box
        m="25px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            color: colors.primary.main,
            borderBottom: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            fontSize: "13px",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={data}
          columns={columns}
          onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
        />
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <FormularioCocheras onClose={handleClose} idEdificio={parseInt(id_edificio)} onSubmitSuccess={handleSuccess} />
      </Dialog>

      <EditarCocheras
        open={openEditar}
        onClose={handleCloseEditar}
        cochera={cocheraParaEditar}
        onSuccess={handleSuccessEdit}
      />
    </Box>
  );
};

export default Cocheras;
