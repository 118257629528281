import React, { useState, useEffect } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import axios from 'axios';

const EditarConcepto = ({ open, onClose, concepto, onSuccess }) => {
  const [conceptoData, setConceptoData] = useState({
    id_concepto: '',
    nombre_concepto: '',
    id_edificio: '',
    // Asegúrate de incluir aquí otros campos necesarios
  });

  useEffect(() => {
  if (concepto) {
    setConceptoData({
      id_concepto: concepto.id_concepto || '',
      nombre_concepto: concepto.nombre_concepto || '',
      id_edificio: concepto.id_edificio || '',
      // Asegúrate de incluir aquí otros campos necesarios con valores predeterminados seguros
    });
  }
}, [concepto]);

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConceptoData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      // Asegúrate de usar la URL correcta de tu API
      await axios.put(`https://app.propia.pe/apiRest/conceptos/modificar`, conceptoData);
      onSuccess(); // Notificar al componente padre para actualizar la lista de conceptos
      onClose(); // Cerrar el diálogo
    } catch (error) {
      console.error('Error al modificar el concepto:', error);
      // Considera manejar el error de manera que el usuario sea notificado
    }
  };

  return (
    <Dialog open={Boolean(concepto)} onClose={onClose}>
      <DialogTitle>Editar Concepto</DialogTitle>
      <DialogContent>
      <TextField
          autoFocus
          margin="dense"
          label="Nombre del Concepto"
          type="text"
          fullWidth
          value={conceptoData.nombre_concepto}
          onChange={handleChange}
          required
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit} color="primary">Guardar Cambios</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditarConcepto;
