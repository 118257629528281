import React from 'react';
import { Select, MenuItem, TextField, InputLabel } from '@mui/material';

const MantenimientoForm = ({ formData, handleInputChange, handleFileChange }) => (
    <>
        <InputLabel>Tipo de Mantenimiento</InputLabel>
        <Select
            name="tipo_mantenimiento"
            value={formData.tipo_mantenimiento || ''}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
        >
            <MenuItem value="Preventivo">Preventivo</MenuItem>
            <MenuItem value="Correctivo">Correctivo</MenuItem>
        </Select>

        <TextField
            label="Concepto de Mantenimiento"
            name="concepto_mantenimiento"
            value={formData.concepto_mantenimiento || ''}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
        />

        <TextField
            label="Fecha de Mantenimiento"
            name="fecha_mantenimiento"
            type="date"
            value={formData.fecha_mantenimiento || ''}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            InputLabelProps={{ shrink: true }}
        />

        <TextField
            label="Importe"
            name="importe_mantenimiento"
            type="number"
            value={formData.importe_mantenimiento || ''}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            inputProps={{ step: "0.01" }}
        />

        <InputLabel htmlFor="comprobante">Comprobante</InputLabel>
        <input
            id="comprobante"
            type="file"
            accept="image/*"
            onChange={(e) => handleFileChange(e.target.files[0])}
            style={{ margin: "10px 0" }}
        />
    </>
);

export default MantenimientoForm;
