import React, { useState, useEffect } from "react";
import {  useTheme,  Typography,  Button,  Box,  Select,  MenuItem,  Snackbar,  InputLabel,  Dialog,  DialogContent,
  DialogTitle,
  IconButton,
  Alert
} from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { tokens } from "../../theme";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import ReciboMantenimientoPDF from "../reciboMantenimientoPDF/reciboMantenimientoPDF";
import { Page, Document, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import ReciboMantenimientoDialog from "../Dialogs/dialogPreviewReciboMantenimiento/ReciboMantenimientoDialog ";
import Big from 'big.js';
import EmisionRecibosDialog from "../EmisionRecibosDialog/EmisionRecibosDialog";
import EmisionUnoRecibosDialog from "./EmisionUnoRecibosDialog";
import ReceiptIcon from '@mui/icons-material/Receipt';
import useFetchPropietarios from "../Hook/HookPropietarios";
import useFetchGastos from "../Hook/HookPresupuestoAnual";
import useFetchAndCalculate from "../Hook/HookCalculoAgua";

const DiseñadorReciboMantenimiento = () => {
  const { id_empresa, id_edificio } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [openModal, setOpenModal] = useState(false);
  const [openEmisionDialog, setOpenEmisionDialog] = useState(false);
  const [openEmisionUnoDialog, setOpenEmisionUnoDialog] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedDepartamento, setSelectedDepartamento] = useState(null);
  const [reciboParaEmitir, setReciboParaEmitir] = useState(null);
  const [mensajeExito, setMensajeExito] = useState('');
  const [calculationType, setCalculationType] = useState("cuotaFija");
  const [cuotasFijas, setCuotasFijas] = useState([]);
  const [totalMantenimiento, setTotalMantenimiento] = useState(0);
  const [error, setError] = useState(null);
  const [departamentosData, setDepartamentosData] = useState([]);

  // Hooks personalizados
  const {
    asignacionData, 
    porcentajeData, 
    propietariosData,
    datosEdificioData,
    datosCuentaBancariaData,
    loading: loadingPropietarios
  } = useFetchPropietarios(id_empresa, id_edificio, selectedMonth, selectedYear);

  const {
    gastos, 
    calcularGastosMensuales, 
    fetchData: fetchGastosData 
  } = useFetchGastos(id_empresa, id_edificio, selectedYear);

  const {
    loading: loadingCalculations,
    lecturas,
    departamentos,
    consumoAguaData,
    getCostoTotalByDepartamento, // Asegúrate de extraer esta función
    fetchData: fetchLecturasData
  } = useFetchAndCalculate(selectedMonth, selectedYear, id_empresa, id_edificio);

  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError("Token no encontrado. Por favor, inicia sesión.");
      return null;
    }
    return { headers: { Authorization: `Bearer ${token}` } };
  };

  const manejarError = (error, mensaje) => {
    console.error(mensaje, error);
    if (error.response) {
      if (error.response.status === 401) {
        setError("Sesión expirada. Por favor, inicia sesión nuevamente.");
        localStorage.removeItem('token');
      } else if (error.response.status === 403) {
        setError("No tienes permisos para acceder a este recurso.");
      } else {
        setError(`${mensaje}: ${error.message}`);
      }
    } else {
      setError(mensaje);
    }
  };

  useEffect(() => {
    if (id_empresa && id_edificio) {
      if (localStorage.getItem('token')) {
        fetchCuotasFijas();
        fetchGastosData();
        fetchLecturasData();
      } else {
        setError("Token no encontrado. Por favor, inicia sesión.");
      }
    }
  }, [id_empresa, id_edificio, selectedMonth, selectedYear, calculationType]);

  const fetchCuotasFijas = async () => {
    const headers = getAuthHeaders();
    if (!headers) return;

    try {
      const response = await axios.get(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/cuotas_fijas`,
        headers
      );
      setCuotasFijas(response.data);
    } catch (error) {
      manejarError(error, "Error al obtener las cuotas fijas");
    }
  };

  const calcularReciboMantenimiento = () => {
    const gastoMensual = calcularGastosMensuales(gastos);

    const departamentosWithCuotas = (departamentos || []).map(dep => {
      const lecturaActual = lecturas.find(l => 
        l.id_departamento === dep.id_departamento && 
        new Date(l.fecha_lectura).getMonth() + 1 === selectedMonth &&
        new Date(l.fecha_lectura).getFullYear() === selectedYear
      );

      const mesAnterior = selectedMonth - 1 === 0 ? 12 : selectedMonth - 1;
      const añoAnterior = selectedMonth - 1 === 0 ? selectedYear - 1 : selectedYear;
      const lecturaAnterior = lecturas.find(l => 
        l.id_departamento === dep.id_departamento && 
        new Date(l.fecha_lectura).getMonth() + 1 === mesAnterior &&
        new Date(l.fecha_lectura).getFullYear() === añoAnterior
      );

      const consumoPorM3Dep = lecturaActual && lecturaAnterior 
        ? lecturaActual.lectura_agua - lecturaAnterior.lectura_agua 
        : 0;

      const consumoAguaSoles = consumoAguaData.find(consumo =>
        consumo.id_departamento === dep.id_departamento
      );
      const costoTotal = consumoAguaSoles ? consumoAguaSoles.costo_total : 0;

      const asignacion = asignacionData.find(a => a.id_departamento === dep.id_departamento);
      const porcentaje = porcentajeData.find(p => p.id_departamento === dep.id_departamento);
      const propietario = asignacion ? propietariosData.find(p => p.id_propietario === asignacion.id_propietario) : { nombre_propietario: "Desconocido", apellido_propietario: "Desconocido" };

      const datosEdificio = datosEdificioData?.find(item => item.id_edificio === parseInt(id_edificio)) || {};
      const datosCuentaBancaria = datosCuentaBancariaData?.find(cuenta => cuenta.id_edificio === parseInt(id_edificio)) || {};

      let montoCuota;
      if (calculationType === "porcentaje" && porcentaje) {
        const porcentajeDecimal = new Big(porcentaje.porcentaje_participacion).div(100);
        montoCuota = new Big(gastoMensual).times(porcentajeDecimal).toFixed(2);
      } else {
        const cuotaFija = cuotasFijas.find(cuota => cuota.id_departamento === dep.id_departamento);
        montoCuota = cuotaFija ? cuotaFija.cuota_fija : "0.00";
      }

      const totalReciboMantenimiento = (parseFloat(montoCuota) + parseFloat(costoTotal)).toFixed(2);

      return {
        id: dep.id_departamento,
        numero_departamento: dep.numero_departamento,
        nombre_propietario: propietario.nombre_propietario,
        apellido_propietario: propietario.apellido_propietario,
        porcentaje_participacion: (porcentaje?.porcentaje_participacion || 0).toFixed(2),
        monto_cuota: parseFloat(montoCuota).toFixed(2),
        costo_total: parseFloat(costoTotal).toFixed(2),
        total_recibo_mantenimiento: totalReciboMantenimiento,
        lectura_anterior: lecturaAnterior ? parseFloat(lecturaAnterior.lectura_agua).toFixed(2) : "0.00",
        lectura_actual: lecturaActual ? parseFloat(lecturaActual.lectura_agua).toFixed(2) : "0.00",
        consumo_m3: parseFloat(consumoPorM3Dep).toFixed(2),
        nombre_banco: datosCuentaBancaria.nombre_banco || "N/A",
        numero_cuenta: datosCuentaBancaria.numero_cuenta || "N/A",
        numero_cuenta_cci: datosCuentaBancaria.numero_cuenta_cci || "N/A",
        nombre_junta_propietario: datosEdificio.nombre_junta_propietario || "N/A",
        ruc: datosEdificio.ruc || "N/A",
        id_empresa: parseInt(id_empresa)
      };
    });

    setDepartamentosData(departamentosWithCuotas);
    const totalReciboMantenimiento = departamentosWithCuotas.reduce((acc, dep) => acc + parseFloat(dep.total_recibo_mantenimiento), 0).toFixed(2);
    setTotalMantenimiento(totalReciboMantenimiento);
  };

  useEffect(() => {
    if (!loadingCalculations && !loadingPropietarios && lecturas.length && departamentos.length) {
      calcularReciboMantenimiento();
    }
  }, [lecturas, departamentos, consumoAguaData, asignacionData, porcentajeData, propietariosData]);
  

  const handlePreviewOpen = (departamento) => {
    setSelectedDepartamento(departamento);
    setOpenPreview(true);
  };

  const handlePreviewClose = () => {
    setOpenPreview(false);
  };

  const handleDownloadPDF = async (departamento) => {
    const generatePDF = () => (
      <Document>
        <Page size="A4">
          <ReciboMantenimientoPDF departamento={departamento} />
        </Page>
      </Document>
    );

    const pdfBlob = await pdf(generatePDF()).toBlob();
    saveAs(pdfBlob, `Recibo_${departamento.numero_departamento}.pdf`);
  };

  const onEmitirRecibos = async (datosEmision) => {
    const headers = getAuthHeaders();
    if (!headers) return;

    try {
      await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/recibos_emitidos`,
        { idEdificio: parseInt(id_edificio), recibosEmitidos: datosEmision },
        headers
      );
      setMensajeExito('Recibos creados y emitidos con éxito!');
      setOpenEmisionDialog(false);
      fetchLecturasData();
    } catch (error) {
      manejarError(error, "Error al emitir recibos");
    }
  };

  const handleEmisionUnoOpen = (departamento) => {
    setReciboParaEmitir(departamento);
    setOpenEmisionUnoDialog(true);
  };

  const handleEmisionUnoClose = () => {
    setOpenEmisionUnoDialog(false);
  };

  const columns = [
    { field: "numero_departamento", headerName: "Departamento", flex: 1 },
    { field: "nombre_propietario", headerName: "Nombre", flex: 1 },
    { field: "apellido_propietario", headerName: "Apellido", flex: 1 },
    { field: "monto_cuota", headerName: "Cuota Mantenimiento", flex: 1 },
    { 
      field: "costo_total", 
      headerName: "Monto Agua (S/.)", 
      flex: 1,
      valueGetter: (params) => {
        return getCostoTotalByDepartamento(params.row.id);
      }
    },
    { field: "total_recibo_mantenimiento", headerName: "Total", flex: 1 },
    {
      field: "emitir",
      headerName: "Emitir Recibo",
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleEmisionUnoOpen(params.row)}>
          <ReceiptIcon style={{ color: colors.blueAccent[700] }} />
        </IconButton>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="Recibos de Mantenimiento" />
      <Box display="flex" justifyContent="space-between" alignItems="center" m="0 0 20px 0">
        <Box display="flex" gap="2rem" alignItems="center">
          <Box display="flex" gap="0.5rem" alignItems="center">
            <InputLabel>MES:</InputLabel>
            <Select value={selectedMonth} onChange={(event) => setSelectedMonth(event.target.value)}>
              {Array.from({ length: 12 }, (_, index) => {
                const month = new Date(2000, index).toLocaleString("default", { month: "long" });
                return { value: index + 1, label: month };
              }).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box display="flex" gap="0.5rem" alignItems="center">
            <InputLabel>AÑO:</InputLabel>
            <Select value={selectedYear} onChange={(event) => setSelectedYear(event.target.value)}>
              {Array.from({ length: 10 }, (_, index) => {
                const year = new Date().getFullYear() - index;
                return { value: year, label: year };
              }).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box display="flex" gap="2rem" alignItems="center">
          <Typography variant="subtitle1">
            <strong>Total general de Recibo de Mantenimiento:</strong> S/. {totalMantenimiento}
          </Typography>
          <Box display="flex" gap="1rem">
            <Button variant="contained" style={{ backgroundColor: colors.blueAccent[700] }} onClick={() => setOpenModal(true)}>
              Escoger tipo de cálculo
            </Button>
            <Button variant="contained" onClick={() => setOpenEmisionDialog(true)} style={{ backgroundColor: colors.blueAccent[700] }}>
              Emitir Recibos
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Diálogo para seleccionar tipo de cálculo */}
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Selecciona el tipo de cálculo</DialogTitle>
        <DialogContent>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setCalculationType('porcentaje');
              setOpenModal(false);
            }}
            style={{ marginRight: theme.spacing(2) }}
          >
            Cálculo por Porcentaje de Participación
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setCalculationType('cuotasFijas');
              fetchCuotasFijas();
              setOpenModal(false);
            }}
          >
            Cuotas Fijas
          </Button>
        </DialogContent>
      </Dialog>

      <Box
        m="10px 0 0 0"
        height="65vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], fontSize: "14px", color: "#fff" },
          "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400], fontSize: "13px" },
          "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
          "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
        }}
      >
        <DataGrid
          rows={departamentosData}
          columns={columns}
          getRowId={(row) => row.id}
        />

        <EmisionRecibosDialog
          open={openEmisionDialog}
          onClose={() => setOpenEmisionDialog(false)}
          recibosParaEmitir={departamentosData}
          idEmpresa={parseInt(id_empresa)}
          idEdificio={parseInt(id_edificio)}
          onEmitirRecibos={(data) => {
            setMensajeExito('Recibos emitidos correctamente.');
            fetchLecturasData();
          }}
          totalReciboMantenimiento={totalMantenimiento}
        />

        <EmisionUnoRecibosDialog
          open={openEmisionUnoDialog}
          onClose={handleEmisionUnoClose}
          reciboParaEmitir={reciboParaEmitir}
          idEdificio={parseInt(id_edificio)}
          onEmitirRecibo={() => {
            setMensajeExito('Recibo emitido con éxito!');
            fetchLecturasData();
          }}
        />

        <ReciboMantenimientoDialog
          open={openPreview}
          onClose={handlePreviewClose}
          departamento={selectedDepartamento}
        />

        <Snackbar
          open={!!mensajeExito}
          autoHideDuration={6000}
          onClose={() => setMensajeExito('')}
        >
          <Alert onClose={() => setMensajeExito('')} severity="success" variant="filled" sx={{ width: '100%' }}>
            {mensajeExito}
          </Alert>
        </Snackbar>

        {error && (
          <Snackbar
            open={!!error}
            autoHideDuration={6000}
            onClose={() => setError('')}
          >
            <Alert onClose={() => setError('')} severity="error" variant="filled" sx={{ width: '100%' }}>
              {error}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Box>
  );
};

export default DiseñadorReciboMantenimiento;
