import React, { useState } from "react";
import { Box, InputLabel, Select, MenuItem, Dialog, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import useFetchMonthlyExpenses from '../Hook/hookGastosMensuales'; // Asegúrate de que la ruta del hook sea correcta
import RegistroGastosMensuales from "../registroGastosMensuales/registroGastosMensuales";
import TotalImportes from "../totalImportes/TotalImportes";
import Header from "../../components/Header";
import { tokens } from "../../theme";

const GastosMensuales = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id_edificio } = useParams();
  const { data, loading, fetchData } = useFetchMonthlyExpenses(id_edificio);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [open, setOpen] = useState(false);

  const columns = [
    {
      field: "fecha", // Ajustado para coincidir con la nueva clave de fecha
      headerName: "Fecha",
      flex: 1,
      valueFormatter: ({ value }) => format(parseISO(value), "dd/MM/yyyy") // Asegúrate de que el formato de fecha sea el correcto
    },
    { field: "concepto", headerName: "Concepto", flex: 1.5 }, // Ajustado para coincidir con la nueva clave de concepto
    { 
      field: "monto", 
      headerName: "Importe (S/.)", 
      flex: 1, 
      valueFormatter: ({ value }) => value.toFixed(2) // Asegúrate de que el valor sea un número
    }
  ];
  

  const monthOptions = Array.from({ length: 12 }, (_, index) => ({
    value: index + 1,
    label: new Date(2000, index).toLocaleString("default", { month: "long" })
  }));

  const currentYear = new Date().getFullYear();
  const startYear = 2000;
  const maxDisplayedYears = 10;
  const yearOptions = Array.from({ length: Math.min(maxDisplayedYears, currentYear - startYear + 1) }, (_, index) => ({
    value: currentYear - index,
    label: currentYear - index
  }));

  const filteredData = data.filter(gasto => {
    const date = new Date(gasto.fecha); // Cambiado de 'fecha_gasto_mensual' a 'fecha'
    return date.getMonth() + 1 === selectedMonth && date.getFullYear() === selectedYear;
  });
  

  return (
    <Box m="20px">
      <Header title="Gastos Mensuales" subtitle="Lista de gastos mensuales" />
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="20px">
        <Box display="flex" gap="20px" alignItems="center">
          <Box>
            <InputLabel htmlFor="mes-selector">MES:</InputLabel>
            <Select
              id="mes-selector"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
              displayEmpty
            >
              {monthOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <InputLabel htmlFor="año-selector">AÑO:</InputLabel>
            <Select
              id="año-selector"
              value={selectedYear}
              onChange={(e) => setSelectedYear(parseInt(e.target.value))}
              displayEmpty
            >
              {yearOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Button
          variant="contained"
          onClick={() => setOpen(true)}
          style={{ backgroundColor: colors.blueAccent[700] }}
        >
          Agregar Gasto Mensual
        </Button>
      </Box>
  
      <TotalImportes filteredData={filteredData} colors={colors} />
  
      <DataGrid
        checkboxSelection
        rows={filteredData}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
        sx={{
          height: 600,
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            color: '#fff',
            borderBottom: 'none',
            fontSize: '14px',
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          }
        }}
      />
  
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <RegistroGastosMensuales
          idEdificio={id_edificio}
          onClose={() => setOpen(false)}
          onDataUpdate={fetchData}
        />
      </Dialog>
    </Box>
  );
  

};

export default GastosMensuales;
