import React from 'react';
import { SubMenu } from 'react-pro-sidebar';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import Item from './Item'; // Asegúrate de que la ruta de importación sea correcta

const AdministrativaMenu = ({ selected, setSelected, currentBuilding, isSubMenuActive }) => {
  // Obtener id_empresa, nombre_empresa, id_edificio y nombre_edificio desde `currentBuilding` o `localStorage`
  const id_empresa = currentBuilding?.id_empresa || localStorage.getItem('id_empresa');
  const nombre_empresa = currentBuilding?.nombre_empresa || localStorage.getItem('nombre_empresa');
  const id_edificio = currentBuilding?.id_edificio || localStorage.getItem('id_edificio');
  const nombre_edificio = currentBuilding?.nombre_edificio || localStorage.getItem('nombre_edificio') || 'Edificio';

  // Base URL para construir rutas dinámicamente
  const baseUrl = `/empresa/${id_empresa}/${nombre_empresa}/edificios/${id_edificio}/${nombre_edificio}`;

  // Determinar si el submenú está activo
  const isActive = isSubMenuActive([
    `${baseUrl}/calendar`,
    `${baseUrl}/faq`,
    `${baseUrl}/personal`,
  ]);

  return (
    <SubMenu
      title="Administrativa"
      icon={<PersonOutlineOutlinedIcon />}
      {...(isActive ? { active: true } : {})} // Evitar pasar `active` si no está activo
    >
      <Item
        title="Calendario"
        to={`${baseUrl}/calendar`}
        icon={<CalendarTodayOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
      <Item
        title="Personal"
        to={`${baseUrl}/personal`}
        icon={<AssignmentOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
      <Item
        title="FAQ Page"
        to={`${baseUrl}/faq`}
        icon={<HelpOutlineOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
    </SubMenu>
  );
};

export default AdministrativaMenu;
