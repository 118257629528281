import React, { useState } from 'react';
import { Select, MenuItem, TextField, InputLabel } from '@mui/material';

const RemuneracionesForm = ({
    personal,
    formData,
    handleInputChange,
    handleFileChange,
}) => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        handleFileChange(file); // Pasar el archivo al componente padre
    };

    return (
        <>
            <InputLabel>Personal</InputLabel>
            <Select
                name="id_personal"
                value={formData.id_personal || ''}
                onChange={handleInputChange}
                fullWidth
                margin="dense"
            >
                {personal.map((person) => (
                    <MenuItem key={person.id_personal} value={person.id_personal}>
                        {person.nombre_personal}
                    </MenuItem>
                ))}
            </Select>

            <InputLabel>Tipo de Pago</InputLabel>
            <Select
                name="tipo_pago"
                value={formData.tipo_pago || ''}
                onChange={handleInputChange}
                fullWidth
                margin="dense"
            >
                <MenuItem value="Sueldo">Sueldo</MenuItem>
                <MenuItem value="Horas extras">Horas extras</MenuItem>
            </Select>

            <TextField
                label="Descripción"
                name="descripcion"
                value={formData.descripcion || ''}
                onChange={handleInputChange}
                fullWidth
                margin="dense"
            />

            <TextField
                label="Fecha de Pago"
                name="fecha_pago"
                type="date"
                value={formData.fecha_pago || ''}
                onChange={handleInputChange}
                fullWidth
                margin="dense"
                InputLabelProps={{ shrink: true }}
            />

            <TextField
                label="Monto Base"
                name="monto_base"
                type="number"
                value={formData.monto_base || ''}
                onChange={handleInputChange}
                fullWidth
                margin="dense"
                inputProps={{ step: '0.01' }}
            />

            <InputLabel htmlFor="boucher">Boucher de Pago</InputLabel>
            <input
                id="boucher"
                type="file"
                accept="image/*"
                onChange={handleFileInputChange}
                style={{ margin: '10px 0' }}
            />
        </>
    );
};

export default RemuneracionesForm;
