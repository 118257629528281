import React, { useState } from 'react';
import { DialogContent, DialogTitle, TextField, Button, DialogActions } from '@mui/material';
import axios from "axios";

const FormularioDepositos = ({ onClose, idEdificio, onSubmitSuccess }) => {
  const [numeroDeposito, setNumeroDeposito] = useState('');
  const [pisoDeposito, setPisoDeposito] = useState('');
  const [areaDeposito, setAreaDeposito] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const depositoData = {
      id_edificio: idEdificio,
      numero_deposito: numeroDeposito,
      piso_deposito: pisoDeposito,
      area_deposito: areaDeposito,
      estado: true,
    };

    try {
      await axios.post("https://app.propia.pe/apiRest/depositos/insertar", depositoData);
      onClose(); // Cierra el formulario
      onSubmitSuccess(); // Llama a la función para actualizar el componente padre
    } catch (error) {
      console.error("Error al agregar el depósito:", error);
      alert("Error al registrar el depósito. Por favor, intente de nuevo.");
    }
  };

  return (
    <>
      <DialogTitle>Agregar Depósito</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="numeroDeposito"
          label="Número de Depósito"
          type="number"
          fullWidth
          variant="outlined"
          value={numeroDeposito}
          onChange={(e) => setNumeroDeposito(e.target.value)}
        />
        <TextField
          margin="dense"
          id="pisoDeposito"
          label="Piso del Depósito"
          type="text"
          fullWidth
          variant="outlined"
          value={pisoDeposito}
          onChange={(e) => setPisoDeposito(e.target.value)}
        />
        <TextField
          margin="dense"
          id="areaDeposito"
          label="Área del Depósito (m2)"
          type="number"
          fullWidth
          variant="outlined"
          value={areaDeposito}
          onChange={(e) => setAreaDeposito(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit}>Guardar</Button>
      </DialogActions>
    </>
  );
};

export default FormularioDepositos;
