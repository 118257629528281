import React, { useState, useEffect } from "react";
import { Box, Button, TextField, MenuItem, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import axios from "axios";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";

const EditarPersonal = ({ personal, fetchData, onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [nombrePersonal, setNombrePersonal] = useState(personal?.nombre_personal || "");
  const [fechaContratacion, setFechaContratacion] = useState(personal?.fecha_contratacion || "");
  const [estado, setEstado] = useState(personal?.estado || true);
  const [idTipoPersonal, setIdTipoPersonal] = useState(personal?.id_tipo_personal || "");
  const [tiposPersonal, setTiposPersonal] = useState([]);

  useEffect(() => {
    fetchTiposPersonal();
  }, []);

  const fetchTiposPersonal = async () => {
    try {
      const response = await axios.get("https://app.propia.pe/apiRest/tipo_personal/obtener");
      setTiposPersonal(response.data);
    } catch (error) {
      console.error("Error al obtener tipos de personal:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedPersonal = {
      id_personal: personal.id_personal,
      id_edificio: personal.id_edificio,
      id_tipo_personal: idTipoPersonal,
      nombre_personal: nombrePersonal,
      fecha_contratacion: fechaContratacion,
      estado: estado,
    };

    try {
      await axios.put(`https://app.propia.pe/apiRest/personal3/actualizar/${personal.id_personal}`, updatedPersonal);
      fetchData(); // Actualiza la lista de personal
      onClose(); // Cierra el diálogo
    } catch (error) {
      console.error("Error al actualizar el personal:", error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <DialogTitle>Editar Personal</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Nombre del Personal"
          type="text"
          fullWidth
          variant="outlined"
          value={nombrePersonal}
          onChange={(e) => setNombrePersonal(e.target.value)}
          required
        />
        <TextField
          select
          margin="dense"
          label="Tipo de Personal"
          fullWidth
          variant="outlined"
          value={idTipoPersonal}
          onChange={(e) => setIdTipoPersonal(e.target.value)}
          required
        >
          {tiposPersonal.map((tipo) => (
            <MenuItem key={tipo.id_tipo_personal} value={tipo.id_tipo_personal}>
              {tipo.nombre_tipo_personal}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="dense"
          label="Fecha de Contratación"
          type="date"
          fullWidth
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={fechaContratacion}
          onChange={(e) => setFechaContratacion(e.target.value)}
          required
        />
        <TextField
          select
          margin="dense"
          label="Estado"
          fullWidth
          variant="outlined"
          value={estado}
          onChange={(e) => setEstado(e.target.value)}
          required
        >
          <MenuItem value={true}>Activo</MenuItem>
          <MenuItem value={false}>Inactivo</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: colors.grey[100] }}>
          Cancelar
        </Button>
        <Button type="submit" variant="contained" style={{ backgroundColor: colors.blueAccent[700] }}>
          Guardar
        </Button>
      </DialogActions>
    </Box>
  );
};

export default EditarPersonal;
