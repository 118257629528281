import React, { useState } from 'react';
import axios from 'axios';
import { useTheme, TextField, Button, Container, Typography, Box } from "@mui/material";

const FormularioConceptos = ({ id_empresa, id_edificio, onSuccess }) => {
  const [nombreConcepto, setNombreConcepto] = useState('');
  const theme = useTheme();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar que id_empresa e id_edificio estén definidos
    if (!id_empresa || !id_edificio) {
      alert('ID de empresa o de edificio no está definido. Por favor, verifica la URL o la configuración.');
      return;
    }

    try {
      const token = localStorage.getItem('token'); // Obtener el token del localStorage
      if (!token) {
        alert('Token de autorización no encontrado. Por favor, inicia sesión.');
        return;
      }

      await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/conceptos/insertar`,
        {
          nombre_concepto: nombreConcepto,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      alert('Concepto agregado exitosamente!');
      setNombreConcepto(''); // Limpiar el campo de texto

      if (onSuccess) onSuccess(); // Llamar a la función de éxito para actualizar la lista

    } catch (error) {
      if (error.response && error.response.status === 403) {
        alert('No tienes permiso para realizar esta acción. Por favor, verifica tus credenciales.');
      } else if (error.response && error.response.status === 400) {
        alert('Error en la solicitud. Verifica los datos enviados.');
      } else {
        alert('Hubo un error al agregar el concepto.');
      }
      console.error("Error al agregar el concepto:", error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>Agregar Concepto</Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={3}>
          <TextField
            fullWidth
            label="Nombre del Concepto"
            variant="outlined"
            value={nombreConcepto}
            onChange={(e) => setNombreConcepto(e.target.value)}
            required
            style={{
              backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100]
            }}
            InputProps={{
              style: {
                color: theme.palette.mode === 'dark' ? theme.palette.grey[100] : theme.palette.grey[900]
              }
            }}
            InputLabelProps={{
              style: {
                color: theme.palette.mode === 'dark' ? theme.palette.grey[100] : theme.palette.grey[900]
              }
            }}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <Button type="submit" variant="contained" color="primary">Agregar</Button>
        </Box>
      </form>
    </Container>
  );
};

export default FormularioConceptos;
