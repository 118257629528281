import React, { useRef, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Handsontable from 'handsontable';
import 'handsontable/dist/handsontable.full.css';
import { useTheme } from '@mui/material/styles';
import { tokens } from "../../theme";

const RegistroAsignacionMasivo = ({ hotTableRef, onHandsontableReady, initialData = [] }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [instanceReady, setInstanceReady] = useState(false);

    useEffect(() => {
        // Inicialización de los datos para Handsontable
        const dataToRender = initialData.length
            ? initialData
            : new Array(15).fill(0).map(() => ['', '', '', '', '', '', '']); // Datos iniciales por defecto

        // Creación de la instancia de Handsontable
        const hot = new Handsontable(hotTableRef.current, {
            data: dataToRender,
            rowHeaders: true,
            colHeaders: [
                'Número de Departamento',
                'Nombre Propietario',
                'Apellido Propietario',
                'Email Propietario',
                'Celular Propietario',
                'Número Cochera',
                'Número Depósito',
            ],
            columns: [{}, {}, {}, {}, {}, {}, {}],
            dropdownMenu: true,
            filters: true,
            manualRowResize: true,
            manualColumnResize: true,
            contextMenu: true,
            stretchH: 'all',
            width: '100%',
            height: 500,
            minRows: 15,
            minCols: 7,
            licenseKey: 'non-commercial-and-evaluation',
            className: 'htCenter htMiddle',
            cells: (row, col) => ({
                className: 'htCenter htMiddle',
            }),
            colWidths: 150,
            headerTooltips: true,
        });

        // Asigna la instancia de Handsontable a hotTableRef
        hotTableRef.current.hotInstance = hot;
        setInstanceReady(true);

        // Llama a onHandsontableReady solo si está definida
        if (typeof onHandsontableReady === 'function') {
            onHandsontableReady(hot);
        }

        // Cleanup al desmontar el componente
        return () => {
            hot.destroy();
            setInstanceReady(false);
        };
    }, [hotTableRef, onHandsontableReady, initialData]);

    return (
        <Box>
            <Box sx={{ overflow: 'auto', height: '500px' }}>
                <div ref={hotTableRef}></div>
            </Box>
        </Box>
    );
};

export default RegistroAsignacionMasivo;
