import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import Handsontable from 'handsontable';
import 'handsontable/dist/handsontable.full.css';
import { tokens } from '../../theme';

const RegistroLecturaAgua = ({ id_empresa, id_edificio, onAddSuccess = () => {}, onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const hotTableRef = useRef(null);
  const [fechaLectura, setFechaLectura] = useState('');
  const [departamentos, setDepartamentos] = useState([]);
  const [hotInstance, setHotInstance] = useState(null);

  useEffect(() => {
    fetchDepartamentos();
  }, [id_empresa, id_edificio]);

  useEffect(() => {
    if (departamentos.length && hotTableRef.current) {
      const data = departamentos.map(dep => [dep.numero_departamento, '']);
      const hot = new Handsontable(hotTableRef.current, {
        data,
        rowHeaders: true,
        colHeaders: ['Departamento', 'Lectura de Agua'],
        columns: [{ readOnly: true }, {}],
        width: '100%',
        height: 400,
        licenseKey: 'non-commercial-and-evaluation'
      });
      setHotInstance(hot);
    }
  }, [departamentos]);

  const fetchDepartamentos = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Token de autorización no encontrado.');
        return;
      }

      const response = await axios.get(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      
      setDepartamentos(response.data);
    } catch (error) {
      console.error('Error al obtener los departamentos:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSave = hotInstance.getData();
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Token de autorización no encontrado.');
        return;
      }

      for (let row of dataToSave) {
        const numero_departamento = row[0];
        const lectura_agua = row[1];

        if (!lectura_agua) continue; // Omitir si no hay lectura de agua

        const departamento = departamentos.find(dep => dep.numero_departamento == numero_departamento);
        if (departamento) {
          await axios.post(
            `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/lectura_agua/insertar`,
            {
              id_empresa: id_empresa,
              id_edificio: id_edificio,
              id_departamento: departamento.id_departamento,
              fecha_lectura: fechaLectura,
              lectura_agua: parseFloat(lectura_agua)
            },
            {
              headers: { Authorization: `Bearer ${token}` }
            }
          );
        }
      }

      onAddSuccess(); // Llamar a la función de éxito proporcionada por el componente padre
      onClose(); // Cerrar el diálogo después del registro
    } catch (error) {
      console.error('Error al agregar las lecturas de agua:', error);
    }
  };

  const resetFields = () => {
    setFechaLectura('');
    if (hotInstance) {
      hotInstance.loadData(departamentos.map(dep => [dep.numero_departamento, '']));
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', maxWidth: 500, mx: 'auto', textAlign: 'center' }}>
      <Typography variant="h6" mb={2} sx={{ fontSize: '1.5rem', color: colors.blueAccent[700] }}>
        Registrar Lecturas de Agua
      </Typography>
      <TextField
        label="Fecha de Registro"
        variant="outlined"
        type="date"
        value={fechaLectura}
        onChange={(e) => setFechaLectura(e.target.value)}
        fullWidth
        required
        sx={{ mb: 2, width: '100%' }}
        InputLabelProps={{ shrink: true }}
      />
      <Box ref={hotTableRef} sx={{ mb: 2 }}></Box>
      <Box mt={2} display="flex" justifyContent="center">
        <Button type="submit" variant="contained" color="primary" sx={{ backgroundColor: colors.blueAccent[700] }}>
          Registrar
        </Button>
      </Box>
    </Box>
  );
};

export default RegistroLecturaAgua;
