import { useState } from "react";
import axios from "axios";

const useImageUpload = (id_empresa, id_edificio) => {
  const [uploading, setUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [error, setError] = useState(null);

  const getHeaders = () => {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token no encontrado.");
    return { Authorization: `Bearer ${token}`, "Content-Type": "application/json" };
  };

  const uploadImage = async (imageFile) => {
    if (!imageFile) {
      setError("Por favor, selecciona una imagen.");
      return null;
    }

    if (!imageFile.type.startsWith("image/") || imageFile.size === 0) {
      setError("Por favor, selecciona un archivo de imagen válido.");
      return null;
    }

    setUploading(true);
    setError(null);

    try {
      const uniqueId = crypto.randomUUID();
      const fileKey = `contometros/${uniqueId}_${imageFile.name}`;

      // Obtener URL pre-firmada desde el backend
      const presignedUrlResponse = await axios.post(
        `https://app.propia.pe/apiRest/s3/upload`,
        { fileKey },
        { headers: getHeaders() }
      );

      const presignedUrl = presignedUrlResponse.data;

      // Subir imagen a S3
      await axios.put(presignedUrl, imageFile, {
        headers: { "Content-Type": imageFile.type || "application/octet-stream" },
      });

      const uploadedUrl = presignedUrl.split("?")[0];
      setImageUrl(uploadedUrl);
      return uploadedUrl;
    } catch (err) {
      setError(err.response?.data || err.message || "Error al subir la imagen.");
      return null;
    } finally {
      setUploading(false);
    }
  };

  const saveLecturaAgua = async (lecturaAgua, imageFile) => {
    try {
      if (!lecturaAgua.id_departamento) {
        throw new Error("El campo 'id_departamento' es obligatorio.");
      }

      let uploadedUrl = lecturaAgua.url_imagen_contometro;

      // Subir la imagen si existe un archivo
      if (imageFile) {
        uploadedUrl = await uploadImage(imageFile);
        if (!uploadedUrl) throw new Error("Error al subir la imagen.");
      }

      const lecturaConImagen = {
        ...lecturaAgua,
        url_imagen_contometro: uploadedUrl,
        fecha_lectura: lecturaAgua.fecha_lectura || new Date().toISOString().split("T")[0], // Fecha actual
      };

      const response = await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/lecturas_agua/insertar`,
        [lecturaConImagen],
        { headers: getHeaders() }
      );

      return response.data;
    } catch (err) {
      setError(err.response?.data || err.message || "Error al guardar la lectura de agua.");
      return null;
    }
  };

  const modificarLecturaAgua = async (lecturaAgua, imageFile, id_empresa, id_edificio) => {
    try {
      // Validar que el ID de la lectura esté presente
      if (!lecturaAgua.id_lectura_agua) {
        throw new Error("El campo 'id_lectura_agua' es obligatorio para modificar una lectura.");
      }
  
      // Mantener la URL de la imagen existente o subir una nueva imagen
      let uploadedUrl = lecturaAgua.url_imagen_contometro;
  
      if (imageFile) {
        uploadedUrl = await uploadImage(imageFile);
        if (!uploadedUrl) {
          throw new Error("Error al subir la imagen.");
        }
      }
  
      // Crear el objeto de la lectura con los datos actualizados
      const lecturaConImagen = {
        ...lecturaAgua,
        url_imagen_contometro: uploadedUrl,
      };
  
      // Enviar la solicitud PUT al backend con los IDs correctos
      const response = await axios.put(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/lecturas_agua/modificar`,
        lecturaConImagen,
        { headers: getHeaders() }
      );
  
      return response.data; // Retornar los datos de la respuesta
    } catch (err) {
      // Manejar errores
      setError(err.response?.data || err.message || "Error al modificar la lectura de agua.");
      return null;
    }
  };
  
  

  return {
    uploading,
    imageUrl,
    error,
    uploadImage,
    saveLecturaAgua,
    modificarLecturaAgua,
  };
};

export default useImageUpload;
