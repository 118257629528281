import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTheme, Typography, Alert, Container, Box, Button, Dialog, Snackbar } from '@mui/material';
import { useParams } from "react-router-dom";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import FormularioPorcentajeParticipacionMasivo from "../formularioPorcentajeParticipacion/formularioPorcentajeParticipacion";

const PorcentajeParticipacion = () => {
  const { id_empresa, id_edificio } = useParams();
  const [data, setData] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    fetchData();
  }, [id_empresa, id_edificio]); 

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token'); 

      if (!token) {
        setError("No se encontró el token de autenticación.");
        return;
      }

      const [porcentajeResponse, departamentosResponse] = await Promise.all([
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/porcentaje_participacion`, {
          headers: { Authorization: `Bearer ${token}` }
        }),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos`, {
          headers: { Authorization: `Bearer ${token}` }
        })
      ]);

      setData(Array.isArray(porcentajeResponse.data) ? porcentajeResponse.data : []);
      setDepartamentos(Array.isArray(departamentosResponse.data) ? departamentosResponse.data : []);
      setError(null);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
      setError("Hubo un error al obtener los datos.");
    }
  };

  const calculateTotal = () => {
    return data.reduce((acc, item) => acc + (item.porcentaje_participacion || 0), 0);
  };

  const handleAddSuccess = () => {
    const total = calculateTotal();
    if (total !== 100) {
      setError(`La suma total de porcentajes es ${total}%. Debería ser 100%. Por favor, revisa los valores.`);
      return;
    }
    fetchData();
    setOpen(false);
  };

  const totalMontoCuota = calculateTotal();

  return (
    <Container style={{ marginTop: theme.spacing(1) }}>
      <Box>
        <Header title="Porcentaje de Participación" subtitle="Lista de porcentajes de participación" />

        {error && (
          <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={() => setError(null)}>
            <Alert onClose={() => setError(null)} severity="error">
              {error}
            </Alert>
          </Snackbar>
        )}

        <Box style={{ display: 'flex', justifyContent: 'center', alignItems:'center', marginBottom: theme.spacing(2) }}>
          <div>
            <Typography variant="body1">
              <strong>Total :</strong> 
              <span style={{
                  backgroundColor: colors.blueAccent[700] || 'blue', 
                  color: theme.palette.primary.contrastText, 
                  padding: theme.spacing(0.5, 1), 
                  borderRadius: theme.spacing(0.5),
                  fontSize: '1.2em' 
              }}>
                {totalMontoCuota.toFixed(2)}%
              </span>
            </Typography>
          </div>
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center" m="0 0 20px 0">
          <Button
            variant="contained"
            onClick={() => setOpen(true)}
            style={{ backgroundColor: colors.blueAccent[700] || 'blue' }}
          >
            Agregar Porcentaje
          </Button>
        </Box>

        <Box
          m="10px 0 0 0"
          height="60vh"
          sx={{
            "& .MuiDataGrid-root": { border: "none" },
            "& .MuiDataGrid-cell": { borderBottom: "none" },
            "& .name-column--cell": { color: colors.greenAccent[300] },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700] || 'blue',
              borderBottom: "none",
              fontSize: "14px",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
              fontSize: "13px",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700] || 'blue',
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200] || 'green'} !important`,
            },
          }}
        >
          <DataGrid
            rows={departamentos.map(dep => ({
              id: dep.id_departamento,
              numero_departamento: dep.numero_departamento,
              porcentaje_participacion: data.find(p => p.id_departamento === dep.id_departamento)?.porcentaje_participacion || "N/A"
            }))}
            columns={[
              { field: "numero_departamento", headerName: "Número Departamento", flex: 1 },
              { field: "porcentaje_participacion", headerName: "Porcentaje de Participación %", flex: 1 }
            ]}
          />
        </Box>

        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
          <FormularioPorcentajeParticipacionMasivo
            idEdificio={id_edificio}
            departamentos={departamentos}
            onAddSuccess={handleAddSuccess}
          />
        </Dialog>
      </Box>
    </Container>
  );
};

export default PorcentajeParticipacion;
