import React, { useState, useEffect } from "react";
import {  Dialog,  DialogTitle,  DialogContent,  DialogActions,  Button,  TextField,  CircularProgress,  Box,  Autocomplete,  Snackbar,
  Alert,
  Card,
  CardMedia,
} from "@mui/material";
import axios from "axios";
import useCobranzas from "../Hook/cobranzas/useCobranzas";

const RegistroCobranzaDialog = ({ open, onClose, onPagoRegistrado, id_empresa, id_edificio }) => {
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState(null);
  const [recibosPendientes, setRecibosPendientes] = useState([]);
  const [selectedRecibo, setSelectedRecibo] = useState(null);
  const [fechaPago, setFechaPago] = useState("");
  const [montoPago, setMontoPago] = useState("");
  const [totalReciboMantenimiento, setTotalReciboMantenimiento] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [loadingRecibos, setLoadingRecibos] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState(false);

  const { uploading, saveCobranza, error: uploadError } = useCobranzas(id_empresa, id_edificio);

  useEffect(() => {
    if (open) {
      fetchDepartamentosAsignados();
    } else {
      resetForm();
    }
  }, [open, id_edificio]);

  const fetchDepartamentosAsignados = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}`, "Content-Type": "application/json" };

      const response = await axios.get(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos`,
        { headers }
      );
      setDepartamentos(response.data);
    } catch (error) {
      console.error("Error al obtener los departamentos:", error);
    }
  };

  const fetchRecibosPendientes = async (idDepartamento) => {
    setLoadingRecibos(true);
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}`, "Content-Type": "application/json" };

      const response = await axios.get(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/recibos_emitidos`,
        { headers }
      );

      const recibos = response.data.filter(
        (recibo) => recibo.id_departamento === idDepartamento && !recibo.estado
      );
      setRecibosPendientes(recibos);
    } catch (error) {
      console.error("Error al obtener los recibos pendientes:", error);
    } finally {
      setLoadingRecibos(false);
    }
  };

  const resetForm = () => {
    setSelectedDepartamento(null);
    setSelectedRecibo(null);
    setFechaPago("");
    setMontoPago("");
    setTotalReciboMantenimiento(0);
    setRecibosPendientes([]);
    setSelectedFile(null);
    setPreviewUrl("");
  };

  const handleDepartamentoChange = (event, newValue) => {
    setSelectedDepartamento(newValue);
    if (newValue) {
      fetchRecibosPendientes(newValue.id_departamento);
    }
  };

  const handleReciboChange = (event, newValue) => {
    setSelectedRecibo(newValue);
    if (newValue) {
      setTotalReciboMantenimiento(newValue.total_recibo_mantenimiento);
      setMontoPago(newValue.total_recibo_mantenimiento.toFixed(2));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleGuardarPago = async () => {
    const esPagoAtrasado = new Date(fechaPago) > new Date(selectedRecibo.fecha_vencimiento);

    const nuevoPago = {
      id_recibo: selectedRecibo.id_recibo,
      id_edificio: parseInt(id_edificio),
      id_departamento: selectedDepartamento.id_departamento,
      fecha_pago: fechaPago,
      monto_pagos: parseFloat(montoPago),
      es_pago_atrasado: esPagoAtrasado,
      url_comprobante_pago_recibo: null,
    };

    try {
      const result = await saveCobranza(nuevoPago, selectedFile);
      if (result) {
        setMensaje("Pago registrado correctamente.");
        setError(false);
        setAlertOpen(true);
        onPagoRegistrado();
        onClose();
      }
    } catch (err) {
      console.error("Error al registrar el pago:", err);
      setMensaje("Error al registrar el pago.");
      setError(true);
      setAlertOpen(true);
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Registrar Pago</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap="1rem">
          <Autocomplete
            options={departamentos}
            getOptionLabel={(option) => option.numero_departamento.toString()}
            onChange={handleDepartamentoChange}
            renderInput={(params) => <TextField {...params} label="Número de Departamento" />}
          />
          {loadingRecibos ? (
            <CircularProgress />
          ) : (
            <Autocomplete
              options={recibosPendientes}
              getOptionLabel={(option) =>
                `Recibo: ${option.numero_recibo} - Fecha: ${new Date(option.fecha_emision).toLocaleDateString()} - Monto: S/ ${option.total_recibo_mantenimiento.toFixed(2)}`
              }
              onChange={handleReciboChange}
              renderInput={(params) => <TextField {...params} label="Recibo Pendiente" />}
            />
          )}
          <TextField
            label="Total Recibo Mantenimiento"
            type="number"
            value={totalReciboMantenimiento}
            InputProps={{ readOnly: true }}
          />
          <TextField
            label="Fecha de Pago"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={fechaPago}
            onChange={(e) => setFechaPago(e.target.value)}
          />
          <TextField
            label="Monto del Pago"
            type="number"
            value={montoPago}
            onChange={(e) => setMontoPago(e.target.value)}
            error={montoPago > totalReciboMantenimiento}
            helperText={
              montoPago > totalReciboMantenimiento ? "El monto no puede exceder el total del recibo." : ""
            }
          />
          <input type="file" accept="image/*" onChange={handleFileChange} />
          {previewUrl && (
            <Card>
              <CardMedia component="img" height="200" image={previewUrl} alt="Comprobante" />
            </Card>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={handleGuardarPago}
          disabled={uploading || !selectedRecibo || !selectedDepartamento || !fechaPago || !montoPago}
        >
          {uploading ? <CircularProgress size={24} /> : "Guardar"}
        </Button>
      </DialogActions>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleAlertClose} severity={error ? "error" : "success"} variant="filled">
          {mensaje}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default RegistroCobranzaDialog;
