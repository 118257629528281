import { useState, useEffect } from "react";
import axios from "axios";

const useFetchData = (selectedMonth, selectedYear, id_empresa, id_edificio) => {
  const [loading, setLoading] = useState(true);
  const [administracionData, setAdministracionData] = useState([]);
  const [serviciosData, setServiciosData] = useState([]);
  const [personalData, setPersonalData] = useState([]);
  const [pagosPersonalData, setPagosPersonalData] = useState([]);
  const [gastosBancariosData, setGastosBancariosData] = useState([]);
  const [gastosVariosData, setGastosVariosData] = useState([]);
  const [mantenimientosData, setMantenimientosData] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token de autorización no encontrado.");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const endpoints = [
        axios.get(
          `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/pagos_administracion`,
          { headers }
        ),
        axios.get(
          `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/servicios_publicos`,
          { headers }
        ),
        axios.get(
          `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/personal`,
          { headers }
        ),
        axios.get(
          `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/pagos_personal_base`,
          { headers }
        ),
        axios.get(
          `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/gastos_bancarios`,
          { headers }
        ),
        axios.get(
          `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/gastos_mensuales`,
          {
            params: { month: selectedMonth, year: selectedYear },
            headers,
          }
        ),
        axios.get(
          `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/mantenimientos`,
          { headers }
        ),
      ];

      const responses = await Promise.allSettled(endpoints);

      const safeData = (response, defaultValue = []) =>
        response.status === "fulfilled" ? response.value.data || defaultValue : defaultValue;

      setAdministracionData(
        safeData(responses[0]).map((item) => ({
          id: `admin-${item.id_pago_administracion}`,
          concepto: item.descripcion || "Sin descripción",
          fecha: item.fecha_pago || "Fecha no disponible",
          monto: item.monto || 0,
          tipo: "Administración",
          url: null, // No tiene URL
        }))
      );
      
      setServiciosData(
        safeData(responses[1]).map((item) => ({
          id: `servicio-${item.id_servicio_publico}`,
          concepto: item.descripcion_servicio_publico || "Sin descripción",
          fecha: item.fecha_pago_servicio_publico || "Fecha no disponible",
          monto: item.monto_servicio_publico || 0,
          tipo: "Servicios Públicos",
          url: item.url_comprobante_servicios || null,
        }))
      );
      
      setPagosPersonalData(
        safeData(responses[3]).map((item) => ({
          id: `pago-personal-${item.id_pago_base}`,
          concepto: item.descripcion || "Sin descripción",
          fecha: item.fecha_pago || "Fecha no disponible",
          monto: item.monto_base || 0,
          tipo: "Pagos Personal",
          url: item.url_boucher_pago || null,
        }))
      );
      
      setGastosBancariosData(
        safeData(responses[4]).map((item) => ({
          id: `gasto-bancario-${item.id_gasto_bancario}`,
          concepto: item.descripcion || "Sin descripción",
          fecha: item.fecha_gasto || "Fecha no disponible",
          monto: item.monto_gasto || 0,
          tipo: "Gastos Bancarios",
          url: item.url_comprobante_bancarios || null,
        }))
      );
      
      setGastosVariosData(
        safeData(responses[5]).map((item) => ({
          id: `gasto-varios-${item.id_gastos_mensuales}`,
          concepto: item.concepto_gasto_mensual || "Sin descripción",
          fecha: item.fecha_gasto_mensual || "Fecha no disponible",
          monto: item.importe_gasto_mensual || 0,
          tipo: "Gastos Varios",
          url: item.url_boucher_gasto || null,
        }))
      );
      
      setMantenimientosData(
        safeData(responses[6]).map((item) => ({
          id: `mantenimiento-${item.id_mantenimiento}`,
          concepto: item.concepto_mantenimiento || "Sin descripción",
          fecha: item.fecha_mantenimiento || "Fecha no disponible",
          monto: item.importe_mantenimiento || 0,
          tipo: "Mantenimiento",
          url: item.url_comprobante_mantenimientos || null,
        }))
      );
      
      
      
      
    } catch (error) {
      console.error("Error al obtener los datos:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedMonth, selectedYear, id_empresa, id_edificio]);

  return {
    loading,
    administracionData,
    serviciosData,
    personalData,
    pagosPersonalData,
    gastosBancariosData,
    gastosVariosData,
    mantenimientosData,
    fetchData,
  };
};

export default useFetchData;
