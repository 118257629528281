import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchPropietarios = (id_empresa, id_edificio, selectedMonth, selectedYear) => {
  const [loading, setLoading] = useState(true);
  const [asignacionData, setAsignacionData] = useState([]);
  const [porcentajeData, setPorcentajeData] = useState([]);
  const [departamentosData, setDepartamentosData] = useState([]);
  const [propietariosData, setPropietariosData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token'); // Obtener el token del localStorage

        const [
          asignacionResponse,
          porcentajeResponse,
          departamentosResponse,
          propietariosResponse
        ] = await Promise.all([
          axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/asignaciones`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/porcentaje_participacion`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/propietarios`, {
            headers: { Authorization: `Bearer ${token}` },
          })
        ]);

        setAsignacionData(asignacionResponse.data);
        setPorcentajeData(porcentajeResponse.data);
        setDepartamentosData(departamentosResponse.data);
        setPropietariosData(propietariosResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id_empresa, id_edificio, selectedMonth, selectedYear]);

  return {
    asignacionData,
    porcentajeData,
    departamentosData,
    propietariosData,
    loading
  };
};

export default useFetchPropietarios;
