import React, { useState } from 'react';
import { Dialog, DialogContent, Button, Typography, Table, TableBody, TableCell, TableHead, TableRow, Box, useTheme } from '@mui/material';
import { saveAs } from 'file-saver';
import { pdf, Page, Document } from '@react-pdf/renderer';
import ReciboMantenimientoPDF from '../../reciboMantenimientoPDF/reciboMantenimientoPDF';
import { tokens } from "../../../theme";


const ReciboMantenimientoDialog = ({ open, onClose, departamentosData, onEmitirRecibos, idEdificio, totalReciboMantenimiento }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
 
  console.log("Recibiendo departamentosData en ReciboMantenimientoDialog al inicio:", departamentosData);

  const handleDownloadPDF = async (departamento) => {
    const doc = (
      <Document>
        <Page size="A4">
          <ReciboMantenimientoPDF departamento={departamento} />
        </Page>
      </Document>
    );
    const blob = await pdf(doc).toBlob();
    saveAs(blob, `Recibo_${departamento.numero_departamento}.pdf`);
  };


  console.log("DepartamentosData justo antes de renderizar en return:", departamentosData);


  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      {open && (
        <Box className="receipt-container">
           
           {departamentosData.map((departamento, index) => (
            <div key={departamento.id} className="receipt">
              
              <DialogContent>
                <div className="receipt-header">
                  <h2>Recibo de Mantenimiento</h2>
                </div>
                <div className="receipt-section">

                <div className="receipt-details">
              <div className="receipt-section-title">Datos del Recibo</div>
              <p><strong>Fecha de Emisión:</strong> {departamento.fechaEmision}</p>
              <p><strong>Fecha de Vencimiento:</strong> {departamento.fechaVencimiento}</p>
              <p><strong>Número de Recibo:</strong> {departamento.numeroRecibo}</p>
              <p><strong>Monto Total:</strong> S/.{departamento.montoTotal}</p>
            </div>



                  <div className="receipt-details">
                    <div className="receipt-section-title">Datos del Propietario</div>
                    <p><strong>Nombre:</strong> {departamento.nombre_propietario}</p>
                    <p><strong>Apellido:</strong> {departamento.apellido_propietario}</p>
                    <p><strong>Departamento:</strong> {departamento.numero_departamento}</p>
                    <p><strong>Porcentaje de Participación:</strong> {departamento.porcentaje_participacion}%</p>

                  </div>
                </div>
                <div className="receipt-section">
                  <div className="receipt-details">
                    <div className="receipt-section-title">Detalles de Pago</div>
                    <p><strong>Cuota Mantenimiento:</strong> {departamento.monto_cuota}</p>
                    <p><strong>Monto Agua (Soles):</strong> {departamento.costo_total}</p>
                    {/* <p><strong>Deuda Pendiente: S/.</strong> {departamento.saldo_pendiente}</p> */}
                  </div>
                </div>
                <div className="receipt-section">
                  <div className="receipt-details">
                    <div className="receipt-section-title">Total a pagar</div>
                    <p><strong>Total Recibo Mantenimiento:</strong> {departamento.total_recibo_mantenimiento}</p>
                  </div>
                </div>
                <div className="receipt-section">
                  <div className="receipt-details">
                    <div className="receipt-section-title">Consumo de Agua</div>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell><strong>Lectura Anterior</strong></TableCell>
                          <TableCell><strong>Lectura Actual</strong></TableCell>
                          <TableCell><strong>Consumo (m3)</strong></TableCell>
                          <TableCell><strong>Costo Total (Soles)</strong></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{departamento.lectura_anterior}</TableCell>
                          <TableCell>{departamento.lectura_actual}</TableCell>
                          <TableCell>{departamento.consumo_m3}</TableCell>
                          <TableCell>{departamento.costo_total}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </div>
                <Box display="flex" flexDirection="column" alignItems="start" m="20px 0">
                  <Typography variant="h6">Datos de la Cuenta</Typography>
                  <Typography><strong>Nombre:</strong> {departamento.nombre_junta_propietario}</Typography>
                  <Typography><strong>RUC:</strong> {departamento.ruc}</Typography>           
                  <Typography><strong>Banco:</strong> {departamento.nombre_banco}</Typography>
                  <Typography><strong>Número de Cuenta:</strong> {departamento.numero_cuenta}</Typography>
                  <Typography><strong>Número de Cuenta CCI:</strong> {departamento.numero_cuenta_cci}</Typography>
                </Box>
                <div className="receipt-footer">
                    <Button
                      variant="contained"
                      style={{ margin: '10px', backgroundColor: colors.blueAccent[700] }}
                      onClick={() => handleDownloadPDF(departamento)}
                    >
                      Descargar PDF
                    </Button>
                    
                  </div>
                {index !== departamentosData.length - 1 && <div className="receipt-separator" />}
              </DialogContent>
            </div>
          ))}
        </Box>
      )}
       
   


    </Dialog>
  );
};

export default ReciboMantenimientoDialog;
