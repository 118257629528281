import { useState } from "react";
import axios from "axios";

const useIngresos = (id_empresa, id_edificio) => {
  const [uploading, setUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [error, setError] = useState(null);

  const getHeaders = () => {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token no encontrado.");
    return { Authorization: `Bearer ${token}`, "Content-Type": "application/json" };
  };

  const uploadComprobante = async (imageFile) => {
    if (!imageFile) {
      setError("Por favor, selecciona una imagen.");
      return null;
    }

    if (!imageFile.type.startsWith("image/") || imageFile.size === 0) {
      setError("Por favor, selecciona un archivo de imagen válido.");
      return null;
    }

    setUploading(true);
    setError(null);

    try {
      const uniqueId = crypto.randomUUID();
      const fileKey = `ingresos/${uniqueId}_${imageFile.name}`;

      // Obtener URL pre-firmada desde el backend
      const presignedUrlResponse = await axios.post(
        `https://app.propia.pe/apiRest/s3/upload`,
        { fileKey },
        { headers: getHeaders() }
      );

      const presignedUrl = presignedUrlResponse.data;

      // Subir imagen a S3
      await axios.put(presignedUrl, imageFile, {
        headers: { "Content-Type": imageFile.type || "application/octet-stream" },
      });

      const uploadedUrl = presignedUrl.split("?")[0];
      setImageUrl(uploadedUrl);
      return uploadedUrl;
    } catch (err) {
      setError(err.response?.data || err.message || "Error al subir la imagen.");
      return null;
    } finally {
      setUploading(false);
    }
  };

  const saveIngreso = async (ingresoData, imageFile) => {
    try {
      let uploadedUrl = ingresoData.url_boucher_pago_ingresos;

      if (imageFile) {
        uploadedUrl = await uploadComprobante(imageFile);
        if (!uploadedUrl) throw new Error("Error al subir la imagen.");
      }

      const dataConComprobante = {
        ...ingresoData,
        url_boucher_pago_ingresos: uploadedUrl,
      };

      const response = await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/ingresos/insertar`,
        dataConComprobante,
        { headers: getHeaders() }
      );

      return response.data;
    } catch (err) {
      setError(err.response?.data || err.message || "Error al guardar el ingreso.");
      return null;
    }
  };

  return {
    uploading,
    imageUrl,
    error,
    uploadComprobante,
    saveIngreso,
  };
};

export default useIngresos;
