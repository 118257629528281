import { useState, useRef } from 'react';
import axios from 'axios';

const useStep3 = (id_empresa, id_edificio, departamentos) => {
  const hotTableRefPorcentaje = useRef(null);

  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token de autorización no encontrado.');
    }
    return { Authorization: `Bearer ${token}` };
  };

  const savePorcentaje = async () => {
    if (!hotTableRefPorcentaje.current || !hotTableRefPorcentaje.current.hotInstance) {
      console.error('Handsontable instance is not ready');
      return false;
    }

    const dataToSave = hotTableRefPorcentaje.current.hotInstance.getData();

    try {
      for (let row of dataToSave) {
        // Validar que exista el número de departamento y el porcentaje
        if (!row[0] || row[1] === '') continue;

        const departamento = departamentos.find(
          (dep) => dep.numero_departamento.toString() === row[0].toString()
        );

        if (!departamento) {
          console.warn(`Departamento con número ${row[0]} no encontrado.`);
          continue;
        }

        const porcentajeData = {
          id_empresa,
          id_edificio,
          id_departamento: departamento.id_departamento,
          porcentaje_participacion: parseFloat(row[1]),
        };

        await axios.post(
          `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/porcentaje_participacion/insertar`,
          porcentajeData,
          { headers: getAuthHeaders() }
        );
      }
      return true;
    } catch (error) {
      console.error('Error al guardar porcentajes de participación:', error);
      return false;
    }
  };

  return {
    hotTableRefPorcentaje,
    savePorcentaje,
  };
};

export default useStep3;
