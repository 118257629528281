import React, { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import axios from 'axios';

const RegistroDepartamento = ({ idEdificio, onAddSuccess }) => {
  const [numeroDepartamento, setNumeroDepartamento] = useState('');
  const [pisoDepartamento, setPisoDepartamento] = useState('');
  const [vistaDepartamento, setVistaDepartamento] = useState('');
  const [tipoDepartamento, setTipoDepartamento] = useState('');
  const [tipoUso, setTipoUso] = useState('');
  const [area, setArea] = useState('');
  const [numeroHabitaciones, setNumeroHabitaciones] = useState(0);
  const [numeroBanos, setNumeroBanos] = useState(0);
  const [estado, setEstado] = useState(true);

  const handleRegistro = async (e) => {
    e.preventDefault();

    const departamentoData = {
      id_edificio: idEdificio,
      numero_departamento: numeroDepartamento,
      piso_departamento: pisoDepartamento,
      vista_departamento: vistaDepartamento,
      tipo_departamento: tipoDepartamento,
      tipo_uso: tipoUso,
      area: area,
      numero_habitaciones: numeroHabitaciones,
      numero_baños: numeroBanos,
      estado: estado,
    };

    try {
      await axios.post('https://app.propia.pe/apiRest/departamentos/insertar', departamentoData);
      resetFields();
      alert('Departamento agregado exitosamente');
      if (onAddSuccess) onAddSuccess(); // Llama a la función de devolución de llamada si se proporciona
    } catch (error) {
      console.error('Error al agregar el departamento:', error);
    }
  };

  const resetFields = () => {
    setNumeroDepartamento('');
    setPisoDepartamento('');
    setVistaDepartamento('');
    setTipoDepartamento('');
    setTipoUso('');
    setArea('');
    setNumeroHabitaciones(0);
    setNumeroBanos(0);
    setEstado(true);
  };

  return (
    <Box m="20px">
      <h2>Registro de Departamento</h2>
      <form onSubmit={handleRegistro}>
        <TextField
          label="Número de Departamento"
          variant="outlined"
          fullWidth
          required
          value={numeroDepartamento}
          onChange={(e) => setNumeroDepartamento(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Piso del Departamento"
          variant="outlined"
          fullWidth
          required
          value={pisoDepartamento}
          onChange={(e) => setPisoDepartamento(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Vista del Departamento"
          variant="outlined"
          fullWidth
          required
          value={vistaDepartamento}
          onChange={(e) => setVistaDepartamento(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Tipo de Departamento"
          variant="outlined"
          fullWidth
          required
          value={tipoDepartamento}
          onChange={(e) => setTipoDepartamento(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Tipo de Uso"
          variant="outlined"
          fullWidth
          required
          value={tipoUso}
          onChange={(e) => setTipoUso(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Área"
          variant="outlined"
          fullWidth
          required
          value={area}
          onChange={(e) => setArea(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Número de Habitaciones"
          variant="outlined"
          fullWidth
          required
          type="number"
          value={numeroHabitaciones}
          onChange={(e) => setNumeroHabitaciones(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Número de Baños"
          variant="outlined"
          fullWidth
          required
          type="number"
          value={numeroBanos}
          onChange={(e) => setNumeroBanos(e.target.value)}
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Agregar
        </Button>
      </form>
    </Box>
  );
};

export default RegistroDepartamento;
