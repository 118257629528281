import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardMedia,
} from "@mui/material";
import useImageUpload from "../Hook/lecturaAgua/useImageUpload";

const ImagenDialog = ({
  open,
  onClose,
  lectura, // Objeto lectura que contiene toda la información
  onUploadSuccess,
}) => {
  const { id_empresa, id_edificio, id_departamento, id_lectura, url_imagen_contometro, fecha_lectura } = lectura || {};
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(url_imagen_contometro || "");

  const { uploading, saveLecturaAgua, modificarLecturaAgua, error } = useImageUpload(id_empresa, id_edificio);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file)); // Mostrar previsualización de la imagen seleccionada
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Por favor, selecciona una imagen.");
      return;
    }
  
    if (!lectura?.id_lectura_agua) {
      alert("El campo 'id_lectura_agua' es obligatorio para modificar una lectura. Asegúrate de seleccionar una lectura válida.");
      return;
    }
  
    try {
      const lecturaData = {
        ...lectura,
        fecha_lectura: lectura.fecha_lectura || new Date().toISOString().split("T")[0],
      };
  
      const updatedData = await modificarLecturaAgua(lecturaData, selectedFile, id_empresa, id_edificio);
  
      if (updatedData) {
        onUploadSuccess(updatedData.url_imagen_contometro);
        alert("Imagen subida y guardada con éxito.");
        setSelectedFile(null);
        onClose();
      }
    } catch (err) {
      console.error("Error al subir la imagen:", err);
    }
  };
  
  
  
  

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{url_imagen_contometro ? "Actualizar Imagen del Contómetro" : "Subir Imagen del Contómetro"}</DialogTitle>
      <DialogContent>
        {uploading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" gap="20px">
            {/* Previsualización de la imagen seleccionada o la actual */}
            <Card sx={{ maxWidth: 345, marginBottom: "1rem" }}>
              {previewUrl ? (
                <CardMedia
                  component="img"
                  height="200"
                  image={previewUrl}
                  alt="Previsualización de la imagen"
                />
              ) : (
                <Box
                  sx={{
                    height: 200,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  <Typography color="textSecondary">No hay imagen seleccionada</Typography>
                </Box>
              )}
            </Card>

            {/* Input para seleccionar archivo */}
            <input
              accept="image/*"
              type="file"
              style={{ display: "none" }}
              id="upload-button"
              onChange={handleFileChange}
            />
            <label htmlFor="upload-button">
              <Button variant="outlined" component="span" color="primary">
                Seleccionar Imagen
              </Button>
            </label>

            {/* Botón para subir la imagen */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={!selectedFile || uploading}
            >
              {uploading ? <CircularProgress size={24} /> : "Guardar Imagen"}
            </Button>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ImagenDialog;
