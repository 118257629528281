import React, { useEffect, useState } from "react";
import { Box, Dialog, Button, Snackbar, Alert } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import RegistroPropietario from "../registroPropietarios/index";
import RegistroPropietariosVarios from "../registroPropietariosVarios/registroPropietariosVarios";
import { format } from "date-fns";

const Propietarios = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id_empresa, id_edificio } = useParams(); // Obtener id_empresa y id_edificio desde la URL
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openRegistroVarios, setOpenRegistroVarios] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, [id_empresa, id_edificio]);

  const fetchData = async () => {
    try {
      // Obtener el token de localStorage
      const token = localStorage.getItem("token");

      if (!token) {
        setError("No se encontró el token de autenticación.");
        return;
      }

      // Agregar el token en el encabezado de las solicitudes
      const headers = { Authorization: `Bearer ${token}` };

      // Realizar la solicitud de propietarios
      const response = await axios.get(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/propietarios`,
        { headers }
      );

      const propietariosData = response.data.map((residente, index) => ({
        ...residente,
        id: index + 1,
      }));
      setData(propietariosData);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
      if (error.response && error.response.status === 403) {
        setError("No tienes permiso para acceder a estos datos. Verifica tu autenticación.");
      } else {
        setError("Hubo un error al obtener los datos.");
      }
    }
  };

  const columns = [
    { field: "nombre_propietario", headerName: "Nombre", flex: 1 },
    { field: "apellido_propietario", headerName: "Apellido", flex: 1 },
    { field: "dni_propietario", headerName: "DNI", flex: 0.5 },
    { field: "email_propietario", headerName: "Email", flex: 1 },
    { field: "celular_propietario", headerName: "Celular", flex: 1 },
    { field: "tipo_propietario", headerName: "Tipo", flex: 1 },
    {
      field: "fecha_nacimiento",
      headerName: "Fecha de Nacimiento",
      flex: 1,
      valueFormatter: (params) => {
        return format(new Date(params.value), "dd/MM/yyyy");
      },
    },
    { field: "genero_propietario", headerName: "Género", flex: 0.5 },
  ];

  return (
    <Box m="20px">
      <Header title="Propietarios" subtitle="Lista de propietarios" />

      {error && (
        <Snackbar open={true} autoHideDuration={6000} onClose={() => setError(null)}>
          <Alert onClose={() => setError(null)} severity="error" sx={{ backgroundColor: colors.red[600] }}>
            {error}
          </Alert>
        </Snackbar>
      )}

      <Box sx={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
        <Button
          variant="contained"
          onClick={() => setOpen(true)}
          style={{ backgroundColor: colors.blueAccent[700], marginRight: "10px" }}
        >
          Agregar Propietario
        </Button>
        <Button
          variant="contained"
          onClick={() => setOpenRegistroVarios(true)}
          style={{ backgroundColor: colors.blueAccent[700] }}
        >
          Registro Masivo de Propietarios
        </Button>
      </Box>

      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            fontSize: "13px",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />

        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
          <RegistroPropietario idEdificio={id_edificio} fetchData={fetchData} />
        </Dialog>

        <Dialog open={openRegistroVarios} onClose={() => setOpenRegistroVarios(false)} maxWidth="lg" fullWidth>
          <RegistroPropietariosVarios idEdificio={id_edificio} fetchData={fetchData} />
        </Dialog>
      </Box>
    </Box>
  );
};

export default Propietarios;
