import React from 'react';
import { Dialog, DialogContent, Button, Typography, Box, Grid, Table, TableBody, TableCell, TableHead, TableRow ,useTheme} from '@mui/material';
import { saveAs } from 'file-saver';
import { pdf, Page, Document } from '@react-pdf/renderer';
import RecibosEmitidosPDF from './RecibosEmitidosPDF';
import { tokens } from "../../theme";

const ReciboEmitidoDialog = ({ open, onClose, recibo }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleDownloadPDF = async (recibo) => {
    const doc = (
      <Document>
        <Page size="A5">
          <RecibosEmitidosPDF recibo={recibo} />
        </Page>
      </Document>
    );
    const blob = await pdf(doc).toBlob();
    saveAs(blob, `Recibo_${recibo.numero_departamento}.pdf`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return date.toLocaleDateString();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      {open && (
        <Box sx={{ p:2 }}>
          <DialogContent>
            <Box sx={{ mb: 1, backgroundColor: "#f8f9fa", padding: 2, borderRadius: 1, border: "1px solid #3367b0" }}>
              <Grid container spacing={2} sx={{ mb: 1 }}>
                {/* Espacio 1: Información del Edificio */}
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: "#000" }}>
                    Junta de Propietarios
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', color: "#000" }}>
                    Edificio {recibo.nombre_edificio || 'Desconocido'}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#212529" }}>
                    {recibo.direccion_edificio || 'Desconocido'}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#212529" }}>
                    {recibo.distrito_edificio || 'Desconocido'}
                  </Typography>
                </Grid>

                {/* Espacio 2: Información del Recibo de Mantenimiento */}
                <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: "#000" }}>
                    Recibo de Mantenimiento
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#000" }}>
                    {formatDate(recibo.fecha_emision)}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#000" }}>
                    Número de Recibo: {recibo.numero_recibo}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#000" }}>
                    Fecha de Vencimiento: {formatDate(recibo.fecha_vencimiento)}
                  </Typography>
                </Grid>

                {/* Datos del Propietario directamente debajo de Recibo de Mantenimiento */}
                <Grid item xs={12} sx={{ textAlign: 'right'}}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', color: "#212529" }}>
                    Nombre: {recibo.nombre_propietario}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', color: "#212529" }}>
                    Apellido: {recibo.apellido_propietario}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', color: "#212529" }}>
                    Departamento: {recibo.numero_departamento}
                  </Typography>
                  {/* <Typography variant="body2" sx={{ fontWeight: 'bold', color: "#212529" }}>
                    Participación: {recibo.porcentaje_participacion}%
                  </Typography> */}
                </Grid>
              </Grid>
            </Box>

            {/* Detalles de Pago */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#3367b0', mb: 1 }}>
                  Detalles de Pago
                </Typography>
                <Box sx={{ border: "1px solid #dee2e6", borderRadius: 1, padding: 2, mb: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', color: "#212529" }}>
                        Cuota Mantenimiento:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'right' }}>
                      <Typography variant="body2" sx={{ color: "#212529" }}>
                        S/. {recibo.monto_cuota}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', color: "#212529" }}>
                        Monto Agua:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'right' }}>
                      <Typography variant="body2" sx={{ color: "#212529" }}>
                        S/. {recibo.costo_total}
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={6}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', color: "#212529" }}>
                        Deuda Pendiente:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'right' }}>
                      <Typography variant="body2" sx={{ color: "#212529" }}>
                        S/. {recibo.saldo_pendiente}
                      </Typography>
                    </Grid> */}

                    
                    
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            {/* Fila de Total Recibo Mantenimiento */}
            <Grid item xs={12}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: "#faa550", padding: 1, borderRadius: 1 }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', color: "#212529" }}>
                          Total Recibo Mantenimiento:
                        </Typography>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', color: "#212529" }}>
                          S/ {recibo.total_recibo_mantenimiento}
                        </Typography>
                      </Box>
                    </Grid>

            {/* Consumo de Agua */}
            <Grid container spacing={2} mt={0.5}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#3367b0', mb: 1 }}>
                  Consumo de Agua
                </Typography>
                <Table size="small" sx={{ border: "1px solid #3367b0", borderRadius: 1 }}>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#3367b0" }}>
                      <TableCell sx={{ color: "#ffffff", fontWeight: "bold", textAlign: "center" }}>Lectura Anterior</TableCell>
                      <TableCell sx={{ color: "#ffffff", fontWeight: "bold", textAlign: "center" }}>Lectura Actual</TableCell>
                      <TableCell sx={{ color: "#ffffff", fontWeight: "bold", textAlign: "center" }}>Consumo (m3)</TableCell>
                      <TableCell sx={{ color: "#ffffff", fontWeight: "bold", textAlign: "center" }}>Costo Total (Soles)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{recibo.lectura_anterior}</TableCell>
                      <TableCell>{recibo.lectura_actual}</TableCell>
                      <TableCell>{recibo.consumo_m3}</TableCell>
                      <TableCell>{recibo.costo_total}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>

            {/* Datos de la Cuenta con Imagen */}
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={8}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#3367b0', mb: 2 }}>
                  Datos de la Cuenta
                </Typography>
                <Box sx={{ border: "1px solid #dee2e6", borderRadius: 1, padding: 2 }}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', color: "#212529" }}>
                    Nombre: {recibo.nombre_junta_propietario}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', color: "#212529" }}>
                    RUC: {recibo.ruc}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', color: "#212529" }}>
                    Banco: {recibo.nombre_banco}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', color: "#212529" }}>
                    Número de Cuenta: {recibo.numero_cuenta}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', color: "#212529" }}>
                    Número de Cuenta CCI: {recibo.numero_cuenta_cci}
                  </Typography>
                  {recibo.tipoCuenta === "Cuenta Recaudadora" && (
                    <Typography variant="body2" sx={{ fontWeight: 'bold', color: "#212529" }}>
                      Código: {recibo.numero_departamento}
                    </Typography>
                  )}
                </Box>
              </Grid>
               {/* Imagen del Contómetro */}
                {recibo.url_imagen_contometro && (
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#3367b0' }}>Imagen del Contómetro</Typography>
                      <img src={recibo.url_imagen_contometro} alt="Contómetro" style={{ maxWidth: '80%', borderRadius: '8px' }} />
                    </Grid>
                  </Grid>
                )}
            </Grid>

            {/* Botón de Descargar PDF */}
            <Box sx={{ mt: 2, textAlign: 'right', padding: 1, borderRadius: 1 }}>
              <Button 
                variant="contained"
                color="primary"
                onClick={() => handleDownloadPDF(recibo)}
              >
                Descargar PDF
              </Button>
            </Box>
          </DialogContent>
        </Box>
      )}
    </Dialog>
  );
};

export default ReciboEmitidoDialog;
