import React from 'react';
import { TextField, InputLabel } from '@mui/material';

const GastosMensualesForm = ({ formData, handleInputChange, handleFileChange }) => (
    <>
        <TextField
            label="Concepto del Gasto"
            name="concepto_gasto_mensual"
            value={formData.concepto_gasto_mensual || ''}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            multiline
        />
        <TextField
            label="Fecha del Gasto"
            name="fecha_gasto_mensual"
            type="date"
            value={formData.fecha_gasto_mensual || ''}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            InputLabelProps={{ shrink: true }}
        />
        <TextField
            label="Importe del Gasto"
            name="importe_gasto_mensual"
            type="number"
            value={formData.importe_gasto_mensual || ''}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            inputProps={{ step: "0.01" }}
        />
        <InputLabel htmlFor="comprobante" style={{ marginTop: "10px" }}>Comprobante</InputLabel>
        <input
            id="comprobante"
            type="file"
            accept="image/*"
            onChange={(e) => handleFileChange(e.target.files[0])}
            style={{ margin: "10px 0" }}
        />
    </>
);

export default GastosMensualesForm;
