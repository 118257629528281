import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Table, TableRow, TableCell, TableBody, Button, Box, Typography } from '@mui/material';

const InformeEconomicoDialog = ({
    open, onClose,
    ingresosRecibosData = [],
    ingresosCuotaExtraData = [],
    ingresosOtrosData = [],
    ingresosCobranzaCuotasAtrasadasData = [],
    ingresosMorasData = [],
    personalData = [],
    serviciosData = [],
    administracionData = [],
    gastosVariosData = [],
    gastosBancariosData = [],
    mantenimientosData = [], // Añadido para los mantenimientos
    saldoInicialData = [],
    selectedMonth,
    selectedYear
}) => {
    useEffect(() => {
        // Aquí ya no hay registros innecesarios en la consola.
    }, [open, ingresosRecibosData, ingresosCuotaExtraData, ingresosOtrosData, ingresosCobranzaCuotasAtrasadasData, ingresosMorasData, personalData, serviciosData, mantenimientosData, administracionData, gastosVariosData, gastosBancariosData, saldoInicialData]);
    

    const calculateTotal = (data) => data.reduce((total, item) => total + item.monto, 0);

    const totalIngresosRecibos = calculateTotal(ingresosRecibosData);
    const totalCobranzaCuotasAtrasadas = calculateTotal(ingresosCobranzaCuotasAtrasadasData);
    const totalMoras = calculateTotal(ingresosMorasData);
    const totalCuotaExtra = calculateTotal(ingresosCuotaExtraData);
    const totalOtrosIngresos = calculateTotal(ingresosOtrosData);
    const totalSaldoInicial = calculateTotal(saldoInicialData);
    const totalMantenimientos = calculateTotal(mantenimientosData); // Total de Mantenimientos

    const ingresosTotal = totalIngresosRecibos + totalCobranzaCuotasAtrasadas + totalMoras + totalCuotaExtra + totalOtrosIngresos;
    const egresosTotal = calculateTotal([...personalData, ...serviciosData, ...mantenimientosData, ...administracionData, ...gastosVariosData, ...gastosBancariosData]);
    const saldoFinal = totalSaldoInicial + ingresosTotal - egresosTotal;

   
    const formatDate = (date) => date;
    const formatAmount = (amount) => amount.toFixed(2);

    const secciones = [
        {
            titulo: "Saldo Inicial",
            subcategorias: [
                {
                    datos: saldoInicialData.map(item => ({
                        concepto: item.descripcion,
                        fecha: item.fecha,
                        monto: item.monto
                    }))
                }
            ],
            total: totalSaldoInicial
        },
        {
            titulo: "Ingresos (Anexo 1)",
            subcategorias: [
                { titulo: "Recibos de Mantenimiento", datos: [{ concepto: 'Total Recibos de Mantenimiento', fecha: `${selectedYear}-${String(selectedMonth).padStart(2, '0')}-01`, monto: totalIngresosRecibos }] },
                { titulo: "Cobranza Cuotas Atrasadas", datos: [{ concepto: 'Total Cobranza Cuotas Atrasadas', fecha: `${selectedYear}-${String(selectedMonth).padStart(2, '0')}-01`, monto: totalCobranzaCuotasAtrasadas }] },
                { titulo: "Moras", datos: [{ concepto: 'Total Moras', fecha: `${selectedYear}-${String(selectedMonth).padStart(2, '0')}-01`, monto: totalMoras }] },
                { titulo: "Cuota Extra Ordinaria", datos: [{ concepto: 'Total Cuota Extra Ordinaria', fecha: `${selectedYear}-${String(selectedMonth).padStart(2, '0')}-01`, monto: totalCuotaExtra }] },
                { titulo: "Otros Ingresos", datos: [{ concepto: 'Total Otros Ingresos', fecha: `${selectedYear}-${String(selectedMonth).padStart(2, '0')}-01`, monto: totalOtrosIngresos }] }
            ],
            total: ingresosTotal
        },
        {
            titulo: "Egresos",
            subcategorias: [
                { titulo: "Remuneraciones", datos: personalData },
                { titulo: "Servicios", datos: serviciosData },
                { titulo: "Mantenimientos", datos: mantenimientosData }, // Sección de Mantenimientos
                { titulo: "Administración", datos: administracionData },
                { titulo: "Gastos Varios", datos: gastosVariosData },
                { titulo: "Gastos Bancarios", datos: gastosBancariosData }
            ],
            total: egresosTotal
        }
    ];

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth scroll="paper">
            <DialogTitle align="center">Informe Económico</DialogTitle>
            <DialogContent dividers={true} style={{ overflowY: 'auto' }}>
                {secciones.map((seccion, index) => (
                    <Box key={index} mb={2}>
                        <Typography variant="h6" gutterBottom>{seccion.titulo}</Typography>
                        {seccion.subcategorias.map((subcategoria, subIndex) => (
                            <Box key={subIndex} mb={2} pl={2}>
                                <Typography variant="subtitle1">{subcategoria.titulo}</Typography>
                                <Table size="small">
                                    <TableBody>
                                        {subcategoria.datos.map((dato, indexDato) => (
                                            <TableRow key={indexDato}>
                                                <TableCell style={{ width: '50%' }}>{dato.concepto}</TableCell>
                                                <TableCell style={{ width: '25%', textAlign: 'right' }}>{formatDate(dato.fecha)}</TableCell>
                                                <TableCell style={{ width: '25%', textAlign: 'right' }}>{formatAmount(dato.monto)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        ))}
                        <Box mt={2} pl={2} style={{ textAlign: 'right' }}>
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Total {seccion.titulo.split(' ')[0]}: S/.{formatAmount(seccion.total)}</Typography>
                        </Box>
                    </Box>
                ))}
                <Box mt={2} pl={2} style={{ textAlign: 'right' }}>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Saldo Final: S/.{formatAmount(saldoFinal)}</Typography>
                </Box>
                <Button onClick={onClose} color="primary" variant="contained" style={{ marginTop: '20px' }}>
                    Cerrar
                </Button>
            </DialogContent>
        </Dialog>
    );
};

export default InformeEconomicoDialog;
