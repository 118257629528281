import React, { useState } from 'react';
import { MenuItem, Box, Typography, useTheme } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import { tokens } from '../../theme';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const { id_edificio } = useParams();
  const isActive = location.pathname.startsWith(to.replace(':id_edificio', id_edificio));
  const [isHovered, setIsHovered] = useState(false);

  const iconStyle = {
    color: isHovered || isActive || selected === title ? 'white' : colors.grey[100],
    marginRight: theme.spacing(1),
  };

  return (
    <MenuItem
  active={isActive || selected === title ? true : undefined} // Solo pasa true o undefined
  style={{
    color: isHovered || isActive || selected === title ? 'white' : colors.grey[100],
    backgroundColor: isHovered || isActive || selected === title ? colors.blueAccent[700] : 'transparent',
    borderRadius: '8px',
    fontWeight: isActive || isHovered || selected === title ? 'bold' : 'normal',
  }}
  onMouseEnter={() => setIsHovered(true)}
  onMouseLeave={() => setIsHovered(false)}
  onClick={() => setSelected(title)}
>
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    {React.cloneElement(icon, { style: iconStyle })}
    <Typography
      variant="body1"
      style={{
        flexGrow: 1,
        color: isHovered || isActive || selected === title ? 'white' : colors.grey[100],
        fontSize: '16px',
      }}
    >
      {title}
    </Typography>
  </Box>
  <Link to={to.replace(':id_edificio', id_edificio)} />
</MenuItem>

  );
};

export default Item;
