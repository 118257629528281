import { useState } from 'react';
import axios from 'axios';

const useStep4 = (id_empresa, id_edificio) => {
  const [tipoCuentaSeleccionado, setTipoCuentaSeleccionado] = useState('');
  const [nombreBanco, setNombreBanco] = useState('');
  const [numeroCuenta, setNumeroCuenta] = useState('');
  const [numeroCuentaCci, setNumeroCuentaCci] = useState('');

  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token de autorización no encontrado.');
    }
    return { Authorization: `Bearer ${token}` };
  };

  const saveCuentaBancaria = async () => {
    if (!tipoCuentaSeleccionado || !nombreBanco || !numeroCuenta || !numeroCuentaCci) {
      alert('Por favor, completa todos los campos de la cuenta bancaria.');
      return false;
    }

    if (!id_empresa || !id_edificio) {
      alert('Error: No se ha creado el edificio o empresa. Completa los pasos anteriores.');
      return false;
    }

    const cuentaBancaria = {
      id_empresa,
      id_edificio,
      id_tipo_cuenta: tipoCuentaSeleccionado,
      nombre_banco: nombreBanco,
      numero_cuenta: numeroCuenta,
      numero_cuenta_cci: numeroCuentaCci,
    };

    try {
      const response = await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/cuentas_bancarias/insertar`,
        cuentaBancaria,
        { headers: getAuthHeaders() }
      );

      if (response.status === 201) {
        alert('Cuenta bancaria guardada exitosamente.');
        return true;
      } else {
        console.error('Error en la respuesta del servidor:', response);
        return false;
      }
    } catch (error) {
      console.error('Error al guardar la cuenta bancaria:', error);
      if (error.response && error.response.status === 403) {
        alert('No tienes permiso para realizar esta acción. Inicia sesión de nuevo.');
      }
      return false;
    }
  };

  return {
    tipoCuentaSeleccionado,
    setTipoCuentaSeleccionado,
    nombreBanco,
    setNombreBanco,
    numeroCuenta,
    setNumeroCuenta,
    numeroCuentaCci,
    setNumeroCuentaCci,
    saveCuentaBancaria,
  };
};

export default useStep4;
