import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Box, Button, Dialog, Typography, TextField, useTheme, Snackbar, Alert } from "@mui/material";
import Handsontable from "handsontable";
import "handsontable/dist/handsontable.full.css";
import { tokens } from "../../theme";

const EditarLecturasAgua = ({ id_empresa, id_edificio, onClose, onSave }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const hotTableRef = useRef(null);
  const [fechaLectura, setFechaLectura] = useState('');
  const [departamentos, setDepartamentos] = useState([]);
  const [lecturas, setLecturas] = useState([]);
  const [hotInstance, setHotInstance] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (id_empresa && id_edificio) {
      fetchDepartamentosYLecturas();
    }
  }, [id_empresa, id_edificio]);

  useEffect(() => {
    if (departamentos.length && hotTableRef.current) {
      const data = departamentos.map(dep => {
        const lectura = lecturas.find(l => l.id_departamento === dep.id_departamento);
        return [dep.numero_departamento, lectura ? lectura.lectura_agua : ''];
      });
      const hot = new Handsontable(hotTableRef.current, {
        data,
        rowHeaders: true,
        colHeaders: ['Departamento', 'Lectura de Agua'],
        columns: [{ readOnly: true }, {}],
        width: '100%',
        height: 400,
        licenseKey: 'non-commercial-and-evaluation'
      });
      setHotInstance(hot);
    }
  }, [departamentos, lecturas]);

  const fetchDepartamentosYLecturas = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setErrorMessage('Token de autorización no encontrado. Por favor, inicia sesión.');
      return;
    }

    try {
      const [departamentosResponse, lecturasResponse] = await Promise.all([
        axios.get(
          `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos`,
          { headers: { Authorization: `Bearer ${token}` } }
        ),
        axios.get(
          `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/lecturas_agua`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
      ]);

      setDepartamentos(departamentosResponse.data);
      setLecturas(lecturasResponse.data);
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 403) {
      setErrorMessage('No tienes permiso para acceder a los datos. Verifica tus credenciales.');
    } else if (error.response && error.response.status === 401) {
      setErrorMessage('Sesión expirada. Por favor, vuelve a iniciar sesión.');
      localStorage.removeItem('token'); // Remover token inválido
    } else {
      setErrorMessage('Error al obtener los departamentos y lecturas.');
    }
    console.error('Error al obtener los departamentos y lecturas:', error);
  };

  const handleSave = async () => {
    const dataToSave = hotInstance.getData();
    const token = localStorage.getItem('token');

    if (!token) {
      setErrorMessage('Token de autorización no encontrado. Por favor, inicia sesión.');
      return;
    }

    try {
      for (let row of dataToSave) {
        const numero_departamento = row[0];
        const lectura_agua = row[1];

        if (!lectura_agua) continue;

        const departamento = departamentos.find(dep => dep.numero_departamento === numero_departamento);
        if (departamento) {
          const lecturaExistente = lecturas.find(l => l.id_departamento === departamento.id_departamento);
          const payload = {
            id_empresa,
            id_edificio,
            id_departamento: departamento.id_departamento,
            fecha_lectura: fechaLectura,
            lectura_agua: parseFloat(lectura_agua)
          };

          if (lecturaExistente) {
            await axios.put(
              `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/lecturas_agua/modificar`,
              { ...payload, id: lecturaExistente.id },
              { headers: { Authorization: `Bearer ${token}` } }
            );
          } else {
            await axios.post(
              `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/lecturas_agua/insertar`,
              payload,
              { headers: { Authorization: `Bearer ${token}` } }
            );
          }
        }
      }

      onSave(); // Llamar a la función de éxito del componente padre
      onClose(); // Cerrar el diálogo después de guardar
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <Box component="form" sx={{ width: '100%', maxWidth: 500, mx: 'auto', textAlign: 'center', p: 3 }}>
        <Typography variant="h6" mb={2} sx={{ fontSize: '1.5rem', color: colors.blueAccent[700] }}>
          Editar Lecturas de Agua
        </Typography>
        <TextField
          label="Fecha de Registro"
          variant="outlined"
          type="date"
          value={fechaLectura}
          onChange={(e) => setFechaLectura(e.target.value)}
          fullWidth
          required
          sx={{ mb: 2, width: '100%' }}
          InputLabelProps={{ shrink: true }}
        />
        <Box ref={hotTableRef} sx={{ mb: 2 }}></Box>
        <Box mt={2} display="flex" justifyContent="center">
          <Button onClick={handleSave} variant="contained" color="primary" sx={{ backgroundColor: colors.blueAccent[700] }}>
            Guardar
          </Button>
        </Box>
        {/* Mensaje de error */}
        <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage(null)}>
          <Alert onClose={() => setErrorMessage(null)} severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Dialog>
  );
};

export default EditarLecturasAgua;
