import React, { useState } from "react";
import axios from "axios";
import { useTheme, Typography, Alert, Box, Button, Dialog, Snackbar, Select, MenuItem, InputLabel, IconButton } from '@mui/material';
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import ConsumoAguaForm from "../consumoAguaForm/ConsumoAguaForm";
import RegistroLecturaAgua from "../registroLecturaAgua/registroLecturaAgua";
import EditarLecturasAgua from "./EditarLecturasAgua";
import useFetchAndCalculate from "../Hook/HookCalculoAgua";
import ImagenDialog from "./ImagenDialog"; // Importa ImagenDialog


const parseDateAsUtc = (dateString) => {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(Date.UTC(year, month - 1, day));
};

const LecturaAgua = () => {
  const { id_empresa, id_edificio } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [openRegistroLectura, setOpenRegistroLectura] = useState(false);
  const [openEditarLecturas, setOpenEditarLecturas] = useState(false);
  const [openImagenDialog, setOpenImagenDialog] = useState(false);
  const [selectedLectura, setSelectedLectura] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [error, setError] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const {
    loading,
    error: fetchError,
    lecturas,
    departamentos,
    consumoAguaData,
    consumoTotalM3,
    fetchData
  } = useFetchAndCalculate(selectedMonth, selectedYear, id_empresa, id_edificio);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    fetchData();
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    fetchData();
  };

  const monthOptions = Array.from({ length: 12 }, (_, index) => {
    const month = new Date(2000, index).toLocaleString("default", { month: "long" });
    return { value: index + 1, label: month };
  });

  const currentYear = new Date().getFullYear();
  const startYear = 2000;
  const maxDisplayedYears = 10;
  const yearOptions = Array.from(
    { length: Math.min(maxDisplayedYears, currentYear - startYear + 1) },
    (_, index) => {
      const year = currentYear - index;
      return { value: year, label: year };
    }
  );  

  const filteredLecturas = lecturas.filter(lectura => {
    const date = parseDateAsUtc(lectura.fecha_lectura);
    return date.getUTCMonth() + 1 === selectedMonth && date.getUTCFullYear() === selectedYear;
  });

  const filteredConsumo = consumoAguaData.filter(consumo => {
    const date = parseDateAsUtc(consumo.fecha_lectura);
    return date.getUTCMonth() + 1 === selectedMonth && date.getUTCFullYear() === selectedYear;
  });

  const costoPorM3 = consumoTotalM3 > 0 ? (filteredConsumo[0]?.consumo_agua || 0) / consumoTotalM3 : 0;

  const verificarLecturasCompletas = () => {
    return departamentos.every(dep =>
      filteredLecturas.some(lectura =>
        lectura.id_departamento === dep.id_departamento
      )
    );
  };

  const guardarCostoTotalEnDB = async () => {
    if (!verificarLecturasCompletas()) {
      setError("No se pueden calcular los costos totales porque faltan lecturas de algunos departamentos.");
      return;
    }

    const fechaActual = new Date(); // Fecha actual en tiempo real
    const fechaSeleccionada = fechaActual.toISOString().split('T')[0]; // Usar la fecha actual


    const datosParaGuardar = departamentos.map(dep => {
      const lecturaActual = filteredLecturas.find(l => l.id_departamento === dep.id_departamento);
      const lecturaAnterior = lecturas.find(l =>
        l.id_departamento === dep.id_departamento &&
        new Date(l.fecha_lectura).getMonth() === (selectedMonth - 2 < 0 ? 11 : selectedMonth - 2) &&
        new Date(l.fecha_lectura).getFullYear() === (selectedMonth - 1 < 1 ? selectedYear - 1 : selectedYear)
      );
      const consumoPorM3Dep = lecturaActual ? lecturaActual.lectura_agua - (lecturaAnterior ? lecturaAnterior.lectura_agua : 0) : 0;

      return {
        id_empresa: parseInt(id_empresa),
        id_edificio: parseInt(id_edificio),
        id_departamento: dep.id_departamento,
        fecha_consumo_agua_soles: fechaSeleccionada,
        costo_total: (consumoPorM3Dep * costoPorM3).toFixed(2)
      };
    });

    const token = localStorage.getItem('token');
    if (!token) {
      setError("Token de autorización no encontrado. Por favor, inicia sesión.");
      return;
    }

    try {
      const response = await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/consumos_agua_soles/insertar`,
        datosParaGuardar,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 201) {
        setSuccessMessage("Datos almacenados con éxito");
        fetchData();
      } else {
        setError("Hubo un problema al guardar los datos.");
      }
    } catch (error) {
      setError("Hubo un error al guardar los costos totales en la base de datos.");
    }
  };

  const displayDate = (dateString) => {
    const date = parseDateAsUtc(dateString);
    return `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;
  };

  const handleViewImage = (lectura) => {
    if (!lectura || !lectura.id_empresa || !lectura.id_edificio || !lectura.id_departamento || !lectura.id_lectura_agua) {
      setError("Faltan datos necesarios para cargar la imagen (empresa, edificio, departamento o lectura).");
      return;
    }
    setSelectedLectura(lectura);
    setOpenImagenDialog(true);
  };
  
  

  const handleCloseImagenDialog = () => {
    setOpenImagenDialog(false);
    setSelectedLectura(null);
  };

  return (
    <Box m="20px">
      <Header title="Lecturas de Agua" />

      {(error || fetchError) && (
        <Snackbar open={true} autoHideDuration={6000} onClose={() => setError(null)}>
          <Alert onClose={() => setError(null)} severity="error">
            {error || fetchError}
          </Alert>
        </Snackbar>
      )}

      {successMessage && (
        <Snackbar open={true} autoHideDuration={6000} onClose={() => setSuccessMessage(null)}>
          <Alert onClose={() => setSuccessMessage(null)} severity="success">
            {successMessage}
          </Alert>
        </Snackbar>
      )}

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box display="flex" gap="1rem" alignItems="center">
          <Box display="flex" gap="0.5rem" alignItems="center">
            <InputLabel>MES:</InputLabel>
            <Select value={selectedMonth} onChange={handleMonthChange}>
              {monthOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box display="flex" gap="0.5rem" alignItems="center">
            <InputLabel>AÑO:</InputLabel>
            <Select value={selectedYear} onChange={handleYearChange}>
              {yearOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box border={1} borderColor={colors.grey[300]} borderRadius={2} p={2} mb={2}>
            {filteredConsumo.map(consumo => (
              <Box key={consumo.id_consumo_agua} mt={1}>
                <Typography variant="body1">Fecha de Lectura: {displayDate(consumo.fecha_lectura)}</Typography>
                <Typography variant="body1">Consumo: {consumo.consumo_agua.toFixed(2)} Soles</Typography>
              </Box>
            ))}
            <Box mt={2}>
              <Typography variant="body1">Consumo Total m3: {consumoTotalM3.toFixed(2)}</Typography>
              {consumoTotalM3 > 0 && (
                <Typography variant="body1">Costo por m3: {(filteredConsumo[0]?.consumo_agua / consumoTotalM3).toFixed(2)} Soles/m3</Typography>
              )}
            </Box>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" gap="1rem">
          <Button variant="contained" onClick={() => setOpenDialog(true)} style={{ backgroundColor: colors.blueAccent[700] }}>
            Ingresar Consumo Agua
          </Button>
          <Button variant="contained" onClick={() => setOpenRegistroLectura(true)} style={{ backgroundColor: colors.blueAccent[700] }}>
            Registrar Lectura
          </Button>
          <Button variant="contained" onClick={() => setOpenEditarLecturas(true)} style={{ backgroundColor: colors.blueAccent[700] }}>
            Editar Lecturas
          </Button>
          <Button variant="contained" onClick={guardarCostoTotalEnDB} style={{ backgroundColor: colors.blueAccent[700] }}>
            Calcular y Guardar Costos
          </Button>
        </Box>
      </Box>

      <Box m="10px 0 0 0" height="60vh" sx={{
        "& .MuiDataGrid-root": { border: "none" },
        "& .MuiDataGrid-cell": { borderBottom: "none" },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
          fontSize: "14px",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
          fontSize: "13px",
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        }
      }}>
        <DataGrid
          rows={departamentos.map(dep => {
            const lecturaActual = filteredLecturas.find(l => l.id_departamento === dep.id_departamento);
            const lecturaAnterior = lecturas.find(l =>
              l.id_departamento === dep.id_departamento &&
              new Date(l.fecha_lectura).getMonth() === (selectedMonth - 2 < 0 ? 11 : selectedMonth - 2) &&
              new Date(l.fecha_lectura).getFullYear() === (selectedMonth - 1 < 1 ? selectedYear - 1 : selectedYear)
            );
            let consumoPorM3Dep = 0;
            if (lecturaActual && lecturaAnterior) {
              consumoPorM3Dep = lecturaActual.lectura_agua - lecturaAnterior.lectura_agua;
            }

            return {
              id: dep.id_departamento,
              numero_departamento: dep.numero_departamento,
              fecha_lectura: lecturaActual ? displayDate(lecturaActual.fecha_lectura) : 'N/A',
              lectura_anterior: lecturaAnterior ? lecturaAnterior.lectura_agua : 0,
              lectura_actual: lecturaActual ? lecturaActual.lectura_agua : 0,
              consumo_por_m3: consumoPorM3Dep.toFixed(2),
              costo_total: (consumoPorM3Dep * costoPorM3).toFixed(2),
              hasImage: Math.random() > 0.5 // Simulación
            };
          })}
          columns={[
            { field: "numero_departamento", headerName: "Número Departamento", flex: 1 },
            { field: "lectura_anterior", headerName: "Lectura Anterior", flex: 1 },
            { field: "lectura_actual", headerName: "Lectura Actual", flex: 1 },
            { field: "consumo_por_m3", headerName: "Consumo por m3", flex: 1 },
            { field: "costo_total", headerName: "Costo Total", flex: 1, valueFormatter: (params) => `${params.value} Soles` },
            {
              field: "imagen",
              headerName: "Imagen",
              flex: 1,
              renderCell: (params) => {
                const lectura = lecturas.find(l => l.id_departamento === params.row.id);
                if (!lectura?.id_lectura_agua) {
                  return <Typography color="textSecondary">N/A</Typography>;
                }
                return (
                  <IconButton onClick={() => handleViewImage(lectura)}>
                    <VisibilityIcon />
                  </IconButton>
                );
              },
            }
                       
            
          ]}
        />
      </Box>

      <Dialog open={openRegistroLectura} onClose={() => setOpenRegistroLectura(false)} maxWidth="sm" fullWidth>
        <RegistroLecturaAgua
          id_empresa={id_empresa}
          id_edificio={id_edificio}
          isOpen={openRegistroLectura}
          onClose={() => setOpenRegistroLectura(false)}
          onAddSuccess={() => {
            fetchData();
            setSuccessMessage("Lecturas de agua registradas con éxito.");
          }}
        />
      </Dialog>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
        <ConsumoAguaForm
          id_empresa={id_empresa}
          id_edificio={id_edificio}
          onSubmit={() => {
            fetchData();
            guardarCostoTotalEnDB();
            setOpenDialog(false);
          }}
        />
      </Dialog>

      <Dialog open={openEditarLecturas} onClose={() => setOpenEditarLecturas(false)} maxWidth="sm" fullWidth>
        <EditarLecturasAgua
          id_empresa={id_empresa}
          id_edificio={id_edificio}
          onClose={() => setOpenEditarLecturas(false)}
          onSave={() => {
            fetchData();
            setSuccessMessage("Lecturas de agua actualizadas con éxito.");
          }}
        />
      </Dialog>
      {selectedLectura && (
        <ImagenDialog
          open={openImagenDialog}
          onClose={handleCloseImagenDialog}
          lectura={selectedLectura} // Incluye el objeto completo
          id_empresa={id_empresa} // Pasar explícitamente
          id_edificio={id_edificio} // Pasar explícitamente
          onUploadSuccess={(newImageUrl) => {
            setSuccessMessage("Imagen subida con éxito.");
            fetchData();
            handleCloseImagenDialog();
          }}
        />
      )}

    </Box>
  );
};

export default LecturaAgua;
