import { useState, useEffect } from "react";
import { Routes, Route, useParams, useLocation } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Departamentos from "./scenes/departamentos";
import Cocheras from "./scenes/cocheras";
import Depositos from "./scenes/depositos";
import Propietarios from "./scenes/propietarios";
import Asignacion from "./scenes/asignacion";
import Parametros from "./scenes/parametros/porcentajeParticipacion";
import Areas from "./scenes/areas/areas";

import FormularioEdificios from "./scenes/formularioedificios/formularioedificios";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import Carts from "./scenes/edificios/Edificios";
import Home from "./scenes/home/home";
import GastosMensuales from "./scenes/gastos_mensuales/gastosMensuales";
import CuotaMantenimiento from "./scenes/cuotaMantenimiento/cuotaMantenimiento";
import LecturaAgua from "./scenes/LecturaAgua/LecturaAgua";
import DiseñadorReciboMantenimiento from "./scenes/diseñadorReciboMantenimiento/diseñadorReciboMantenimiento";
import ReciboMantenimientoPDF from "./scenes/reciboMantenimientoPDF/reciboMantenimientoPDF";
import EdificioInformacion from "./scenes/Edificio_info/edificio_info";
import PresupuestoAnualProyectado from "./scenes/presupuestoAnualProyectado/PresupuestoAnualProyectado";
import Deudores from "./scenes/Deudas/Deudores";
import InicioSesion from "./scenes/Autenticaciones/inicioSesion";
import RecibosMantenimientoEmitidos from "./scenes/RecibosMantenimientoEmitidos/RecibosMantenimientoEmitidos";
import Ingresos from "./scenes/Ingresos/Ingresos";
import Egresos from "./scenes/Egresos/Egresos";
import Informes from "./scenes/Informes/Informes";
import Cobranzas from "./scenes/Cobranzas/Cobranzas";
import Personal from "./scenes/Personal/Personal";
import CierreDeMes from "./scenes/CierreDeMes/CierreDeMes";
import AdminConsole from "./scenes/adminConsole/AdminConsole";
import PrivateRoute from "./scenes/Autenticaciones/PrivateRoute"; // Importar el componente PrivateRoute

function App() {
  // Definir el modo de tema y el estado del sidebar
  const [theme, colorMode] = useMode();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { id_empresa } = useParams();
  const location = useLocation();

  // Verificar si el usuario tiene un token válido para determinar si mostrar el Sidebar y Topbar
  const token = localStorage.getItem("token");
  const shouldShowSidebarAndTopbar = token && location.pathname !== "/iniciar-sesion";

  // Limpiar currentBuilding del localStorage al cargar el componente App por primera vez
  useEffect(() => {
    localStorage.removeItem("currentBuilding");
    localStorage.removeItem("id_edificio");
    localStorage.removeItem("nombre_edificio");
  }, []); // Ejecuta solo al inicio

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {/* Mostrar Sidebar solo si el usuario está autenticado */}
          {shouldShowSidebarAndTopbar && (
            <Sidebar isSidebarOpen={isSidebarOpen} id_empresa={id_empresa} />
          )}
          <main className="content">
            {/* Mostrar Topbar solo si el usuario está autenticado */}
            {shouldShowSidebarAndTopbar && (
              <Topbar setIsSidebarOpen={setIsSidebarOpen} />
            )}

            {/* Definir las rutas */}
            <Routes>
              <Route path="/" element={<InicioSesion />} />
              <Route path="/iniciar-sesion" element={<InicioSesion />} />
              

              <Route
                path="/admin-console"
                element={
                  <PrivateRoute allowedRoles={["ROLE_ADMIN_MAESTRO"]}>
                    <AdminConsole />
                  </PrivateRoute>
                }
              />

              <Route
                path="/empresa/:id_empresa/:nombre_empresa/home"
                element={
                  <PrivateRoute allowedRoles={["ROLE_SUPER_ADMIN", "ROLE_SUB_ADMIN"]}>
                    <Home />
                  </PrivateRoute>
                }
              />

              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios"
                element={<Carts />}
              />

              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/edificio_informacion"
                element={<EdificioInformacion />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/departamentos"
                element={<Departamentos />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/propietarios"
                element={<Propietarios />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/cocheras"
                element={<Cocheras />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/depositos"
                element={<Depositos />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/asignacion"
                element={<Asignacion />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/areas"
                element={<Areas />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/parametros"
                element={<Parametros />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/presupuesto_anual_proyectado"
                element={<PresupuestoAnualProyectado />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/gastos_mensuales"
                element={<GastosMensuales />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/cuota_mantenimiento"
                element={<CuotaMantenimiento />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/lectura_de_agua"
                element={<LecturaAgua />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/diseñador_recibos_mantenimiento"
                element={<DiseñadorReciboMantenimiento />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/recibopdf"
                element={<ReciboMantenimientoPDF />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/deudores"
                element={<Deudores />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/recibos_mantenimientos_emitidos"
                element={<RecibosMantenimientoEmitidos />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/cobranzas"
                element={<Cobranzas />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/ingresos"
                element={<Ingresos />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/egresos"
                element={<Egresos />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/informes"
                element={<Informes />}
              />
              <Route path="/formulario_edificios" element={<FormularioEdificios />} />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/dashboard"
                element={<Dashboard />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/personal"
                element={<Personal />}
              />
              <Route
                path="/empresa/:id_empresa/:nombre_empresa/edificios/:id_edificio/:nombre_edificio/cierre_de_mes"
                element={<CierreDeMes />}
              />
             
              <Route path="/calendar" element={<Calendar />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;




