import React, { useState, useEffect } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import axios from 'axios';

const EditarDepartamento = ({ open, onClose, departamento, onSuccess }) => {
  const [departamentoData, setDepartamentoData] = useState({
    id_departamento: '',
    numero_departamento: '',
    piso_departamento: '',
    vista_departamento: '',
    tipo_departamento: '',
    tipo_uso: '',
    area: '',
    numero_habitaciones: '',
    numero_baños: '',
    estado: true, // Suponiendo que quieres mantener este campo para edición
  });

  useEffect(() => {
    // Actualiza el estado con los datos del departamento seleccionado para edición
    if (departamento) {
      setDepartamentoData({ ...departamento });
    }
  }, [departamento]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDepartamentoData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      // Envía la petición PUT para actualizar el departamento
      // Asegúrate de que la URL es correcta según tu API
      const response = await axios.put(`https://app.propia.pe/apiRest/departamentos/modificar`, departamentoData);
      console.log('Departamento actualizado:', response.data);
      onSuccess(); // Actualiza la lista de departamentos en el componente padre
      onClose(); // Cierra el diálogo
    } catch (error) {
      console.error('Error al modificar el departamento:', error);
      // Aquí deberías manejar el error, por ejemplo, mostrando un mensaje al usuario
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Editar Departamento</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          name="numero_departamento"
          label="Número de Departamento"
          type="number"
          fullWidth
          variant="outlined"
          value={departamentoData.numero_departamento}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="piso_departamento"
          label="Piso del Departamento"
          type="number"
          fullWidth
          variant="outlined"
          value={departamentoData.piso_departamento}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="vista_departamento"
          label="Vista del Departamento"
          type="text"
          fullWidth
          variant="outlined"
          value={departamentoData.vista_departamento}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="tipo_departamento"
          label="Tipo de Departamento"
          type="text"
          fullWidth
          variant="outlined"
          value={departamentoData.tipo_departamento}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="tipo_uso"
          label="Tipo de Uso"
          type="text"
          fullWidth
          variant="outlined"
          value={departamentoData.tipo_uso}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="area"
          label="Área (m²)"
          type="number"
          fullWidth
          variant="outlined"
          value={departamentoData.area}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="numero_habitaciones"
          label="Número de Habitaciones"
          type="number"
          fullWidth
          variant="outlined"
          value={departamentoData.numero_habitaciones}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="numero_baños"
          label="Número de Baños"
          type="number"
          fullWidth
          variant="outlined"
          value={departamentoData.numero_baños}
          onChange={handleChange}
        />
        {/* Considera incluir un control para el estado si es relevante para la edición */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditarDepartamento;
