import React from 'react';
import { Select, MenuItem, TextField, InputLabel } from '@mui/material';

const EmpresasForm = ({ empresas, formData, handleInputChange }) => (
    <>
        <InputLabel>Empresa</InputLabel>
        <Select
            name="id_empresa"
            value={formData.id_empresa || ''}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
        >
            {empresas.map((empresa) => (
                <MenuItem key={empresa.id_empresa} value={empresa.id_empresa}>
                    {empresa.nombre_empresa}
                </MenuItem>
            ))}
        </Select>
        <TextField
            label="Descripción"
            name="descripcion"
            value={formData.descripcion || ''}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
        />
        <TextField
            label="Fecha de Pago"
            name="fecha_pago"
            type="date"
            value={formData.fecha_pago || ''}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
        />
        <TextField
            label="Monto"
            name="monto"
            type="number"
            value={formData.monto || ''}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
        />
    </>
);

export default EmpresasForm;
