import React from 'react';
import { SubMenu } from 'react-pro-sidebar';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import MoneyOffCsredOutlinedIcon from '@mui/icons-material/MoneyOffCsredOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import WaterDamageOutlinedIcon from '@mui/icons-material/WaterDamageOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import Item from './Item';

const ContabilidadMenu = ({ selected, setSelected, currentBuilding, isSubMenuActive }) => {
  // Obtener id_empresa, nombre_empresa, id_edificio y nombre_edificio desde params o localStorage
  const id_empresa = currentBuilding?.id_empresa || localStorage.getItem('id_empresa');
  const nombre_empresa = currentBuilding?.nombre_empresa || localStorage.getItem('nombre_empresa');
  const id_edificio = currentBuilding?.id_edificio || localStorage.getItem('id_edificio');
  const nombre_edificio = currentBuilding?.nombre_edificio || localStorage.getItem('nombre_edificio') || 'Edificio';

  // Base URL para simplificar las rutas
  const baseUrl = `/empresa/${id_empresa}/${nombre_empresa}/edificios/${id_edificio}/${nombre_edificio}`;

  return (
    <SubMenu
      title="Contabilidad"
      icon={<ReceiptOutlinedIcon />}
      active={isSubMenuActive([
        '/ingresos',
        '/egresos',
        '/informes',
        '/presupuesto_anual_proyectado',
        '/cuota_mantenimiento',
        '/cobranzas',
        '/diseñador_recibos_mantenimiento',
        '/recibos_mantenimientos_emitidos',
        '/lectura_de_agua',
        '/deudores',
        '/areas',
        '/cierre_de_mes',
      ]) ? true : undefined}
    >
      <Item
        title="Ingresos"
        to={`${baseUrl}/ingresos`}
        icon={<MonetizationOnOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
      <Item
        title="Egresos"
        to={`${baseUrl}/egresos`}
        icon={<ShoppingBasketOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
      <Item
        title="Informes"
        to={`${baseUrl}/informes`}
        icon={<ReportOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
      <Item
        title="Pre.Anual Proyectado"
        to={`${baseUrl}/presupuesto_anual_proyectado`}
        icon={<BarChartOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
      <Item
        title="Cuota de Mantenimiento"
        to={`${baseUrl}/cuota_mantenimiento`}
        icon={<MoneyOffCsredOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
      <Item
        title="Cobranzas"
        to={`${baseUrl}/cobranzas`}
        icon={<ReceiptLongOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
      <Item
        title="Recibos de Mantenimiento"
        to={`${baseUrl}/diseñador_recibos_mantenimiento`}
        icon={<ReceiptLongOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
      <Item
        title="Recibos Emitidos"
        to={`${baseUrl}/recibos_mantenimientos_emitidos`}
        icon={<PrintOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
      <Item
        title="Lecturas de Agua"
        to={`${baseUrl}/lectura_de_agua`}
        icon={<WaterDamageOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
      <Item
        title="Cierre de Mes"
        to={`${baseUrl}/cierre_de_mes`}
        icon={<PrintOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
      <Item
        title="Deudores"
        to={`${baseUrl}/deudores`}
        icon={<PeopleOutlineOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
      <Item
        title="Areas"
        to={`${baseUrl}/areas`}
        icon={<LayersOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
    </SubMenu>
  );
};

export default ContabilidadMenu;
