import React from "react";
import { useState } from "react";
import { Box, Dialog, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import axios from "axios";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import RegistroPersonal from "./RegistroPersonal";
import EditPersonal from "./EditarPersonal";
import EliminarPersonal from "./EliminarPersonal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useFetchPersonalData from "../Hook/HookPersonal";

const Personal = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id_empresa, id_edificio } = useParams(); // Obtener los IDs desde la URL
  const [open, setOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedPersonal, setSelectedPersonal] = useState(null);

  const { loading, tiposPersonal, personal, fetchAllPersonalData } = useFetchPersonalData(id_empresa, id_edificio);

  const handleDelete = (personal, event) => {
    event.stopPropagation(); // Evitar la propagación del evento de clic
    setSelectedPersonal(personal);
    setOpenDeleteDialog(true); // Abre el diálogo de confirmación de eliminación
  };

  const confirmDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Token de autorización no encontrado.");
      await axios.delete(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/personal/${selectedPersonal.id_personal}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchAllPersonalData(); // Actualiza la lista después de eliminar
      setOpenDeleteDialog(false); // Cierra el diálogo de confirmación
    } catch (error) {
      console.error("Error al eliminar el personal:", error);
    }
  };

  const handleEdit = (personal, event) => {
    event.stopPropagation(); // Evitar la propagación del evento de clic
    setSelectedPersonal(personal);
    setOpenEditDialog(true);
  };

  const columns = [
    { field: "nombre_personal", headerName: "Nombre", flex: 1 },
    {
      field: "nombre_tipo_personal",
      headerName: "Tipo",
      flex: 1,
      valueGetter: (params) =>
        tiposPersonal.find((tipo) => tipo.id_tipo_personal === params.row.id_tipo_personal)?.nombre_tipo_personal || "",
    },
    {
      field: "fecha_contratacion",
      headerName: "Fecha de Contratación",
      flex: 1,
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
    },
    {
      field: "estado",
      headerName: "Estado",
      flex: 0.5,
      valueFormatter: (params) => (params.value ? "Activo" : "Inactivo"),
    },
    {
      field: "acciones",
      headerName: "Acciones",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            onClick={(event) => handleEdit(params.row, event)}
            sx={{
              minWidth: "30px",
              padding: "6px",
            }}
          >
            <EditIcon />
          </Button>
          <Button
            onClick={(event) => handleDelete(params.row, event)}
            sx={{
              minWidth: "30px",
              padding: "6px",
            }}
          >
            <DeleteIcon />
          </Button>
        </Box>
      ),
      sortable: false,
      align: "center",
    },
  ];

  return (
    <Box m="20px">
      <Header title="Personal" subtitle="Lista de personal de trabajo" />
      <Box sx={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
        <Button
          variant="contained"
          onClick={() => setOpen(true)}
          style={{ backgroundColor: colors.blueAccent[700] }}
        >
          Agregar Personal
        </Button>
      </Box>
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            fontSize: "13px",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={personal}
          columns={columns}
          getRowId={(row) => row.id_personal} // Usar `id_personal` como identificador único
        />
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
          <RegistroPersonal idEdificio={id_edificio} fetchData={fetchAllPersonalData} />
        </Dialog>
        <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} maxWidth="sm" fullWidth>
          {selectedPersonal && (
            <EditPersonal
              personal={selectedPersonal}
              fetchData={fetchAllPersonalData}
              onClose={() => setOpenEditDialog(false)}
            />
          )}
        </Dialog>
        <EliminarPersonal
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          onConfirm={confirmDelete}
        />
      </Box>
    </Box>
  );
};

export default Personal;
