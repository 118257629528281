import React, { useState, useMemo } from "react";
import {
  Box,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  Button,
  useTheme,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Visibility } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { format, parseISO, isValid } from "date-fns";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import TotalIngresos from "../TotalIngresos/TotalIngresos";
import useFetchIngresos from "../Hook/HookIngresos";
import RegistroIngresosDialog from "./RegistroIngresosDialog";
import IngresosImagenDialog from "./IngresosImagenDialog";

const Ingresos = () => {
  const theme = useTheme();
  const { id_empresa, id_edificio } = useParams();
  const colors = tokens(theme.palette.mode);

  const [filters, setFilters] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    type: "all",
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [alert, setAlert] = useState({ open: false, severity: "info", message: "" });
  const [imageDialog, setImageDialog] = useState({ open: false, ingreso: null });

  const {
    loading,
    ingresosRecibosData,
    ingresosOtrosData,
    ingresosCuotaExtraData,
    ingresosMorasData,
    fetchData,
  } = useFetchIngresos(filters.month, filters.year, id_empresa, id_edificio);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const combinedData = useMemo(() => {
    return [
      ...ingresosRecibosData,
      ...ingresosOtrosData,
      ...ingresosCuotaExtraData,
      ...ingresosMorasData,
    ].filter((item) => {
      const parsedDate = parseISO(item.fecha);
      const matchesType = filters.type === "all" || item.tipoIngreso === filters.type;
      return (
        isValid(parsedDate) &&
        format(parsedDate, "MM") === filters.month.toString().padStart(2, "0") &&
        format(parsedDate, "yyyy") === filters.year.toString() &&
        matchesType
      );
    });
  }, [ingresosRecibosData, ingresosOtrosData, ingresosCuotaExtraData, ingresosMorasData, filters]);

  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);

  const handleAddSuccess = () => {
    fetchData();
    setAlert({ open: true, severity: "success", message: "Ingreso registrado exitosamente" });
  };

  const handleAlertClose = () => setAlert({ ...alert, open: false });

  const handleImageDialogOpen = (ingreso) => {
    if (!ingreso.url_comprobante) {
      setAlert({ open: true, severity: "error", message: "No hay comprobante disponible." });
      return;
    }
    setImageDialog({ open: true, ingreso });
  };
  

  const handleImageDialogClose = () => {
    setImageDialog({ open: false, ingreso: null });
  };

  const columns = [
    { field: "numero_departamento", headerName: "Nº Departamento", flex: 1, minWidth: 130 },
    {
      field: "fecha",
      headerName: "Fecha de Pago",
      flex: 1,
      minWidth: 180,
      valueFormatter: (params) => format(parseISO(params.value), "dd/MM/yyyy"),
    },
    { field: "monto", headerName: "Monto", flex: 1, minWidth: 110 },
    { field: "metodo_pago", headerName: "Método de Pago", flex: 1, minWidth: 150 },
    { field: "tipoIngreso", headerName: "Tipo de Ingreso", flex: 1, minWidth: 150 },
    {
        field: "url_comprobante", // Asegúrate de que este campo coincide con los datos retornados
        headerName: "Acciones",
        flex: 0.5,
        minWidth: 100,
        renderCell: (params) => (
          <IconButton
            color="primary"
            onClick={() => handleImageDialogOpen(params.row)} // Pasa el objeto completo del ingreso
          >
            <Visibility />
          </IconButton>
        ),
      }
      
  ];

  const monthOptions = Array.from({ length: 12 }, (_, index) => ({
    value: index + 1,
    label: new Date(2000, index).toLocaleString("default", { month: "long" }),
  }));

  const yearOptions = Array.from({ length: 10 }, (_, index) => ({
    value: new Date().getFullYear() - index,
    label: new Date().getFullYear() - index,
  }));

  const typeOptions = [
    { value: "all", label: "Todos" },
    { value: "Recibo de Mantenimiento", label: "Recibo de Mantenimiento" },
    { value: "Cuota Extraordinaria", label: "Cuota Extraordinaria" },
    { value: "Mora", label: "Mora" },
    { value: "Alquiler", label: "Alquiler" },
  ];

  return (
    <Box m="20px">
      <Header title="Ingresos" />
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
      {/* Filtros y Total Importes */}
      <Box display="flex" gap="1rem" alignItems="center" flex="1">
        {/* Filtro Mes */}
        <Box display="flex" gap="0.5rem" alignItems="center">
          <InputLabel>MES:</InputLabel>
          <Select name="month" value={filters.month} onChange={handleFilterChange} size="small">
            {monthOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Box>

        {/* Filtro Año */}
        <Box display="flex" gap="0.5rem" alignItems="center">
          <InputLabel>AÑO:</InputLabel>
          <Select name="year" value={filters.year} onChange={handleFilterChange} size="small">
            {yearOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Box>

        {/* Filtro Tipo */}
        <Box display="flex" gap="0.5rem" alignItems="center">
          <InputLabel>TIPO:</InputLabel>
          <Select name="type" value={filters.type} onChange={handleFilterChange} size="small">
            {typeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Box>

        {/* Total de Ingresos */}
        <TotalIngresos ingresosData={combinedData} colors={colors} />
      </Box>

      {/* Botón Registrar */}
      <Button
        variant="contained"
        style={{ backgroundColor: colors.blueAccent[700] }}
        onClick={handleDialogOpen}
      >
        Registrar
      </Button>
    </Box>

      
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
          <CircularProgress />
        </Box>
      ) : (
        <Box
          height="60vh"
          sx={{
            "& .MuiDataGrid-root": { border: "none" },
            "& .MuiDataGrid-cell": { borderBottom: "none" },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
              fontSize: "14px",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
              fontSize: "13px",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
          }}
        >
          <DataGrid
            rows={combinedData}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5, 10, 20]}
            getRowId={(row) => row.id}
          />
        </Box>
      )}
      <RegistroIngresosDialog
        open={openDialog}
        onClose={handleDialogClose}
        id_empresa={id_empresa}
        id_edificio={id_edificio}
        onAddSuccess={handleAddSuccess}
        setAlert={setAlert}
      />
      <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity={alert.severity} sx={{ width: "100%" }}>
          {alert.message}
        </Alert>
      </Snackbar>
      <IngresosImagenDialog
        open={imageDialog.open}
        onClose={handleImageDialogClose}
        ingreso={imageDialog.ingreso} // El ingreso con todos los datos, incluida la URL
        id_empresa={id_empresa}
        id_edificio={id_edificio}
        onUploadSuccess={() => {
            fetchData(); // Refresca los datos tras subir la imagen
            setAlert({ open: true, severity: "success", message: "Imagen actualizada exitosamente" });
        }}
        />

    </Box>
  );
};

export default Ingresos;
