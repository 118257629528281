import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontFamily: 'Helvetica',
  },
  headerContainer: {
    marginBottom: 20,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  table: {
    display: 'table',
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: 20,
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: 'black',
    borderBottomStyle: 'solid',
    paddingBottom: 5,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderBottomColor: '#e0e0e0',
    borderBottomStyle: 'solid',
    paddingVertical: 5,
  },
  tableCell: {
    fontSize: 10,
    flex: 1,
    textAlign: 'center',
    padding: 5,
  },
  tableCellHeader: {
    fontSize: 12,
    fontWeight: 'bold',
    flex: 1,
    textAlign: 'center',
    padding: 5,
  },
  imagePage: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  image: {
    width: '80%',
    maxHeight: 400,
    objectFit: 'contain',
    marginBottom: 10,
  },
});

const formatNumber = (number) =>
  new Intl.NumberFormat('es-PE', {
    style: 'currency',
    currency: 'PEN',
    minimumFractionDigits: 2,
  })
    .format(number)
    .replace('PEN', 'S/');

const PedidosPDF = ({ egresosData, selectedMonth, selectedYear }) => {
  const formattedMonth = format(new Date(selectedYear, selectedMonth - 1), 'MMMM yyyy', { locale: es });

  // Filtrar los egresos que tienen imágenes
  const egresosConImagen = egresosData.filter((egreso) => egreso.url);

  return (
    <Document>
      {/* Página de lista de egresos */}
      <Page style={styles.page}>
        <View style={styles.headerContainer}>
          <Text style={styles.title}>Egresos del Mes - {formattedMonth}</Text>
        </View>
        <View style={styles.table}>
          {/* Encabezado de la tabla */}
          <View style={styles.tableHeader}>
            <Text style={styles.tableCellHeader}>Concepto</Text>
            <Text style={styles.tableCellHeader}>Fecha</Text>
            <Text style={styles.tableCellHeader}>Monto</Text>
          </View>
          {/* Filas de la tabla */}
          {egresosData.map((egreso, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={styles.tableCell}>{egreso.concepto}</Text>
              <Text style={styles.tableCell}>
                {egreso.fecha ? format(parseISO(egreso.fecha), 'dd/MM/yyyy') : 'Sin fecha'}
              </Text>
              <Text style={styles.tableCell}>{formatNumber(egreso.monto)}</Text>
            </View>
          ))}
        </View>
      </Page>

      {/* Páginas con imágenes de los egresos */}
      {egresosConImagen.map((egreso, index) => (
        <Page key={index} style={styles.page}>
          <View style={styles.imagePage}>
            <Text style={styles.title}>{egreso.concepto}</Text>
            <Image style={styles.image} src={egreso.url} />
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default PedidosPDF;
