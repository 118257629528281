import React from "react";
import { InputLabel, Select, MenuItem, TextField } from "@mui/material";

const OtrosIngresosForm = ({ formData, handleInputChange, handleFileChange, departamentos, fetchingDepartamentos }) => {
  return (
    <>
      <InputLabel>Departamento</InputLabel>
      <Select
        name="id_departamento"
        fullWidth
        margin="dense"
        onChange={handleInputChange}
        value={formData.id_departamento || ""}
        disabled={fetchingDepartamentos}
      >
        {departamentos.map((depto) => (
          <MenuItem key={depto.id_departamento} value={depto.id_departamento}>
            {depto.numero_departamento}
          </MenuItem>
        ))}
      </Select>
      <TextField
        label="Fecha de Ingreso"
        name="fecha_ingreso"
        type="date"
        fullWidth
        margin="dense"
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Monto"
        name="monto_ingreso"
        type="number"
        fullWidth
        margin="dense"
        onChange={handleInputChange}
      />
      <InputLabel>Método de Pago</InputLabel>
      <Select
        name="metodo_pago_ingreso"
        fullWidth
        margin="dense"
        onChange={handleInputChange}
        value={formData.metodo_pago_ingreso || ""}
      >
        <MenuItem value="Transferencia Bancaria">Transferencia Bancaria</MenuItem>
        <MenuItem value="Transferencia Interbancaria">Transferencia Interbancaria</MenuItem>
        <MenuItem value="Depósito">Depósito</MenuItem>
      </Select>
      <InputLabel>Tipo de Ingreso</InputLabel>
      <Select
        name="tipo_ingreso"
        fullWidth
        margin="dense"
        onChange={handleInputChange}
        value={formData.tipo_ingreso || ""}
      >
        <MenuItem value="Alquiler">Alquiler</MenuItem>
        <MenuItem value="Servicios">Servicios</MenuItem>
        <MenuItem value="Otros">Otros</MenuItem>
      </Select>
      <TextField
        label="Descripción"
        name="descripcion_ingreso"
        fullWidth
        margin="dense"
        onChange={handleInputChange}
      />
      <TextField
        label="Comprobante"
        type="file"
        fullWidth
        margin="dense"
        onChange={(e) => handleFileChange(e.target.files[0])}
      />
    </>
  );
};

export default OtrosIngresosForm;
