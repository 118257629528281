import React from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const EliminarDeuda = ({ deuda, cerrarModal, onDeudaEliminada }) => {
  const confirmarEliminarDeuda = async () => {
    try {
      // Espera a que la petición de eliminación se complete
      await axios.delete(`https://app.propia.pe/apiRest/deudas/${deuda.id_deuda}`);


      // Luego actualiza la lista de deudas y cierra el modal
      if (onDeudaEliminada) {
        onDeudaEliminada();
      }
      cerrarModal();
    } catch (error) {
      console.error('Error al eliminar la deuda:', error);
      // Manejar el error
    }
  };

  return (
    <Dialog open={Boolean(deuda)} onClose={cerrarModal}>
      <DialogTitle>Confirmar Eliminación</DialogTitle>
      <DialogContent>
        ¿Estás seguro de que deseas eliminar esta deuda?
      </DialogContent>
      <DialogActions>
        <Button onClick={cerrarModal}>Cancelar</Button>
        <Button onClick={confirmarEliminarDeuda} color="primary">Eliminar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EliminarDeuda;
