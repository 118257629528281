import React from "react";
import { Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const ReciboMantenimientoPDF = ({ departamento }) => {
  const styles = StyleSheet.create({
    receiptContainer: {
      padding: 20,
    },
    receipt: {
      padding: 20,
      backgroundColor: '#ffffff',
      marginBottom: 20,
      border: '1px solid black',
      borderRadius: 5,
    },
    receiptHeader: {
      padding: 10,
      marginBottom: 20,
      textAlign: 'center',
    },
    titleText: {
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: 8,
      color: 'black',
    },
    detailText: {
      fontSize: 12,
      marginBottom: 4,
      color: 'black',
    },
    section: {
      marginBottom: 15,
    },
    footer: {
      marginTop: 20,
      textAlign: 'center',
      fontSize: 12,
      color: 'black',
    },
    tableSection: {
      marginBottom: 15,
      border: '1px solid black',
      borderRadius: 5,
      padding: 0, // Adjusted
    },
    tableHeader: {
      flexDirection: "row",
      backgroundColor: '#498ff2',
      borderBottom: '1px solid black',
      marginBottom: 0, // Adjusted
    },
    tableRow: {
      flexDirection: "row",
      marginBottom: 0, // Adjusted
    },
    tableCellHeader: {
      fontSize: 11,
      flex: 1,
      fontWeight: 'bold',
      color: '#ffffff',
      borderRight: '1px solid black',
      padding: 5,
      textAlign: 'center',
    },
    tableCell: {
      fontSize: 9,
      flex: 1,
      color: 'black',
      borderRight: '1px solid black',
      padding: 5,
      textAlign: 'center',
    },
    accountData: {
      fontSize: 10,
      marginBottom: 5,
      color: 'black',
      padding: 5,
    },
    gridContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    gridItem: {
      width: '48%',
    },
    totalSection: {
      backgroundColor: '#498ff2',
      padding: 10,
      borderRadius: 5,
      marginBottom: 15,
    },
    totalText: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#ffffff',
      marginBottom: 8,
    },
  });

  return (
    <Document>
      <View style={styles.receiptContainer}>
        <View style={styles.receipt}>
          <View style={styles.receiptHeader}>
            <Text style={styles.titleText}>Recibo de Mantenimiento</Text>
          </View>

          <View style={styles.gridContainer}>

            <View style={styles.gridItem}>
              <View style={styles.section}>
                <Text style={styles.titleText}>Propietario:</Text>
                <Text style={styles.detailText}>Nombre: {departamento.nombre_propietario}</Text>
                <Text style={styles.detailText}>Apellido: {departamento.apellido_propietario}</Text>
                <Text style={styles.detailText}>Departamento: {departamento.numero_departamento}</Text>
                <Text style={styles.detailText}>Porcentaje de Participación: {departamento.porcentaje_participacion} %</Text>
              </View>
            </View>

            <View style={styles.gridItem}>
              <View style={styles.totalSection}>
                <Text style={styles.totalText}>Total a pagar: S/. {departamento.total_recibo_mantenimiento}</Text>
              </View>

              <View style={styles.section}>
                <Text style={styles.titleText}>Detalles de Pago:</Text>
                <Text style={styles.detailText}>Cuota Mantenimiento: S/. {departamento.monto_cuota}</Text>
                <Text style={styles.detailText}>Monto Agua: S/. {departamento.costo_total}</Text>
                <Text style={styles.detailText}>Deuda Pendiente: S/. {departamento.saldo_pendiente}</Text>
              </View>
            </View>

          </View>

          <Text style={styles.titleText}>Consumo de Agua:</Text>
          <View style={styles.tableSection}>
            <View style={styles.tableHeader}>
              <Text style={styles.tableCellHeader}>Lectura Anterior</Text>
              <Text style={styles.tableCellHeader}>Lectura Actual</Text>
              <Text style={styles.tableCellHeader}>Consumo (m3)</Text>
              <Text style={{...styles.tableCellHeader, borderRight: 'none'}}>Costo Total (Soles)</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCell}>{departamento.lectura_anterior}</Text>
              <Text style={styles.tableCell}>{departamento.lectura_actual}</Text>
              <Text style={styles.tableCell}>{departamento.consumo_m3}</Text>
              <Text style={{...styles.tableCell, borderRight: 'none'}}>{departamento.costo_total}</Text>
            </View>
          </View>

          <Text style={styles.titleText}>Cuenta a Depositar</Text>
          <View style={styles.accountData}>
            <Text>Nombre: {departamento.nombre_junta_propietario}</Text>
            <Text>RUC: {departamento.ruc}</Text>
            <Text>Banco: {departamento.nombre_banco}</Text>
            <Text>Número de Cuenta: {departamento.numero_cuenta}</Text>
            <Text>Número de Cuenta CCI: {departamento.numero_cuenta_cci}</Text>
          </View>

          <View style={styles.footer}>
            <Text>Enviar su comprobante de pago a cobranzas@cariqlo.com</Text>
          </View>
        </View>
      </View>
    </Document>
  );
};

export default ReciboMantenimientoPDF;
