import React from 'react';
import { SubMenu } from 'react-pro-sidebar';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Item from './Item';

const ComunidadMenu = ({ selected, setSelected, currentBuilding, isSubMenuActive }) => {
  // Obtener id_empresa, nombre_empresa, id_edificio y nombre_edificio desde params o localStorage
  const id_empresa = currentBuilding?.id_empresa || localStorage.getItem('id_empresa');
  const nombre_empresa = currentBuilding?.nombre_empresa || localStorage.getItem('nombre_empresa');
  const id_edificio = currentBuilding?.id_edificio || localStorage.getItem('id_edificio');
  const nombre_edificio = currentBuilding?.nombre_edificio || localStorage.getItem('nombre_edificio') || 'Edificio';

  // Base URL para simplificar las rutas
  const baseUrl = `/empresa/${id_empresa}/${nombre_empresa}/edificios/${id_edificio}/${nombre_edificio}`;

  return (
    <SubMenu
      title="Comunidad"
      icon={<GroupOutlinedIcon />}
      active={isSubMenuActive([
        '/asignacion',
        '/edificio_informacion',
        '/propietarios',
        '/departamentos',
        '/cocheras',
        '/depositos',
        '/parametros',
      ]) ? true : undefined}
    >
      <Item
        title="Asignaciones"
        to={`${baseUrl}/asignacion`}
        icon={<AssignmentOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
      <Item
        title="Info. Edificio"
        to={`${baseUrl}/edificio_informacion`}
        icon={<InfoOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
      <Item
        title="Propietarios"
        to={`${baseUrl}/propietarios`}
        icon={<PeopleAltOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
      <Item
        title="Departamentos"
        to={`${baseUrl}/departamentos`}
        icon={<BusinessOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
      <Item
        title="Cocheras"
        to={`${baseUrl}/cocheras`}
        icon={<DirectionsCarOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
      <Item
        title="Depositos"
        to={`${baseUrl}/depositos`}
        icon={<StorageOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
      <Item
        title="Parametros"
        to={`${baseUrl}/parametros`}
        icon={<SettingsOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
        nombreEdificio={nombre_edificio}
      />
    </SubMenu>
  );
};

export default ComunidadMenu;
