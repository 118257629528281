import { useState, useEffect } from "react";
import axios from "axios";

const useFetchCobranzas = (selectedMonth, selectedYear, id_empresa, id_edificio, filtroDepartamento) => {
  const [loading, setLoading] = useState(true);
  const [cobranzasData, setCobranzasData] = useState([]);

  const fetchCobranzas = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token de autorización no encontrado.");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const [responsePagos, responseRecibos, responseDepartamentos, responseMoras] = await Promise.all([
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/pago_recibo_mantenimiento`, { headers }),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/recibos_emitidos`, { headers }),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos`, { headers }),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/moras`, { headers }),
      ]);

      const pagos = responsePagos.data;
      const recibos = responseRecibos.data;
      const departamentos = responseDepartamentos.data;
      const moras = responseMoras.data;

      const cobranzas = pagos.map((pago) => {
        const recibo = recibos.find((r) => r.id_recibo === pago.id_recibo);
        const departamento = departamentos.find((d) => d.id_departamento === recibo?.id_departamento);
        const mora = moras.find((m) => m.id_recibo === recibo?.id_recibo);

        return {
          ...pago,
          id: `${pago.id_pago_recibo_mantenimiento}-${Math.random().toString(36).substr(2, 9)}`,
          numero_departamento: departamento ? departamento.numero_departamento : "Desconocido",
          mora: mora ? mora.monto_mora : 0,
          url_comprobante_pago_recibo: pago.url_comprobante_pago_recibo || null,
        };
      });

      // Filtrar según mes, año y departamento
      let filtrado = cobranzas;
      if (selectedMonth) {
        filtrado = filtrado.filter((pago) => new Date(pago.fecha_pago).getMonth() + 1 === parseInt(selectedMonth));
      }
      if (selectedYear) {
        filtrado = filtrado.filter((pago) => new Date(pago.fecha_pago).getFullYear() === parseInt(selectedYear));
      }
      if (filtroDepartamento) {
        filtrado = filtrado.filter((pago) => pago.numero_departamento.includes(filtroDepartamento));
      }

      setCobranzasData(filtrado);
    } catch (error) {
      console.error("Error al obtener las cobranzas:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCobranzas();
  }, [selectedMonth, selectedYear, id_empresa, id_edificio, filtroDepartamento]);

  return { loading, cobranzasData, fetchCobranzas };
};

export default useFetchCobranzas;
