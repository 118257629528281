import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  Select,
  MenuItem,
  InputLabel,
  useTheme,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { tokens } from "../../theme";
import useCuotasExtraordinarias from "../Hook/ingresos/useCuotasExtraordinarias";
import useIngresos from "../Hook/ingresos/useIngresos";
import CuotaExtraordinariaForm from "./CuotaExtraordinariaForm";
import OtrosIngresosForm from "./OtrosIngresosForm";

const RegistroIngresosDialog = ({ open, onClose, id_empresa, id_edificio, onAddSuccess, setAlert }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [registroTipo, setRegistroTipo] = useState("");
  const [formData, setFormData] = useState({});
  const [departamentos, setDepartamentos] = useState([]);
  const [comprobante, setComprobante] = useState(null);
  const [fetchingDepartamentos, setFetchingDepartamentos] = useState(false);

  const { saveCuotaExtraordinaria, uploading: uploadingCuota } = useCuotasExtraordinarias(id_empresa, id_edificio);
  const { saveIngreso, uploading: uploadingIngreso } = useIngresos(id_empresa, id_edificio);

  useEffect(() => {
    if (open && registroTipo) {
      fetchDepartamentos();
    }
  }, [open, registroTipo, id_empresa, id_edificio]);

  const fetchDepartamentos = async () => {
    setFetchingDepartamentos(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Token de autorización no encontrado.");
      const response = await axios.get(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setDepartamentos(response.data);
    } catch (error) {
      console.error("Error al obtener los departamentos:", error);
      setAlert({ open: true, severity: "error", message: "Error al obtener los departamentos" });
    } finally {
      setFetchingDepartamentos(false);
    }
  };

  const handleRegistroTipoChange = (event) => {
    setRegistroTipo(event.target.value);
    setFormData({});
    setComprobante(null);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (file) => {
    setComprobante(file);
  };

  const handleSubmit = async () => {
    try {
      let result;
      if (registroTipo === "Cuota Extraordinaria") {
        result = await saveCuotaExtraordinaria(formData, comprobante);
      } else if (registroTipo === "Otros Ingresos") {
        result = await saveIngreso(formData, comprobante);
      }

      if (result) {
        onAddSuccess();
        onClose();
        setAlert({ open: true, severity: "success", message: "Ingreso registrado exitosamente." });
      }
    } catch (error) {
      console.error("Error al registrar el ingreso:", error);
      setAlert({ open: true, severity: "error", message: "Error al registrar el ingreso." });
    }
  };

  const renderFormFields = () => {
    switch (registroTipo) {
      case "Cuota Extraordinaria":
        return (
          <CuotaExtraordinariaForm
            formData={formData}
            handleInputChange={handleInputChange}
            handleFileChange={handleFileChange}
            departamentos={departamentos}
            fetchingDepartamentos={fetchingDepartamentos}
          />
        );
      case "Otros Ingresos":
        return (
          <OtrosIngresosForm
            formData={formData}
            handleInputChange={handleInputChange}
            handleFileChange={handleFileChange}
            departamentos={departamentos}
            fetchingDepartamentos={fetchingDepartamentos}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal open={open} onClose={() => onClose()}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <h2>Registrar Ingreso</h2>
        <InputLabel>Tipo de Registro</InputLabel>
        <Select value={registroTipo} onChange={handleRegistroTipoChange} fullWidth margin="dense">
          <MenuItem value="Cuota Extraordinaria">Cuota Extraordinaria</MenuItem>
          <MenuItem value="Otros Ingresos">Otros Ingresos</MenuItem>
        </Select>
        {renderFormFields()}
        <Box mt={2}>
          {uploadingCuota || uploadingIngreso ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              onClick={handleSubmit}
              style={{ backgroundColor: colors.blueAccent[700], color: colors.primary.contrastText }}
              fullWidth
            >
              Guardar
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default RegistroIngresosDialog;
