import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Visibility } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import RegistroCobranzaDialog from "./RegistroCobranzaDialog";
import CobranzasImagenDialog from "./CobranzasImagenDialog"; // Importar el nuevo componente
import useFetchCobranzas from "../Hook/HookCobranzas";

const Cobranzas = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id_empresa, id_edificio } = useParams();
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [filtroDepartamento, setFiltroDepartamento] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [selectedCobranza, setSelectedCobranza] = useState(null);

  const { loading, cobranzasData, fetchCobranzas } = useFetchCobranzas(
    selectedMonth,
    selectedYear,
    id_empresa,
    id_edificio,
    filtroDepartamento
  );

  const handleViewImage = (row) => {
    setSelectedCobranza(row);
    setImageDialogOpen(true);
  };

  const handleImageDialogClose = () => {
    setImageDialogOpen(false);
    setSelectedCobranza(null);
  };

  const columns = [
    { field: "numero_departamento", headerName: "Número Departamento", flex: 1, minWidth: 150 },
    {
      field: "fecha_pago",
      headerName: "Fecha de Pago",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return !isNaN(date) ? date.toLocaleDateString() : "Desconocida";
      },
    },
    { field: "monto_pagos", headerName: "Monto Pagado", flex: 1, minWidth: 150, valueFormatter: (params) => Number(params.value).toFixed(2) },
    { field: "mora", headerName: "Mora", flex: 1, minWidth: 150, valueFormatter: (params) => Number(params.value).toFixed(2) },
    {
      field: "acciones",
      headerName: "Acciones",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" gap="10px">
          <IconButton onClick={() => handleViewImage(params.row)}>
            <Visibility style={{ color: colors.greenAccent[500] }} />
          </IconButton>
        </Box>
      ),
    },
  ];

  const clearFilters = () => {
    setSelectedMonth(new Date().getMonth() + 1);
    setSelectedYear(new Date().getFullYear());
    setFiltroDepartamento("");
  };

  return (
    <Box m="20px">
      <Header title="Cobranzas" subtitle="Registro de pagos de recibos de mantenimiento" />
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box display="flex" gap="1rem" alignItems="center">
          <FormControl>
            <InputLabel id="mes-label">Mes</InputLabel>
            <Select
              labelId="mes-label"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              label="Mes"
              sx={{ width: 120 }}
            >
              {Array.from({ length: 12 }, (_, index) => (new Date(2000, index).toLocaleString("default", { month: "long" })))
                .map((month, index) => (
                  <MenuItem key={index + 1} value={index + 1}>
                    {month}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="ano-label">Año</InputLabel>
            <Select
              labelId="ano-label"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              label="Año"
              sx={{ width: 120 }}
            >
              {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Número Departamento"
            value={filtroDepartamento}
            onChange={(e) => setFiltroDepartamento(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ width: 150 }}
          />
          <Button variant="contained" color="secondary" onClick={clearFilters}>
            Limpiar Filtro
          </Button>
        </Box>
        <Button
          variant="contained"
          onClick={() => setOpenDialog(true)}
          style={{ backgroundColor: colors.blueAccent[700] }}
        >
          Registrar Pago
        </Button>
      </Box>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            height: "64vh",
            width: "100%",
            "& .MuiDataGrid-root": { border: "none" },
            "& .MuiDataGrid-cell": { borderBottom: "1px solid rgba(224, 224, 224, 1)" },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              color: "#fff",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            },
            "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
            "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
          }}
        >
          <DataGrid rows={cobranzasData} columns={columns} getRowId={(row) => row.id} />
        </Box>
      )}

      <RegistroCobranzaDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onPagoRegistrado={fetchCobranzas}
        id_empresa={id_empresa}
        id_edificio={id_edificio}
      />

      <CobranzasImagenDialog
        open={imageDialogOpen}
        onClose={handleImageDialogClose}
        cobranza={selectedCobranza}
        onUploadSuccess={(url) => {
          console.log("Imagen subida con éxito:", url);
          fetchCobranzas();
        }}
      />
    </Box>
  );
};

export default Cobranzas;
