import React, { useState, useEffect } from "react";
import { Box, Button, Dialog, CircularProgress, Snackbar, Alert, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axios from "axios";
import { useParams } from "react-router-dom";
import RegistroDepartamento from "../registroDepartamento/registroDepartamento";
import RegistroMasivo from "../registroDepartamento/registroDepartamentosMasivos";
import EditarDepartamento from "./EditarDepartamento";

const Departamentos = () => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const colors = tokens(mode);
  const { id_empresa, id_edificio } = useParams(); // Obtener id_empresa e id_edificio desde la URL
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [openCargaMasiva, setOpenCargaMasiva] = useState(false);
  const [openEditar, setOpenEditar] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [departamentoParaEditar, setDepartamentoParaEditar] = useState(null);

  useEffect(() => {
    fetchData();
  }, [id_empresa, id_edificio]); // Dependencias actualizadas

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token'); // Obtener el token del localStorage

      // Llamada al API con axios y headers adecuados
      const response = await axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos`, {
        headers: { Authorization: `Bearer ${token}` }, // Incluir token en el header
      });

      const departamentosData = response.data.map(departamento => ({
        ...departamento,
        id: departamento.id_departamento, // Mapear el ID correctamente para el DataGrid
      }));

      setData(departamentosData);
      setError("");  // Limpiar el error si la data se obtiene correctamente
    } catch (error) {
      setError("Error al obtener los datos");
      console.error("Error al obtener los datos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = () => {
    const departamentoSeleccionado = data.find(departamento => selectedRows.includes(departamento.id));
    if (departamentoSeleccionado) {
      setDepartamentoParaEditar(departamentoSeleccionado);
      setOpenEditar(true);
    }
  };

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem('token'); // Obtener el token
      await axios.post(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos/eliminar`, selectedRows, {
        headers: { Authorization: `Bearer ${token}` }, // Incluir el token
      });
      fetchData(); // Recargar los datos
      setSelectedRows([]);
    } catch (error) {
      console.error('Error al eliminar los datos:', error);
    }
  };

  const handleCloseEditar = () => {
    setOpenEditar(false);
    setDepartamentoParaEditar(null);
  };

  const handleSuccessEdit = () => {
    fetchData();
    handleCloseEditar();
  };

  const columns = [
    { field: "numero_departamento", headerName: "Número", flex: 1 },
    { field: "piso_departamento", headerName: "Piso", flex: 1 },
    { field: "vista_departamento", headerName: "Vista", flex: 1 },
    { field: "tipo_departamento", headerName: "Tipo", flex: 1 },
    { field: "area", headerName: "Área (m2)", flex: 1 },
    { field: "numero_habitaciones", headerName: "Dormitorios", flex: 1 },
    { field: "numero_baños", headerName: "Baños", flex: 1 },
  ];

  return (
    <Box m="20px">
      <Header title="Departamentos" subtitle="Lista de departamentos en el edificio" />
      <Box sx={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
        {selectedRows.length > 0 && (
          <>
            <Button variant="contained" onClick={handleEdit} style={{ backgroundColor: colors.blueAccent[700], marginRight: "10px" }} disabled={selectedRows.length !== 1 || loading}>
              Editar
            </Button>
            <Button variant="contained" onClick={handleDelete} style={{ backgroundColor: colors.blueAccent[700], marginRight: "10px" }} disabled={loading}>
              Eliminar
            </Button>
          </>
        )}
        <Button variant="contained" onClick={() => setOpen(true)} style={{ backgroundColor: colors.blueAccent[700] }} disabled={loading}>
          Agregar Departamento
        </Button>
        <Button variant="contained" onClick={() => setOpenCargaMasiva(true)} style={{ marginLeft: "10px", backgroundColor: colors.blueAccent[700] }} disabled={loading}>
          Carga Masiva de Departamentos
        </Button>
      </Box>
      <Box
        m="25px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            color: colors.primary.main,
            borderBottom: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
            fontSize: "13px",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid checkboxSelection rows={data} columns={columns} onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)} />
        )}
      </Box>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <RegistroDepartamento idEdificio={id_edificio} onAddSuccess={fetchData} />
      </Dialog>

      <Dialog open={openCargaMasiva} onClose={() => setOpenCargaMasiva(false)} maxWidth="md" fullWidth>
        <RegistroMasivo idEdificio={id_edificio} onAddSuccess={fetchData} />
      </Dialog>

      <EditarDepartamento open={openEditar} onClose={handleCloseEditar} departamento={departamentoParaEditar} onSuccess={handleSuccessEdit} />

      <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={() => setError("")}>
        <Alert onClose={() => setError("")} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Departamentos;
