import { useState, useEffect } from "react";
import axios from "axios";

const useFetchIngresos = (selectedMonth, selectedYear, id_empresa, id_edificio) => {
  const [loading, setLoading] = useState(true);
  const [ingresosRecibosData, setIngresosRecibosData] = useState([]);
  const [ingresosOtrosData, setIngresosOtrosData] = useState([]);
  const [ingresosCuotaExtraData, setIngresosCuotaExtraData] = useState([]);
  const [ingresosMorasData, setIngresosMorasData] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token de autorización no encontrado.");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const endpoints = [
        axios.get(
          `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos`,
          { headers }
        ),
        axios.get(
          `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/pago_recibo_mantenimiento`,
          { headers }
        ),
        axios.get(
          `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/ingresos`,
          { headers }
        ),
        axios.get(
          `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/cuotas_extraordinarias`,
          { headers }
        ),
        axios.get(
          `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/moras`,
          { headers }
        ),
      ];

      const responses = await Promise.allSettled(endpoints);

      const safeData = (response, defaultValue = []) =>
        response.status === "fulfilled" ? response.value.data || defaultValue : defaultValue;

      const departamentosMap = safeData(responses[0]).reduce((map, depto) => {
        map[depto.id_departamento] = depto.numero_departamento;
        return map;
      }, {});

      setIngresosRecibosData(
        safeData(responses[1]).map((item) => ({
          id: `recibo-${item.id_pago_recibo_mantenimiento}`,
          concepto: "Pago de Recibo de Mantenimiento",
          fecha: item.fecha_pago || "Fecha no disponible",
          monto: item.monto_pagos || 0,
          metodo_pago: "Método no especificado",
          numero_departamento: departamentosMap[item.id_departamento] || "Desconocido",
          tipoIngreso: "Recibo de Mantenimiento",
          es_pago_atrasado: item.es_pago_atrasado,
          url_comprobante: item.url_comprobante_pago_recibo || null,
        }))
      );

      setIngresosOtrosData(
        safeData(responses[2]).map((item) => ({
          id: `ingreso-vario-${item.id_ingreso}`,
          concepto: item.descripcion_ingreso || "Sin descripción",
          fecha: item.fecha_ingreso || "Fecha no disponible",
          monto: item.monto_ingreso || 0,
          metodo_pago: item.metodo_pago_ingreso || "Método no especificado",
          numero_departamento: departamentosMap[item.id_departamento] || "Desconocido",
          tipoIngreso: item.tipo_ingreso || "Otro",
          url_comprobante: item.url_boucher_pago_ingresos || null, // Campo actualizado
        }))
      );

      setIngresosCuotaExtraData(
        safeData(responses[3]).map((cuota) => ({
          id: `cuota-extra-${cuota.id_cuota_extraordinaria}`,
          concepto: cuota.descripcion || "Sin descripción",
          fecha: cuota.fecha_cuota || "Fecha no disponible",
          monto: cuota.monto_cuota || 0,
          metodo_pago: cuota.metodo_pago || "Método no especificado",
          numero_departamento: departamentosMap[cuota.id_departamento] || "General",
          tipoIngreso: "Cuota Extraordinaria",
          url_comprobante: cuota.url_boucher_pago_cuota_extra || null, // Campo actualizado
        }))
      );

      setIngresosMorasData(
        safeData(responses[4]).map((item) => ({
          id: `mora-${item.id_mora}`,
          concepto: "Mora",
          fecha: item.fecha_mora || "Fecha no disponible",
          monto: item.monto_mora || 0,
          metodo_pago: "Método no especificado",
          numero_departamento: departamentosMap[item.id_departamento] || "Desconocido",
          tipoIngreso: "Mora",
        }))
      );
    } catch (error) {
      console.error("Error al obtener los datos:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedMonth, selectedYear, id_empresa, id_edificio]);

  return {
    loading,
    ingresosRecibosData,
    ingresosOtrosData,
    ingresosCuotaExtraData,
    ingresosMorasData,
    fetchData,
  };
};

export default useFetchIngresos;
