import React, { useState } from "react";
import { Box, TextField, Button, Snackbar, Alert } from "@mui/material";
import axios from "axios";
import { formatISO, parseISO } from "date-fns";

const ConsumoAguaForm = ({ onSubmit, id_empresa, id_edificio }) => {
  const [fechaLectura, setFechaLectura] = useState("");
  const [fechaEmision, setFechaEmision] = useState("");
  const [fechaVencimiento, setFechaVencimiento] = useState("");
  const [consumoAgua, setConsumoAgua] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleRegistro = async (e) => {
    e.preventDefault();

    // Validar que id_empresa e id_edificio estén definidos
    if (!id_empresa || !id_edificio) {
      setErrorMessage("ID de empresa o de edificio no está definido. Por favor, verifica la URL o la configuración.");
      return;
    }

    try {
      const token = localStorage.getItem("token"); // Obtener el token del localStorage

      // Verificar si hay un token de autorización
      if (!token) {
        setErrorMessage("Token de autorización no encontrado. Por favor, inicia sesión.");
        return;
      }

      // Convertir las fechas a formato ISO en UTC
      const formattedFechaLectura = formatISO(parseISO(fechaLectura), { representation: "date" });
      const formattedFechaEmision = formatISO(parseISO(fechaEmision), { representation: "date" });
      const formattedFechaVencimiento = formatISO(parseISO(fechaVencimiento), { representation: "date" });

      const consumoAguaData = {
        id_empresa: id_empresa,
        id_edificio: id_edificio,
        fecha_lectura: formattedFechaLectura,
        fecha_emision: formattedFechaEmision,
        fecha_vencimiento: formattedFechaVencimiento,
        consumo_agua: parseFloat(consumoAgua),
      };

      // Realizar la solicitud de registro
      await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/consumos_agua/insertar`,
        consumoAguaData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Asegurarse de que el token se envía correctamente
          },
        }
      );

      setSuccessMessage("Consumo de agua registrado exitosamente.");
      resetFields();
      onSubmit(consumoAguaData); // Ejecutar el callback después del registro
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          setErrorMessage("No tienes permiso para realizar esta acción. Por favor, verifica tus credenciales.");
        } else if (error.response.status === 400) {
          setErrorMessage("Error en la solicitud. Verifica los datos enviados.");
        } else {
          setErrorMessage(`Error inesperado: ${error.response.data}`);
        }
      } else {
        setErrorMessage("Hubo un error al registrar el consumo de agua.");
      }
      console.error("Error al registrar el consumo de agua:", error);
    }
  };

  const resetFields = () => {
    setFechaLectura("");
    setFechaEmision("");
    setFechaVencimiento("");
    setConsumoAgua("");
  };

  return (
    <Box m="20px">
      <h2>Registro de Consumo de Agua</h2>
      <form onSubmit={handleRegistro}>
        <TextField
          label="Fecha de Lectura"
          type="date"
          fullWidth
          required
          value={fechaLectura}
          onChange={(e) => setFechaLectura(e.target.value)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Fecha de Emisión"
          type="date"
          fullWidth
          required
          value={fechaEmision}
          onChange={(e) => setFechaEmision(e.target.value)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Fecha de Vencimiento"
          type="date"
          fullWidth
          required
          value={fechaVencimiento}
          onChange={(e) => setFechaVencimiento(e.target.value)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Consumo de Agua (m³)"
          variant="outlined"
          fullWidth
          required
          type="number"
          value={consumoAgua}
          onChange={(e) => setConsumoAgua(e.target.value)}
          margin="normal"
          inputProps={{ step: "0.01", min: "0" }} // Permitir decimales y evitar valores negativos
        />
        <Button type="submit" variant="contained" color="primary">
          Registrar Consumo
        </Button>
      </form>

      {/* Mensaje de éxito */}
      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage(null)}>
        <Alert onClose={() => setSuccessMessage(null)} severity="success" sx={{ width: "100%" }}>
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Mensaje de error */}
      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage(null)}>
        <Alert onClose={() => setErrorMessage(null)} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ConsumoAguaForm;
