import React, { useState, useEffect } from "react";
import { Box, Button, TextField, CircularProgress, useTheme, Autocomplete } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useParams } from "react-router-dom";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import ReciboEmitidoDialog from "./ReciboEmitidoDialog";
import './RecibosMantenimientoEmitidos.css';
import { saveAs } from 'file-saver';
import { pdf, Page, Document } from '@react-pdf/renderer';
import RecibosEmitidosPDF from './RecibosEmitidosPDF';
import useFetchPropietarios from '../Hook/HookPropietarios';
import useInfoEdificios from '../Hook/HookInfoEdificios';
import useFetchAndCalculate from "../Hook/HookCalculoAgua";

const RecibosMantenimientoEmitidos = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id_empresa, id_edificio } = useParams();
  const [recibosData, setRecibosData] = useState([]);
  const [loadingRecibos, setLoadingRecibos] = useState(true);
  const [filtroFechaInicio, setFiltroFechaInicio] = useState('');
  const [filtroFechaFin, setFiltroFechaFin] = useState('');
  const [filtroNumeroDepartamento, setFiltroNumeroDepartamento] = useState(null);
  const [filtroNumeroRecibo, setFiltroNumeroRecibo] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRecibo, setSelectedRecibo] = useState(null);

  // Hooks con id_empresa y id_edificio
  const {
    asignacionData,
    porcentajeData,
    departamentosData,
    propietariosData,
    loading: loadingPropietarios
  } = useFetchPropietarios(id_empresa, id_edificio, filtroFechaInicio, filtroFechaFin);

  const { lecturas, loading: loadingLecturas } = useFetchAndCalculate(
    new Date().getMonth() + 1, // Mes actual
    new Date().getFullYear(),  // Año actual
    id_empresa,
    id_edificio
  );
  

  const {
    edificiosData,
    juntaPropietarioData,
    cuentasBancariasData,
    loading: loadingInfoEdificios
  } = useInfoEdificios(id_empresa, id_edificio);

  useEffect(() => {
    // Validar existencia de id_empresa e id_edificio antes de realizar solicitudes
    if (id_empresa && id_edificio) {
      fetchRecibosEmitidos();
    } else {
      console.error("Faltan parámetros de id_empresa o id_edificio.");
    }
  }, [id_empresa, id_edificio, filtroFechaInicio, filtroFechaFin, filtroNumeroDepartamento, filtroNumeroRecibo, asignacionData, porcentajeData, departamentosData, propietariosData, edificiosData, juntaPropietarioData, cuentasBancariasData]);

  const getAuthHeaders = () => {
    let token = localStorage.getItem('token');
    if (!token) {
      console.error("Token no encontrado. Por favor, inicia sesión.");
      return null;
    }

    // Asegurarse de que el token tenga el prefijo 'Bearer'
    if (!token.startsWith('Bearer ')) {
      token = `Bearer ${token}`;
    }

    return { headers: { Authorization: token } };
  };

  const fetchRecibosEmitidos = async () => {
    setLoadingRecibos(true);
    const headers = getAuthHeaders();
    if (!headers) return;
  
    try {
      // Realiza las solicitudes necesarias
      const [
        recibosResponse,
        morasResponse,
        pagosResponse,
        lecturasResponse,
      ] = await Promise.all([
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/recibos_emitidos`, headers),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/moras`, headers),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/pago_recibo_mantenimiento`, headers),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/lecturas_agua`, headers),
      ]);
  
      // Filtra los recibos según los filtros aplicados
      let recibosFiltrados = recibosResponse.data.filter(recibo => recibo.id_edificio === parseInt(id_edificio));
  
      if (filtroFechaInicio) {
        recibosFiltrados = recibosFiltrados.filter(recibo => new Date(recibo.fecha_emision) >= new Date(filtroFechaInicio));
      }
      if (filtroFechaFin) {
        recibosFiltrados = recibosFiltrados.filter(recibo => new Date(recibo.fecha_emision) <= new Date(filtroFechaFin));
      }
      if (filtroNumeroDepartamento) {
        recibosFiltrados = recibosFiltrados.filter(recibo => recibo.id_departamento === filtroNumeroDepartamento);
      }
      if (filtroNumeroRecibo) {
        recibosFiltrados = recibosFiltrados.filter(recibo => recibo.numero_recibo === filtroNumeroRecibo);
      }
  
      // Mapea la información adicional a cada recibo
      const recibosConInfoAdicional = recibosFiltrados.map(recibo => {
        const departamento = departamentosData.find(depto => depto.id_departamento === recibo.id_departamento) || {};
        const asignacion = asignacionData.find(asig => asig.id_departamento === recibo.id_departamento) || {};
        const propietario = propietariosData.find(prop => prop.id_propietario === asignacion.id_propietario) || {};
        const porcentajeParticipacion = porcentajeData.find(por => por.id_departamento === recibo.id_departamento) || {};
        const mora = morasResponse.data.find(mora => mora.id_recibo === recibo.id_recibo) || {};
        const pago = pagosResponse.data.find(pago => pago.id_recibo === recibo.id_recibo) || {};
        const lectura = lecturasResponse.data.find(l => l.id_departamento === recibo.id_departamento) || {};
  
        return {
          ...recibo,
          numero_departamento: departamento.numero_departamento || "Desconocido",
          nombre_propietario: propietario.nombre_propietario || "Desconocido",
          apellido_propietario: propietario.apellido_propietario || "Desconocido",
          porcentaje_participacion: porcentajeParticipacion.porcentaje_participacion || "0",
          nombre_edificio: edificiosData?.nombreEdificio || "Desconocido",
          direccion_edificio: edificiosData?.direccionEdificio || "Desconocido",
          distrito_edificio: edificiosData?.distritoEdificio || "Desconocido",
          nombre_junta_propietario: juntaPropietarioData?.nombreJuntaPropietario || "Desconocido",
          ruc: juntaPropietarioData?.ruc || "Desconocido",
          nombre_banco: cuentasBancariasData?.nombreBanco || "Desconocido",
          numero_cuenta: cuentasBancariasData?.numeroCuenta || "Desconocido",
          numero_cuenta_cci: cuentasBancariasData?.numeroCuentaCCI || "Desconocido",
          tipoCuenta: cuentasBancariasData?.tipoCuenta || "Desconocido",
          mora: mora.monto_mora || 0,
          monto_pagado: pago.monto_pagos || 0,
          fecha_pago: pago.fecha_pago || null,
          estado: recibo.estado ? "Pagado" : "Pendiente",
          url_imagen_contometro: lectura.url_imagen_contometro || null, // Agregar la imagen del contómetro
        };
      });
  
      setRecibosData(recibosConInfoAdicional);
    } catch (error) {
      console.error("Error al obtener los recibos emitidos:", error);
    } finally {
      setLoadingRecibos(false);
    }
  };
  

  const handleOpenDialog = (recibo, event) => {
    event.stopPropagation();
    setSelectedRecibo(recibo);
    setOpenDialog(true);
  };

  const handleDownloadPDF = async (recibo, event) => {
    event.stopPropagation();
    const doc = (
      <Document>
        <Page size="A5">
          <RecibosEmitidosPDF recibo={recibo} />
        </Page>
      </Document>
    );
    const blob = await pdf(doc).toBlob();
    saveAs(blob, `Recibo_${recibo.numero_departamento}.pdf`);
  };
  

  const columns = [
    { field: "numero_departamento", headerName: "Número Depto", flex: 1, minWidth: 150 },
    { field: "total_recibo_mantenimiento", headerName: "Monto Total", flex: 1, minWidth: 130, valueFormatter: (params) => Number(params.value).toFixed(2) },
    { field: "mora", headerName: "Mora", flex: 1, minWidth: 130, valueFormatter: (params) => Number(params.value).toFixed(2) },
    { field: "monto_pagado", headerName: "Monto Pagado", flex: 1, minWidth: 130, valueFormatter: (params) => Number(params.value).toFixed(2) },
    { field: "fecha_emision", headerName: "Fecha Emisión", flex: 1, minWidth: 150 },
    { field: "fecha_vencimiento", headerName: "Fecha Vencimiento", flex: 1, minWidth: 180 },
    {
      field: "estado",
      headerName: "Estado",
      flex: 1,
      minWidth: 130,
      cellClassName: (params) => params.value === 'Pendiente' ? 'estado-pendiente' : 'estado-pagado',
    },
    {
      field: 'accionVer',
      headerName: 'Acciones',
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            onClick={(event) => handleOpenDialog(params.row, event)}
            sx={{ minWidth: '30px', padding: '6px' }}
          >
            <VisibilityIcon />
          </Button>
          <Button
            onClick={(event) => handleDownloadPDF(params.row, event)}
            sx={{ minWidth: '30px', padding: '6px' }}
          >
            <DownloadIcon />
          </Button>
        </Box>
      ),
      sortable: false,
      width: 100,
      align: 'center',
    },
  ];

  const clearFilters = () => {
    setFiltroFechaInicio('');
    setFiltroFechaFin('');
    setFiltroNumeroDepartamento(null);
    setFiltroNumeroRecibo('');
  };

  return (
    <Box m="20px">
      <Header title="Recibos de Mantenimiento Emitidos" subtitle="Lista de recibos de mantenimiento emitidos" />
      <Box display="flex" gap="1rem" alignItems="center" mb="20px">
        <TextField
          label="Fecha Inicio"
          type="date"
          value={filtroFechaInicio}
          onChange={(e) => setFiltroFechaInicio(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Fecha Fin"
          type="date"
          value={filtroFechaFin}
          onChange={(e) => setFiltroFechaFin(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <Autocomplete
          options={departamentosData}
          getOptionLabel={(option) => option.numero_departamento ? option.numero_departamento.toString() : ""}
          value={departamentosData.find(dep => dep.id_departamento === filtroNumeroDepartamento) || null}
          onChange={(event, newValue) => setFiltroNumeroDepartamento(newValue ? newValue.id_departamento : null)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Número Departamento"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
        <TextField
          label="Número Recibo"
          type="text"
          value={filtroNumeroRecibo}
          onChange={(e) => setFiltroNumeroRecibo(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <Button variant="contained" color="secondary" onClick={clearFilters}>
          Limpiar Filtro
        </Button>
      </Box>
      {(loadingPropietarios || loadingInfoEdificios || loadingRecibos) ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            height: "calc(85vh - 200px)",
            width: "100%",
            "& .MuiDataGrid-root": { border: "none" },
            "& .MuiDataGrid-cell": { borderBottom: "1px solid rgba(224, 224, 224, 1)" },
            "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], color: "#fff", borderBottom: "1px solid rgba(224, 224, 224, 1)" },
            "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
            "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
          }}
        >
          <DataGrid
            rows={recibosData}
            columns={columns}
            getRowId={(row) => row.id_recibo}
          />
          {selectedRecibo && (
            <ReciboEmitidoDialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            recibo={selectedRecibo} 
          />
          )}
        </Box>
      )}
    </Box>
  );
};

export default RecibosMantenimientoEmitidos;
