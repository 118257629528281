import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchPersonalData = (id_empresa, id_edificio) => {
    const [loading, setLoading] = useState(false);
    const [tiposPersonal, setTiposPersonal] = useState([]);
    const [personal, setPersonal] = useState([]);
    const [pagosPersonalBase, setPagosPersonalBase] = useState([]);

    // Fetch Tipos de Personal
    const fetchTiposPersonal = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error("Token de autorización no encontrado.");
            const response = await axios.get('https://app.propia.pe/apiRest/tipo_personal/obtener', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setTiposPersonal(response.data);
        } catch (error) {
            console.error("Error al obtener tipos de personal:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch Personal
    const fetchPersonal = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error("Token de autorización no encontrado.");
            const response = await axios.get(
                `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/personal`,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setPersonal(response.data);
        } catch (error) {
            console.error("Error al obtener personal:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch Pagos Personal Base
    const fetchPagosPersonalBase = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error("Token de autorización no encontrado.");
            const response = await axios.get(
                `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/pagos_personal_base`,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setPagosPersonalBase(response.data);
        } catch (error) {
            console.error("Error al obtener pagos personal base:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch All Data
    const fetchAllPersonalData = async () => {
        await Promise.all([fetchTiposPersonal(), fetchPersonal(), fetchPagosPersonalBase()]);
    };

    // useEffect para cargar datos al montar
    useEffect(() => {
        if (id_empresa && id_edificio) {
            fetchAllPersonalData();
        }
    }, [id_empresa, id_edificio]);

    return {
        loading,
        tiposPersonal,
        personal,
        pagosPersonalBase,
        fetchTiposPersonal,
        fetchPersonal,
        fetchPagosPersonalBase,
        fetchAllPersonalData,
    };
};

export default useFetchPersonalData;
