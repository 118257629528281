import React, { useEffect } from 'react';
import { Box, TextField, MenuItem, Typography } from '@mui/material';
import axios from 'axios';

const RegistroCuentaBancaria = ({
  tipoCuentaSeleccionado,
  setTipoCuentaSeleccionado,
  nombreBanco,
  setNombreBanco,
  numeroCuenta,
  setNumeroCuenta,
  numeroCuentaCci,
  setNumeroCuentaCci,
}) => {
  const [tiposCuenta, setTiposCuenta] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const fetchTiposCuenta = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('Token de autorización no encontrado.');
          alert('No se encontró el token de autorización. Inicia sesión nuevamente.');
          return;
        }
        const response = await axios.get('https://app.propia.pe/apiRest/tipo_cuentas/obtener', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTiposCuenta(response.data || []);
      } catch (error) {
        console.error('Error al obtener los tipos de cuenta:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTiposCuenta();
  }, []);

  return (
    <Box>
      <Typography variant="h6" align="center" gutterBottom>
        Configuración de la Cuenta Bancaria
      </Typography>
      <TextField
        select
        label="Tipo de Cuenta"
        value={tipoCuentaSeleccionado || ''}
        onChange={(e) => setTipoCuentaSeleccionado(e.target.value)}
        fullWidth
        margin="normal"
        error={!tipoCuentaSeleccionado && !loading}
        helperText={!tipoCuentaSeleccionado && !loading ? 'Selecciona un tipo de cuenta' : ''}
      >
        {loading ? (
          <MenuItem disabled>Cargando tipos de cuenta...</MenuItem>
        ) : tiposCuenta.length > 0 ? (
          tiposCuenta.map((tipo) => (
            <MenuItem key={tipo.id_tipo_cuenta} value={tipo.id_tipo_cuenta}>
              {tipo.nombre_tipo_cuenta}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No hay tipos de cuenta disponibles</MenuItem>
        )}
      </TextField>
      <TextField
        label="Nombre del Banco"
        value={nombreBanco || ''}
        onChange={(e) => setNombreBanco(e.target.value)}
        fullWidth
        margin="normal"
        error={!nombreBanco && !loading}
        helperText={!nombreBanco && !loading ? 'Introduce el nombre del banco' : ''}
      />
      <TextField
        label="Número de Cuenta"
        value={numeroCuenta || ''}
        onChange={(e) => setNumeroCuenta(e.target.value)}
        fullWidth
        margin="normal"
        error={!numeroCuenta && !loading}
        helperText={!numeroCuenta && !loading ? 'Introduce el número de cuenta' : ''}
      />
      <TextField
        label="Número de Cuenta CCI"
        value={numeroCuentaCci || ''}
        onChange={(e) => setNumeroCuentaCci(e.target.value)}
        fullWidth
        margin="normal"
        error={!numeroCuentaCci && !loading}
        helperText={!numeroCuentaCci && !loading ? 'Introduce el número de cuenta CCI' : ''}
      />
    </Box>
  );
};

export default RegistroCuentaBancaria;
