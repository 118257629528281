import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { tokens } from '../../theme';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import logo from '../assets/logo.jpg';
import ComunidadMenu from '../Sidebar/ComunidadMenu';
import ContabilidadMenu from '../Sidebar/ContabilidadMenu';
import AdministrativaMenu from '../Sidebar/AdministrativaMenu';

const Sidebar = ({ isSidebarOpen }) => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const colors = tokens(mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState('');
  const location = useLocation();
  const params = useParams();

  // Obtener id_empresa y nombre_empresa desde params o localStorage
  const id_empresa = params.id_empresa || localStorage.getItem('id_empresa');
  const nombre_empresa = params.nombre_empresa || localStorage.getItem('nombre_empresa');
  const id_edificio = params.id_edificio || sessionStorage.getItem('id_edificio');
  const nombre_edificio = params.nombre_edificio || sessionStorage.getItem('nombre_edificio');

  // Estados para edificios
  const [currentBuilding, setCurrentBuilding] = useState({});

  // Efecto para cargar el edificio actual basado en el id_edificio de la URL o el sessionStorage
  useEffect(() => {
    if (id_edificio && nombre_edificio) {
      setCurrentBuilding({ id_edificio, nombre_edificio });
    }
  }, [id_edificio, nombre_edificio, location.pathname]);

  // Función para alternar el estado del sidebar (colapsado/expandido)
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Determina si un submenú está activo basado en la URL actual
  const isSubMenuActive = (paths) => {
    return paths.some((path) => location.pathname.includes(path));
  };

  return (
    <Box
      sx={{
        '& .pro-sidebar-inner': {
          background: colors.primary[400],
        },
        '& .pro-menu-item': {
          color: colors.grey[100],
        },
        '& .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover, & .pro-sidebar .pro-menu .pro-sub-menu > .pro-inner-item:hover': {
          backgroundColor: colors.blueAccent[700],
          color: 'white',
          borderRadius: '10px',
          '& .pro-icon, & .pro-arrow': {
            color: 'white',
          },
        },
        '& .pro-sidebar .pro-menu .pro-menu-item:focus': {
          color: colors.grey[100],
        },
        '& .pro-menu-item.active, & .pro-sub-menu-title.active, & .pro-menu-item.active > .pro-inner-item, & .pro-sub-menu > .pro-inner-item.active': {
          backgroundColor: colors.blueAccent[700],
          color: 'white',
          borderRadius: '10px',
          '& .pro-icon, & .pro-arrow': {
            color: 'white',
          },
        },
        '& .pro-icon-wrapper': {
          backgroundColor: 'transparent',
        },
        '& .MuiTypography-root': {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
        },
        '& .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item': {
          backgroundColor: 'transparent',
        },
        '& .pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem onClick={toggleSidebar}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                marginTop: '1rem',
              }}
            >
              {!isCollapsed && (
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                  <img src={logo} alt="Logo" style={{ width: '100px' }} />
                </Box>
              )}
              {isCollapsed && (
                <IconButton>
                  <MenuOutlinedIcon />
                </IconButton>
              )}
            </Box>
          </MenuItem>

          {/* Muestra el nombre del edificio solo si está disponible en el estado actual */}
          {!isCollapsed && currentBuilding?.nombre_edificio && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center" mt="10px">
                <Typography variant="h4" color={colors.grey[100]}>
                  Edificio: {currentBuilding.nombre_edificio}
                </Typography>
              </Box>
            </Box>
          )}

          <MenuItem
            icon={<HomeOutlinedIcon />}
            active={location.pathname === `/empresa/${id_empresa}/${nombre_empresa}/home` ? true : undefined}
            onClick={() => setSelected('Home')}
            style={{
              color: location.pathname === `/empresa/${id_empresa}/${nombre_empresa}/home` ? 'white' : colors.grey[100],
              backgroundColor: location.pathname === `/empresa/${id_empresa}/${nombre_empresa}/home` ? colors.blueAccent[700] : 'transparent',
            }}
          >
            Home
            <Link to={`/empresa/${id_empresa}/${nombre_empresa}/home`} />
          </MenuItem>

          <MenuItem
            icon={<ApartmentOutlinedIcon />}
            active={location.pathname === `/empresa/${id_empresa}/${nombre_empresa}/edificios` ? true : undefined}
            onClick={() => setSelected('Edificios')}
            style={{
              color: location.pathname === `/empresa/${id_empresa}/${nombre_empresa}/edificios` ? 'white' : colors.grey[100],
              backgroundColor: location.pathname === `/empresa/${id_empresa}/${nombre_empresa}/edificios` ? colors.blueAccent[700] : 'transparent',
            }}
          >
            Edificios
            <Link to={`/empresa/${id_empresa}/${nombre_empresa}/edificios`} />
          </MenuItem>

          <Box sx={{ borderBottom: `1px solid ${colors.grey[300]}`, margin: '10px 20px' }}></Box>

          <MenuItem
            icon={<DashboardOutlinedIcon />}
            active={
              currentBuilding?.id_edificio && location.pathname.includes(`/empresa/${id_empresa}/${nombre_empresa}/edificios/${currentBuilding.id_edificio}`)
                ? true
                : undefined
            }
            onClick={() => setSelected('Dashboard')}
            style={{
              color: currentBuilding?.id_edificio && location.pathname.includes(`/empresa/${id_empresa}/${nombre_empresa}/edificios/${currentBuilding.id_edificio}`)
                ? 'white'
                : colors.grey[100],
              backgroundColor: currentBuilding?.id_edificio && location.pathname.includes(`/empresa/${id_empresa}/${nombre_empresa}/edificios/${currentBuilding.id_edificio}`)
                ? colors.blueAccent[700]
                : 'transparent',
            }}
          >
            Dashboard
            {currentBuilding?.id_edificio && currentBuilding?.nombre_edificio ? (
              <Link
                to={`/empresa/${id_empresa}/${nombre_empresa}/edificios/${currentBuilding.id_edificio}/${encodeURIComponent(
                  currentBuilding.nombre_edificio
                )}/dashboard`}
              />
            ) : (
              <Link to="/error" />
            )}
          </MenuItem>

          <ComunidadMenu
            selected={selected}
            setSelected={setSelected}
            currentBuilding={currentBuilding}
            isSubMenuActive={isSubMenuActive}
          />

          <ContabilidadMenu
            selected={selected}
            setSelected={setSelected}
            currentBuilding={currentBuilding}
            isSubMenuActive={isSubMenuActive}
          />

          <AdministrativaMenu
            selected={selected}
            setSelected={setSelected}
            currentBuilding={currentBuilding}
            isSubMenuActive={isSubMenuActive}
          />
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
