import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Checkbox,
    FormControlLabel,
    Snackbar,
    Alert
} from '@mui/material';
import axios from 'axios';

const EmisionRecibosDialog = ({ open, onClose, recibosParaEmitir, idEmpresa, idEdificio, onEmitirRecibos }) => {
    const [fechaEmision, setFechaEmision] = useState('');
    const [fechaVencimiento, setFechaVencimiento] = useState('');
    const [aplicarMora, setAplicarMora] = useState(false);
    const [valorPorDia, setValorPorDia] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [error, setError] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertOpen(false);
    };

    const handleSubmit = async () => {
        if (!recibosParaEmitir || recibosParaEmitir.length === 0) {
            setMensaje("No hay recibos para emitir.");
            setError(true);
            setAlertOpen(true);
            return;
        }

        const token = localStorage.getItem('token'); // Obtiene el token del almacenamiento local
        if (!token) {
            setMensaje("Token de autorización no encontrado.");
            setError(true);
            setAlertOpen(true);
            return;
        }

        const recibosParaEmitirFormateados = recibosParaEmitir.map(recibo => ({
            id_empresa: idEmpresa,
            id_edificio: idEdificio,
            id_departamento: recibo.id,
            fecha_emision: fechaEmision,
            fecha_vencimiento: fechaVencimiento,
            estado: false, // Inicialmente establecido como "Pendiente"
            lectura_actual: recibo.lectura_actual,
            lectura_anterior: recibo.lectura_anterior,
            monto_cuota: recibo.monto_cuota,
            total_recibo_mantenimiento: recibo.total_recibo_mantenimiento,
            consumo_m3: recibo.consumo_m3,
            costo_total: recibo.costo_total,
            aplicar_mora: aplicarMora,
            valor_por_dia_mora: aplicarMora ? parseFloat(valorPorDia) : 0
        }));

        try {
            const response = await axios.post(
                `https://app.propia.pe/apiRest/empresas/${idEmpresa}/edificios/${idEdificio}/recibos_emitidos`,
                recibosParaEmitirFormateados,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            setMensaje('Recibos emitidos correctamente.');
            setError(false);
            setAlertOpen(true);
            onEmitirRecibos(response.data);
            onClose(); // Cierra el diálogo después de la operación exitosa
        } catch (error) {
            setMensaje(`Error al emitir recibos: ${error.response ? error.response.data : error.message}`);
            setError(true);
            setAlertOpen(true);
        }
    };

    return (
        <Dialog open={open} onClose={() => { onClose(); setMensaje(''); setError(false); }}>
            <DialogTitle>Emisión de Recibos de Mantenimiento</DialogTitle>
            <DialogContent>
                <TextField
                    label="Fecha de Emisión"
                    type="date"
                    value={fechaEmision}
                    onChange={e => setFechaEmision(e.target.value)}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Fecha de Vencimiento"
                    type="date"
                    value={fechaVencimiento}
                    onChange={e => setFechaVencimiento(e.target.value)}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                />
                <FormControlLabel
                    control={<Checkbox checked={aplicarMora} onChange={e => setAplicarMora(e.target.checked)} />}
                    label="Aplicar Mora después de la fecha límite"
                />
                {aplicarMora && (
                    <TextField
                        label="Valor por día de Mora (S/.)"
                        type="number"
                        value={valorPorDia}
                        onChange={e => setValorPorDia(e.target.value)}
                        fullWidth
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { onClose(); setMensaje(''); setError(false); }}>Cancelar</Button>
                <Button onClick={handleSubmit} color="primary" variant="contained">Emitir Recibos</Button>
            </DialogActions>
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={error ? "error" : "success"} variant="filled" sx={{ width: '100%' }}>
                    {mensaje}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default EmisionRecibosDialog;
