import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper, Box, TextField, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { tokens } from "../../theme";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import PaymentIcon from '@mui/icons-material/Payment';
import DeleteIcon from '@mui/icons-material/Delete'; // Ícono de eliminar
import FormularioDeudas from "../FormularioDeudas/FormularioDeudas";
import PagarDeuda from "../PagarDeuda/pagarDeuda";
import EliminarDeuda from "../EliminarDeuda/EliminarDeuda"; // Componente para eliminar deudas

const Deudores = () => {
  const { id_edificio } = useParams();
  const [deudores, setDeudores] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showModal, setShowModal] = useState(false);
  const [showPagoModal, setShowPagoModal] = useState(false);
  const [deudaSeleccionada, setDeudaSeleccionada] = useState(null);
  const [deudaAEliminar, setDeudaAEliminar] = useState(null);
  const [filtroFechaInicio, setFiltroFechaInicio] = useState('');
  const [filtroFechaFin, setFiltroFechaFin] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [responseDeudas, responseDepartamentos] = await Promise.all([
          axios.get("https://app.propia.pe/apiRest/deudas/obtener"),
          axios.get("https://app.propia.pe/apiRest/departamentos/obtener")
        ]);

        setDeudores(responseDeudas.data);
        setDepartamentos(responseDepartamentos.data.filter(departamento => departamento.id_edificio === parseInt(id_edificio)));
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id_edificio]);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const abrirPagoModal = (deuda) => {
    setDeudaSeleccionada(deuda);
    setShowPagoModal(true);
  };

  const cerrarPagoModal = () => {
    setShowPagoModal(false);
    setDeudaSeleccionada(null);
  };

  const toggleDeudaDetails = (idDepartamento) => {
    if (selectedRows.includes(idDepartamento)) {
      setSelectedRows(selectedRows.filter(id => id !== idDepartamento));
    } else {
      setSelectedRows([...selectedRows, idDepartamento]);
    }
  };

  const getNumeroDepartamento = (idDepartamento) => {
    const departamento = departamentos.find(depto => depto.id_departamento === idDepartamento);
    return departamento ? departamento.numero_departamento : 'N/A';
  };

  const calcularEstadoPago = (deuda) => {
    if (deuda.saldo_pendiente <= 0) {
      return "Pagada";
    } else if (deuda.monto_pagado > 0) {
      return "Parcialmente Pagada";
    }
    return "Pendiente";
  };

  const filtrarDeudas = (idDepartamento) => {
    return deudores.filter((deuda) => {
      const fechaCreacion = new Date(deuda.fecha_creacion);
      const inicio = filtroFechaInicio ? new Date(filtroFechaInicio) : new Date('1970-01-01');
      const fin = filtroFechaFin ? new Date(filtroFechaFin) : new Date('2999-12-31');
      return deuda.id_departamento === idDepartamento && fechaCreacion >= inicio && fechaCreacion <= fin;
    });
  };

  const calcularTotal = (deudas, campo) => {
    return deudas.reduce((total, deuda) => total + parseFloat(deuda[campo] || 0), 0);
  };

  const clearFilter = () => {
    setFiltroFechaInicio('');
    setFiltroFechaFin('');
  };

  const actualizarDeudas = async () => {
    try {
      const response = await axios.get("https://app.propia.pe/apiRest/deudas/obtener");
      setDeudores(response.data);
    } catch (error) {
      console.error("Error al actualizar las deudas:", error);
    }
  };

  if (loading) {
    return <p>Cargando deudas...</p>;
  }

  return (
    <Container style={{ marginTop: theme.spacing(1) }}>
      <Typography variant="h1" gutterBottom>Deudores</Typography>
  
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="20px">
        <Box display="flex" gap="1rem" alignItems="center">
          <TextField
            label="Fecha Inicio"
            type="date"
            value={filtroFechaInicio}
            onChange={(e) => setFiltroFechaInicio(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Fecha Fin"
            type="date"
            value={filtroFechaFin}
            onChange={(e) => setFiltroFechaFin(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <Button variant="contained" color="secondary" onClick={clearFilter}>
            Limpiar Filtro
          </Button>
        </Box>
        <Button variant="contained" color="primary" onClick={handleOpenModal} style={{ backgroundColor: colors.blueAccent[700] }}>
          Agregar Deuda
        </Button>
      </Box>
  
      <Paper style={{ maxHeight: '60vh', overflow: 'auto', width: '100%' }}>
        <Table stickyHeader style={{ minWidth: '750px' }}>
          <TableHead>
            <TableRow>
              <TableCell>Departamento</TableCell>
              <TableCell>Fecha Creación</TableCell>
              <TableCell>Fecha Vencimiento</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Monto Original</TableCell>
              <TableCell>Monto Mora</TableCell>
              <TableCell>Monto Pagado</TableCell>
              <TableCell>Saldo Pendiente</TableCell>
              <TableCell>Fecha Último Pago</TableCell>
              <TableCell>Estado de Pago</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {departamentos.map((departamento) => {
              const deudasDepartamento = filtrarDeudas(departamento.id_departamento);
              const isOpen = selectedRows.includes(departamento.id_departamento);
              return (
                <React.Fragment key={departamento.id_departamento}>
                  <TableRow onClick={() => toggleDeudaDetails(departamento.id_departamento)} style={{ cursor: 'pointer' }}>
                    <TableCell component="th" scope="row">
                      {getNumeroDepartamento(departamento.id_departamento)}
                    </TableCell>
                    <TableCell colSpan={10}></TableCell>
                  </TableRow>
                  {isOpen && deudasDepartamento.map((deuda, deudaIndex) => (
                    <TableRow 
                    key={deudaIndex} 
                    style={{ 
                      backgroundColor: calcularEstadoPago(deuda) === "Pagada" 
                        ? "#d4ebfa" // Verde claro para deudas pagadas
                        : (calcularEstadoPago(deuda) === "Pendiente" ? "#f5dfdf" : "transparent") // Rojo claro para deudas pendientes
                    }}
                  >
                      <TableCell></TableCell>
                      <TableCell>{deuda.fecha_creacion}</TableCell>
                      <TableCell>{deuda.fecha_vencimiento}</TableCell>
                      <TableCell>{deuda.descripcion}</TableCell>
                      <TableCell>{(deuda.monto_original || 0).toFixed(2)}</TableCell>
                      <TableCell>{(deuda.monto_mora || 0).toFixed(2)}</TableCell>
                      <TableCell>{(deuda.monto_pagado || 0).toFixed(2)}</TableCell>
                      <TableCell>{(deuda.saldo_pendiente || 0).toFixed(2)}</TableCell>
                      <TableCell>{deuda.fecha_ultimo_pago}</TableCell>
                      <TableCell>{calcularEstadoPago(deuda)}</TableCell>
                      <TableCell>
                        <Button onClick={() => abrirPagoModal(deuda)} disabled={calcularEstadoPago(deuda) === "Pagada"}>
                          <PaymentIcon /> Pagar
                        </Button>
                        <Button onClick={() => setDeudaAEliminar(deuda)}>
                          <DeleteIcon /> Eliminar
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  {isOpen && (
                    <TableRow>
                      <TableCell colSpan={4}>Total</TableCell>
                      <TableCell>{calcularTotal(deudasDepartamento, 'monto_original').toFixed(2)}</TableCell>
                      <TableCell>{calcularTotal(deudasDepartamento, 'monto_mora').toFixed(2)}</TableCell>
                      <TableCell>{calcularTotal(deudasDepartamento, 'monto_pagado').toFixed(2)}</TableCell>
                      <TableCell>{calcularTotal(deudasDepartamento, 'saldo_pendiente').toFixed(2)}</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
  
      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>Agregar Deuda</DialogTitle>
        <DialogContent>
          <FormularioDeudas
            idEdificio={parseInt(id_edificio)}
            departamentos={departamentos}
            handleClose={handleCloseModal}
            onDeudaCreada={actualizarDeudas}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
  
      {deudaSeleccionada && (
        <PagarDeuda
          deuda={deudaSeleccionada}
          cerrarModal={cerrarPagoModal}
          onPagoExitoso={actualizarDeudas}
        />
      )}
  
      {deudaAEliminar && (
        <EliminarDeuda
          deuda={deudaAEliminar}
          cerrarModal={() => setDeudaAEliminar(null)}
          onDeudaEliminada={actualizarDeudas}
        />
      )}
    </Container>
  );
  
};

export default Deudores;
