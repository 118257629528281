import React, { useState } from 'react';
import { Box, Button, Container, Stepper, Step, StepLabel, Typography, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import FormularioEdificios from '../registroEdificio/registroEdificio';
import RegistroAsignacionMasivo from '../asignacion/FormularioAsignacion';
import FormularioPorcentajeParticipacionMasivo from '../formularioPorcentajeParticipacion/formularioPorcentajeParticipacion';
import RegistroCuentaBancaria from '../CuentaBancaria/RegistroCuentaBancaria';
import { tokens } from '../../theme';
import useStep1 from '../Hook/HookStep1';
import useStep2 from '../Hook/HookStep2';
import useStep3 from '../Hook/HookStep3';
import useStep4 from '../Hook/HookStep4';

const steps = [
  'Información del Edificio',
  'Propietarios y Residentes',
  'Porcentajes de Participación / Parámetros',
  'Tipo de Cuenta',
];

const RegistroComunidad = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id_empresa } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [isSaving, setIsSaving] = useState(false); // Indicar si se está guardando
  const [stepCompleted, setStepCompleted] = useState([false, false, false, false]);
  const [imagenFile, setImagenFile] = useState(null);
  const [imagenEdificio, setImagenEdificio] = useState('');

  const {
    loading,
    tipoEdificio,
    setTipoEdificio,
    tiposPropiedad,
    nombreEdificio,
    setNombreEdificio,
    direccionEdificio,
    setDireccionEdificio,
    distritoEdificio,
    setDistritoEdificio,
    telefonoEdificio,
    setTelefonoEdificio,
    fechaConstruccion,
    setFechaConstruccion,
    cantidadDepartamentos,
    setCantidadDepartamentos,
    cantidadSotanos,
    setCantidadSotanos,
    cantidadCocheras,
    setCantidadCocheras,
    cantidadPisos,
    setCantidadPisos,
    id_edificio,
    saveBuilding,
  } = useStep1(id_empresa);

  const { departamentos, hotTableRefAsignacion, saveAsignacion } = useStep2(id_empresa, id_edificio);
  const { hotTableRefPorcentaje, savePorcentaje } = useStep3(id_empresa, id_edificio, departamentos);
  const {
    tipoCuentaSeleccionado,
    setTipoCuentaSeleccionado,
    nombreBanco,
    setNombreBanco,
    numeroCuenta,
    setNumeroCuenta,
    numeroCuentaCci,
    setNumeroCuentaCci,
    saveCuentaBancaria,
  } = useStep4(id_empresa, id_edificio);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      alert('Todos los datos han sido guardados correctamente.');
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSaveStep = async () => {
    setIsSaving(true); // Indicar que el guardado está en progreso
    let saved = false;

    switch (activeStep) {
      case 0:
        if (!imagenFile) {
          alert('Por favor, selecciona una imagen antes de guardar.');
          setIsSaving(false);
          return;
        }
        saved = await saveBuilding(imagenFile);
        break;
      case 1:
        saved = await saveAsignacion();
        break;
      case 2:
        saved = await savePorcentaje();
        break;
      case 3:
        saved = await saveCuentaBancaria();
        break;
      default:
        saved = false;
    }

    if (saved) {
      const newStepCompleted = [...stepCompleted];
      newStepCompleted[activeStep] = true;
      setStepCompleted(newStepCompleted);
      handleNext();
    } else {
      alert('Ocurrió un error al guardar los datos. Por favor, inténtalo de nuevo.');
    }

    setIsSaving(false); // Reiniciar el estado de guardado
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container>
      <Box sx={{ width: '100%', marginTop: '2rem' }}>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ marginBottom: '2rem' }}>
          {steps.map((label, index) => (
            <Step key={label} completed={stepCompleted[index]}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ marginBottom: '2rem', maxHeight: '60vh', overflowY: 'auto' }}>
          {activeStep === 0 && (
            <FormularioEdificios
              tipoEdificio={tipoEdificio}
              setTipoEdificio={setTipoEdificio}
              tiposPropiedad={tiposPropiedad}
              nombreEdificio={nombreEdificio}
              setNombreEdificio={setNombreEdificio}
              direccionEdificio={direccionEdificio}
              setDireccionEdificio={setDireccionEdificio}
              distritoEdificio={distritoEdificio}
              setDistritoEdificio={setDistritoEdificio}
              telefonoEdificio={telefonoEdificio}
              setTelefonoEdificio={setTelefonoEdificio}
              fechaConstruccion={fechaConstruccion}
              setFechaConstruccion={setFechaConstruccion}
              cantidadDepartamentos={cantidadDepartamentos}
              setCantidadDepartamentos={setCantidadDepartamentos}
              cantidadSotanos={cantidadSotanos}
              setCantidadSotanos={setCantidadSotanos}
              cantidadCocheras={cantidadCocheras}
              setCantidadCocheras={setCantidadCocheras}
              cantidadPisos={cantidadPisos}
              setCantidadPisos={setCantidadPisos}
              imagenFile={imagenFile}
              setImagenFile={setImagenFile}
              imagenEdificio={imagenEdificio}
              setImagenEdificio={setImagenEdificio}
            />
          )}
          {activeStep === 1 && (
            id_edificio ? <RegistroAsignacionMasivo hotTableRef={hotTableRefAsignacion} /> : <Typography>Primero, complete el paso 1.</Typography>
          )}
          {activeStep === 2 && (
            id_edificio ? <FormularioPorcentajeParticipacionMasivo hotTableRef={hotTableRefPorcentaje} departamentos={departamentos} /> : <Typography>Primero, complete el paso 1.</Typography>
          )}
          {activeStep === 3 && (
            id_edificio ? (
              <RegistroCuentaBancaria
                tipoCuentaSeleccionado={tipoCuentaSeleccionado}
                setTipoCuentaSeleccionado={setTipoCuentaSeleccionado}
                nombreBanco={nombreBanco}
                setNombreBanco={setNombreBanco}
                numeroCuenta={numeroCuenta}
                setNumeroCuenta={setNumeroCuenta}
                numeroCuentaCci={numeroCuentaCci}
                setNumeroCuentaCci={setNumeroCuentaCci}
              />
            ) : (
              <Typography>Primero, complete el paso 1.</Typography>
            )
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button disabled={activeStep === 0 || isSaving} onClick={handleBack}>
            Atrás
          </Button>
          <Button variant="contained" onClick={handleSaveStep} disabled={isSaving}>
            {activeStep < steps.length - 1 ? 'Guardar y Siguiente' : 'Guardar y Finalizar'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default RegistroComunidad;

