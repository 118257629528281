import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children, role }) => {
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("role")?.toUpperCase(); // Normaliza el rol a mayúsculas
  const idEmpresa = localStorage.getItem("id_empresa");

  if (!token) {
    return <Navigate to="/iniciar-sesion" />;
  }

  // Verifica que ROLE_ADMIN_MAESTRO no intente acceder a rutas que requieren id_empresa
  if (role === "ROLE_ADMIN_MAESTRO" && userRole === "ROLE_ADMIN_MAESTRO") {
    return <Navigate to="/admin-console" />;
  }

  // Verifica que SUPER_ADMIN o SUB_ADMIN tenga id_empresa antes de acceder
  if ((role === "ROLE_SUB_ADMIN" || role === "ROLE_SUPER_ADMIN") && !idEmpresa) {
    return <Navigate to="/home" />;
  }

  // Añadir condición para ROLE_SUPER_ADMIN si es necesario
  if (role === "ROLE_SUPER_ADMIN" && userRole !== "ROLE_SUPER_ADMIN") {
    return <Navigate to="/home" />;
  }

  return children;
};


export default PrivateRoute;
