import React from 'react';
import { TextField, InputLabel } from '@mui/material';

const GastosBancariosForm = ({ formData, handleInputChange, handleFileChange }) => {
    const validarArchivo = (file) => {
        if (!file.type.startsWith("image/")) {
            alert("Por favor, selecciona un archivo de imagen válido.");
            return false;
        }
        if (file.size === 0) {
            alert("El archivo seleccionado está vacío.");
            return false;
        }
        return true;
    };

    const onFileChange = (event) => {
        const file = event.target.files[0];
        if (file && validarArchivo(file)) {
            handleFileChange(file); // Pasar archivo validado al padre
        }
    };

    return (
        <>
            <TextField
                label="Descripción"
                name="descripcion"
                value={formData.descripcion || ''}
                onChange={handleInputChange}
                fullWidth
                margin="dense"
                helperText={!formData.descripcion && "Este campo es obligatorio."} // Ejemplo de helper text
            />
            <TextField
                label="Fecha de Gasto"
                name="fecha_gasto"
                type="date"
                value={formData.fecha_gasto || ''}
                onChange={handleInputChange}
                fullWidth
                margin="dense"
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                label="Monto"
                name="monto_gasto"
                type="number"
                value={formData.monto_gasto || ''}
                onChange={handleInputChange}
                fullWidth
                margin="dense"
                inputProps={{ step: '0.01' }}
            />
            <InputLabel htmlFor="comprobante">Comprobante</InputLabel>
            <input
                id="comprobante"
                type="file"
                accept="image/*"
                onChange={onFileChange} // Validación del archivo
                style={{ margin: '10px 0' }}
            />
        </>
    );
};

export default GastosBancariosForm;
