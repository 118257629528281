import { useState } from "react";
import axios from "axios";

const useGastosMensualesUpload = (id_empresa, id_edificio) => {
  const [uploading, setUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [error, setError] = useState(null);

  const getHeaders = () => {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token no encontrado.");
    console.log("Token enviado:", token); // Log para verificar el token
    return { Authorization: `Bearer ${token}`, "Content-Type": "application/json" };
  };

  const uploadComprobante = async (imageFile) => {
    if (!imageFile) {
      setError("Por favor, selecciona una imagen.");
      return null;
    }

    if (!imageFile.type.startsWith("image/") || imageFile.size === 0) {
      setError("Por favor, selecciona un archivo de imagen válido.");
      return null;
    }

    setUploading(true);
    setError(null);

    try {
      const uniqueId = crypto.randomUUID();
      const fileKey = `gastos_mensuales/${uniqueId}_${imageFile.name}`;
      console.log("Key generada para el archivo:", fileKey); // Log para verificar el key generado

      // Obtener URL pre-firmada desde el backend
      const presignedUrlResponse = await axios.post(
        `https://app.propia.pe/apiRest/s3/upload`,
        { fileKey },
        { headers: getHeaders() }
      );

      const presignedUrl = presignedUrlResponse.data;
      console.log("URL pre-firmada obtenida:", presignedUrl); // Log para verificar la URL pre-firmada

      // Subir imagen a S3
      await axios.put(presignedUrl, imageFile, {
        headers: { "Content-Type": imageFile.type || "application/octet-stream" },
      });

      const uploadedUrl = presignedUrl.split("?")[0];
      setImageUrl(uploadedUrl);
      console.log("URL de la imagen subida:", uploadedUrl); // Log para verificar la URL subida
      return uploadedUrl;
    } catch (err) {
      console.error("Error al subir la imagen:", err.response?.data || err.message);
      setError(err.response?.data || err.message || "Error al subir la imagen.");
      return null;
    } finally {
      setUploading(false);
    }
  };

  const saveGastosMensuales = async (gastosMensualesData, imageFile) => {
    try {
      const gastosConComprobante = await Promise.all(
        gastosMensualesData.map(async (gasto) => {
          let uploadedUrl = gasto.url_boucher_gasto;

          // Subir la imagen si existe un archivo
          if (imageFile) {
            uploadedUrl = await uploadComprobante(imageFile);
            if (!uploadedUrl) throw new Error("Error al subir la imagen.");
          }

          return {
            ...gasto,
            url_boucher_gasto: uploadedUrl, // Campo revisado para coincidir con el backend
          };
        })
      );

      console.log("Datos enviados al backend:", gastosConComprobante); // Log para verificar datos enviados

      const response = await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/gastos_mensuales/insertar`,
        gastosConComprobante, // Enviar como arreglo
        { headers: getHeaders() }
      );

      console.log("Respuesta del backend:", response.data); // Log para verificar respuesta del backend
      return response.data;
    } catch (err) {
      console.error("Error al guardar los gastos mensuales:", err.response?.data || err.message);
      setError(err.response?.data || err.message || "Error al guardar los gastos mensuales.");
      return null;
    }
  };

  const modificarGastoMensual = async (gastoMensualData, imageFile) => {
    try {
      if (!gastoMensualData.id_gastos_mensuales) {
        throw new Error("El campo 'id_gastos_mensuales' es obligatorio para modificar un gasto mensual.");
      }

      let uploadedUrl = gastoMensualData.url_boucher_gasto;

      if (imageFile) {
        uploadedUrl = await uploadComprobante(imageFile);
        if (!uploadedUrl) throw new Error("Error al subir la imagen.");
      }

      const dataConComprobante = {
        ...gastoMensualData,
        url_boucher_gasto: uploadedUrl, // Campo revisado para coincidir con el backend
      };

      console.log("Datos enviados para modificación:", dataConComprobante); // Log para verificar datos enviados

      const response = await axios.put(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/gastos_mensuales/modificar/${gastoMensualData.id_gastos_mensuales}`,
        dataConComprobante,
        { headers: getHeaders() }
      );

      console.log("Respuesta del backend para modificación:", response.data); // Log para verificar respuesta del backend
      return response.data;
    } catch (err) {
      console.error("Error al modificar el gasto mensual:", err.response?.data || err.message);
      setError(err.response?.data || err.message || "Error al modificar el gasto mensual.");
      return null;
    }
  };

  return {
    uploading,
    imageUrl,
    error,
    uploadComprobante,
    saveGastosMensuales,
    modificarGastoMensual,
  };
};

export default useGastosMensualesUpload;
