import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardMedia,
} from "@mui/material";
import axios from "axios";

const IngresosImagenDialog = ({ id_empresa, id_edificio, open, onClose, ingreso, onUploadSuccess }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setPreviewUrl(ingreso?.url_comprobante || ""); // Cambiado de url_comprobante_ingreso a url_comprobante
  }, [ingreso]);
  

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      setError(null);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setError("Por favor, selecciona una imagen.");
      return;
    }

    try {
      setUploading(true);
      setError(null);

      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token de autorización no encontrado.");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      let uploadedUrl = ingreso?.url_comprobante_ingreso;

      // Subir la imagen si existe un archivo
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile);

        const uploadResponse = await axios.post(
          `https://app.propia.pe/apiRest/s3/upload`,
          { fileKey: `ingresos/${selectedFile.name}` },
          { headers }
        );

        const presignedUrl = uploadResponse.data;
        await axios.put(presignedUrl, selectedFile, {
          headers: { "Content-Type": selectedFile.type || "application/octet-stream" },
        });

        uploadedUrl = presignedUrl.split("?")[0];
      }

      // Enviar datos al backend con la URL de la imagen
      const dataConComprobante = {
        ...ingreso,
        url_comprobante_ingreso: uploadedUrl,
      };

      const response = await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/ingresos/insertar`,
        [dataConComprobante],
        { headers }
      );

      setPreviewUrl(uploadedUrl);
      setSelectedFile(null);
      onUploadSuccess(uploadedUrl);
      console.log("Respuesta del backend:", response.data);
    } catch (err) {
      console.error("Error al subir la imagen:", err.response?.data || err.message);
      setError("Error al subir la imagen. Verifica tu conexión o inténtalo nuevamente.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {previewUrl ? "Actualizar Comprobante de Ingreso" : "Subir Comprobante de Ingreso"}
      </DialogTitle>
      <DialogContent>
        {uploading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" gap="20px">
            <Card sx={{ width: "100%", maxWidth: 600, marginBottom: "1rem" }}>
              {previewUrl ? (
                <CardMedia
                  component="img"
                  sx={{
                    height: "400px",
                    objectFit: "contain",
                    backgroundColor: "#f0f0f0",
                  }}
                  image={previewUrl}
                  alt={`Comprobante de ingreso - ${ingreso?.tipoIngreso || "Desconocido"}`}
                />
              ) : (
                <Box
                  sx={{
                    height: 400,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  <Typography color="textSecondary">
                    No hay imagen disponible para este ingreso.
                  </Typography>
                </Box>
              )}
            </Card>

            <input
              accept="image/*"
              type="file"
              style={{ display: "none" }}
              id="upload-button"
              onChange={handleFileChange}
            />
            <label htmlFor="upload-button">
              <Button variant="outlined" component="span" color="primary">
                Seleccionar Imagen
              </Button>
            </label>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button
          onClick={handleUpload}
          color="primary"
          disabled={!selectedFile || uploading}
        >
          {uploading ? <CircularProgress size={24} /> : "Subir"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IngresosImagenDialog;
