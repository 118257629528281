import React, { useState } from 'react';
import { DialogContent, DialogTitle, TextField, Button, DialogActions } from '@mui/material';
import axios from "axios";

const FormularioCocheras = ({ onClose, idEdificio, onSubmitSuccess }) => {
  // Estado inicial para los campos de la cochera
  const [numeroCochera, setNumeroCochera] = useState('');
  const [pisoCochera, setPisoCochera] = useState('');
  const [areaCochera, setAreaCochera] = useState('');

  // Función para manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    const cocheraData = {
      id_edificio: idEdificio,
      numero_cochera: numeroCochera,
      piso_cochera: pisoCochera,
      area_cochera: areaCochera,
      estado: true, // asumiendo que quieres mantener este campo
    };

    try {
      // Llamada a la API para insertar la nueva cochera
      await axios.post("https://app.propia.pe/apiRest/cocheras/insertar", cocheraData);
      onClose(); // Cierra el formulario
      onSubmitSuccess(); // Llama a la función para actualizar el componente padre
    } catch (error) {
      console.error("Error al agregar la cochera:", error);
      alert("Error al registrar la cochera. Por favor, intente de nuevo.");
    }
  };

  return (
    <>
      <DialogTitle>Agregar Cochera</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="numeroCochera"
          label="Número de Cochera"
          type="number"
          fullWidth
          variant="outlined"
          value={numeroCochera}
          onChange={(e) => setNumeroCochera(e.target.value)}
        />
        <TextField
          margin="dense"
          id="pisoCochera"
          label="Piso de la Cochera"
          type="text"
          fullWidth
          variant="outlined"
          value={pisoCochera}
          onChange={(e) => setPisoCochera(e.target.value)}
        />
        <TextField
          margin="dense"
          id="areaCochera"
          label="Área de la Cochera (m2)"
          type="number"
          fullWidth
          variant="outlined"
          value={areaCochera}
          onChange={(e) => setAreaCochera(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit}>Guardar</Button>
      </DialogActions>
    </>
  );
};

export default FormularioCocheras;
