import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';

const EditarEgresoForm = ({ rowData, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({ ...rowData });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    onSubmit(formData); // Enviar los datos editados al padre
    onClose(); // Cerrar el formulario
  };

  return (
    <Box p={2} display="flex" flexDirection="column" gap={2}>
      <TextField
        label="Fecha"
        name="fecha"
        type="date"
        value={formData.fecha || ''}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Tipo"
        name="tipo"
        value={formData.tipo || ''}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Monto"
        name="monto"
        type="number"
        value={formData.monto || ''}
        onChange={handleInputChange}
        fullWidth
      />
      <TextField
        label="Concepto"
        name="concepto"
        value={formData.concepto || ''}
        onChange={handleInputChange}
        fullWidth
      />
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Guardar Cambios
      </Button>
      <Button variant="outlined" onClick={onClose}>
        Cancelar
      </Button>
    </Box>
  );
};

export default EditarEgresoForm;
