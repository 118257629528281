import React from "react";
import { InputLabel, Select, MenuItem, TextField } from "@mui/material";

const CuotaExtraordinariaForm = ({ formData, handleInputChange, handleFileChange, departamentos, fetchingDepartamentos }) => {
  return (
    <>
      <InputLabel>Departamento</InputLabel>
      <Select
        name="id_departamento"
        fullWidth
        margin="dense"
        onChange={handleInputChange}
        value={formData.id_departamento || ""}
        disabled={fetchingDepartamentos}
      >
        {departamentos.map((depto) => (
          <MenuItem key={depto.id_departamento} value={depto.id_departamento}>
            {depto.numero_departamento}
          </MenuItem>
        ))}
      </Select>
      <TextField
        label="Fecha de Cuota"
        name="fecha_cuota"
        type="date"
        fullWidth
        margin="dense"
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Monto"
        name="monto_cuota"
        type="number"
        fullWidth
        margin="dense"
        onChange={handleInputChange}
      />
      <InputLabel>Método de Pago</InputLabel>
      <Select
        name="metodo_pago"
        fullWidth
        margin="dense"
        onChange={handleInputChange}
        value={formData.metodo_pago || ""}
      >
        <MenuItem value="Transferencia Bancaria">Transferencia Bancaria</MenuItem>
        <MenuItem value="Transferencia Interbancaria">Transferencia Interbancaria</MenuItem>
        <MenuItem value="Depósito">Depósito</MenuItem>
      </Select>
      <TextField
        label="Descripción"
        name="descripcion"
        fullWidth
        margin="dense"
        onChange={handleInputChange}
      />
      <TextField
        label="Comprobante"
        type="file"
        fullWidth
        margin="dense"
        onChange={(e) => handleFileChange(e.target.files[0])}
      />
    </>
  );
};

export default CuotaExtraordinariaForm;
