import React from 'react';
import { Dialog, DialogTitle, DialogContent, Table, TableRow, TableCell, TableBody, Button, Box, Typography } from '@mui/material';

const FlujoDialog = ({
    open, onClose,
    ingresosRecibosData = [],
    ingresosCuotaExtraData = [],
    ingresosOtrosData = [],
    ingresosCobranzaCuotasAtrasadasData = [],
    ingresosMorasData = [],
    personalData = [],
    serviciosData = [],
    administracionData = [],
    gastosVariosData = [],
    gastosBancariosData = [],
    mantenimientosData = [],
    saldoInicialData = [],
    selectedYear
}) => {

    // Función para calcular el total por mes
    const calculateMonthlyTotals = (data) => {
        const monthlyTotals = Array(12).fill(0);
        data.forEach(item => {
            const month = new Date(item.fecha).getMonth(); // Obtener el mes (0-11)
            monthlyTotals[month] += item.monto;
        });
        return monthlyTotals;
    };

    // Calcular los totales por mes para cada categoría
    const saldoInicialMonthly = calculateMonthlyTotals(saldoInicialData);
    const ingresosRecibosMonthly = calculateMonthlyTotals(ingresosRecibosData);
    const ingresosCuotaExtraMonthly = calculateMonthlyTotals(ingresosCuotaExtraData);
    const ingresosOtrosMonthly = calculateMonthlyTotals(ingresosOtrosData);
    const ingresosCobranzaCuotasAtrasadasMonthly = calculateMonthlyTotals(ingresosCobranzaCuotasAtrasadasData);
    const ingresosMorasMonthly = calculateMonthlyTotals(ingresosMorasData);
    const personalMonthly = calculateMonthlyTotals(personalData);
    const serviciosMonthly = calculateMonthlyTotals(serviciosData);
    const administracionMonthly = calculateMonthlyTotals(administracionData);
    const gastosVariosMonthly = calculateMonthlyTotals(gastosVariosData);
    const gastosBancariosMonthly = calculateMonthlyTotals(gastosBancariosData);
    const mantenimientosMonthly = calculateMonthlyTotals(mantenimientosData);

    // Función para calcular el total anual
    const calculateAnnualTotal = (monthlyTotals) => {
        return monthlyTotals.reduce((acc, curr) => acc + curr, 0);
    };

    // Meses en columnas
    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    // Datos estructurados para la tabla
    const dataRows = [
        { concepto: "Saldo Inicial", monthly: saldoInicialMonthly },
        { concepto: "Recibos de Mantenimiento", monthly: ingresosRecibosMonthly },
        { concepto: "Cobranza Cuotas Atrasadas", monthly: ingresosCobranzaCuotasAtrasadasMonthly },
        { concepto: "Moras", monthly: ingresosMorasMonthly },
        { concepto: "Cuota Extra Ordinaria", monthly: ingresosCuotaExtraMonthly },
        { concepto: "Otros Ingresos", monthly: ingresosOtrosMonthly },
        { concepto: "Remuneraciones", monthly: personalMonthly },
        { concepto: "Servicios", monthly: serviciosMonthly },
        { concepto: "Mantenimientos", monthly: mantenimientosMonthly },
        { concepto: "Administración", monthly: administracionMonthly },
        { concepto: "Gastos Varios", monthly: gastosVariosMonthly },
        { concepto: "Gastos Bancarios", monthly: gastosBancariosMonthly }
    ];

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle align="center">Flujo de Caja</DialogTitle>
            <DialogContent dividers={true}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Concepto</TableCell>
                            {months.map((month, index) => (
                                <TableCell key={index} style={{ fontWeight: 'bold', textAlign: 'right' }}>{month}</TableCell>
                            ))}
                            <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>Total</TableCell>
                        </TableRow>
                        {dataRows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.concepto}</TableCell>
                                {row.monthly.map((amount, monthIndex) => (
                                    <TableCell key={monthIndex} style={{ textAlign: 'right' }}>
                                        {amount.toFixed(2)}
                                    </TableCell>
                                ))}
                                <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                    {calculateAnnualTotal(row.monthly).toFixed(2)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Box mt={2} textAlign="right">
                    <Button onClick={onClose} color="primary" variant="contained">
                        Cerrar
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default FlujoDialog;
