import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../theme';

const DepartamentosConDeuda = () => {
  const { id_empresa, id_edificio } = useParams(); // Obtener id_empresa y id_edificio desde la URL
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [departamentosConDeuda, setDepartamentosConDeuda] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDepartamentosConDeuda = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error("Token de autorización no encontrado.");
        }

        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };

        // Actualizar las URLs para incluir id_empresa y id_edificio
        const propietariosResponse = await axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/propietarios`, { headers });
        const deudasResponse = await axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/deudas`, { headers });
        const departamentosResponse = await axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos`, { headers });
        const asignacionesResponse = await axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/asignacion`, { headers });

        const propietarios = propietariosResponse.data;
        const deudas = deudasResponse.data.filter(deuda => deuda.saldo_pendiente > 0);
        const departamentos = departamentosResponse.data;
        const asignaciones = asignacionesResponse.data;

        const departamentosConDeudaTemp = deudas.map(deuda => {
          const asignacion = asignaciones.find(asig => asig.id_departamento === deuda.id_departamento);
          const departamento = departamentos.find(dep => dep.id_departamento === deuda.id_departamento);
          const propietario = propietarios.find(prop => prop.id_propietario === asignacion?.id_propietario);

          return {
            id_departamento: deuda.id_departamento,
            numero_departamento: departamento ? departamento.numero_departamento : "N/A",
            nombre_propietario: propietario ? propietario.nombre_propietario : "N/A",
            apellido_propietario: propietario ? propietario.apellido_propietario : "N/A",
            totalDeuda: deuda.saldo_pendiente,
          };
        });

        // Ordenar los departamentos por número de departamento de forma ascendente
        departamentosConDeudaTemp.sort((a, b) => a.numero_departamento - b.numero_departamento);
        setDepartamentosConDeuda(departamentosConDeudaTemp);
      } catch (error) {
        console.error("Error al obtener los datos de departamentos con deuda:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id_empresa && id_edificio) {
      fetchDepartamentosConDeuda();
    }
  }, [id_empresa, id_edificio]);

  if (loading) {
    return <Typography variant="h6" align="center">Cargando datos...</Typography>;
  }

  return (
    <div>
      <Paper style={{ overflow: 'auto' }}>
        <Typography
          variant="h6"
          style={{
            textAlign: 'center',
            marginBottom: '0.5rem',
            backgroundColor: colors.blueAccent[700],
            color: 'white',
            padding: theme.spacing(1),
          }}
        >
          Departamentos con Deudas Pendientes
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: colors.blueAccent[700] }}>
              <TableCell style={{ color: 'white' }}>Departamento</TableCell>
              <TableCell style={{ color: 'white' }}>Nombre</TableCell>
              <TableCell style={{ color: 'white' }}>Apellido</TableCell>
              <TableCell style={{ color: 'white' }}>Total Deuda</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {departamentosConDeuda.map(({ id_departamento, numero_departamento, nombre_propietario, apellido_propietario, totalDeuda }) => (
              <TableRow key={id_departamento}>
                <TableCell>{numero_departamento}</TableCell>
                <TableCell>{nombre_propietario}</TableCell>
                <TableCell>{apellido_propietario}</TableCell>
                <TableCell>S/.{totalDeuda.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default DepartamentosConDeuda;
