import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Grid, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../theme';
import DepartamentosConDeuda from '../DepartamentosconDuedas/DepartamentosconDeudas';
import useFetchIngresos from '../Hook/HookIngresos';
import useFetchData from '../Hook/hookEgresos';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FinancialBarChart from '../ChartsIngresosEgresos/FinancialBarChart';
import './dashboard.css';

const Dashboard = () => {
  const { id_empresa, id_edificio } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [gastosData, setGastosData] = useState([]);
  const [ingresosData, setIngresosData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [monthlyData, setMonthlyData] = useState(Array.from({ length: 12 }, () => ({ ingresos: 0, egresos: 0 })));

  // Hooks de ingresos y gastos
  const {
    loading: loadingIngresos,
    ingresosRecibosData,
    ingresosOtrosData,
    ingresosCuotaExtraData,
    ingresosMorasData,
  } = useFetchIngresos(selectedMonth, selectedYear, id_empresa, id_edificio);

  const {
    loading: loadingGastos,
    administracionData,
    serviciosData,
    pagosPersonalData,
    gastosBancariosData,
    gastosVariosData,
    mantenimientosData,
  } = useFetchData(selectedMonth, selectedYear, id_empresa, id_edificio);

  // Combinar y actualizar datos de ingresos y gastos
  useEffect(() => {
    if (!loadingIngresos && !loadingGastos) {
      const allIngresosData = [
        ...ingresosRecibosData,
        ...ingresosOtrosData,
        ...ingresosCuotaExtraData,
        ...ingresosMorasData,
      ].map((ingreso) => ({
        ...ingreso,
        fecha_pago: ingreso.fecha,
        monto: parseFloat(ingreso.monto),
      }));

      const allGastosData = [
        ...administracionData,
        ...serviciosData,
        ...pagosPersonalData,
        ...gastosBancariosData,
        ...gastosVariosData,
        ...mantenimientosData,
      ].map((gasto) => ({
        ...gasto,
        fecha: gasto.fecha,
        monto: parseFloat(gasto.monto),
      }));

      // Filtrar datos por año
      const filteredIngresosData = filterDataByYear(allIngresosData, 'fecha_pago', selectedYear);
      const filteredGastosData = filterDataByYear(allGastosData, 'fecha', selectedYear);

      // Crear datos mensuales
      const updatedMonthlyData = Array.from({ length: 12 }, (_, i) => {
        const month = i + 1;
        const ingresos = filteredIngresosData
          .filter((item) => new Date(item.fecha_pago).getMonth() + 1 === month)
          .reduce((total, current) => total + current.monto, 0);
        const egresos = filteredGastosData
          .filter((item) => new Date(item.fecha).getMonth() + 1 === month)
          .reduce((total, current) => total + current.monto, 0);

        return { ingresos, egresos };
      });

      setGastosData(filteredGastosData);
      setIngresosData(filteredIngresosData);
      setMonthlyData(updatedMonthlyData);
    }
  }, [
    ingresosRecibosData,
    ingresosOtrosData,
    ingresosCuotaExtraData,
    ingresosMorasData,
    administracionData,
    serviciosData,
    pagosPersonalData,
    gastosBancariosData,
    gastosVariosData,
    mantenimientosData,
    loadingIngresos,
    loadingGastos,
    selectedYear,
  ]);

  // Filtrar datos por año
  const filterDataByYear = (data, fechaPropiedad, year) => {
    return data.filter((item) => {
      const fecha = new Date(item[fechaPropiedad]);
      return fecha.getFullYear() === year;
    });
  };

  // Calcular ingresos, egresos y saldo para el mes actual
  const currentMonthData = (data, fechaPropiedad) => {
    return data.filter((item) => {
      const fecha = new Date(item[fechaPropiedad]);
      return fecha.getMonth() + 1 === selectedMonth && fecha.getFullYear() === selectedYear;
    });
  };

  const ingresosMesActual = currentMonthData(ingresosData, 'fecha_pago').reduce(
    (total, current) => total + current.monto, 0
  );
  const egresosMesActual = currentMonthData(gastosData, 'fecha').reduce(
    (total, current) => total + current.monto, 0
  );
  const saldoMesActual = ingresosMesActual - egresosMesActual;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Panel de Finanzas</Typography>
      <Grid container spacing={2} style={{ marginTop: '1rem' }}>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box className="panel-item ingresos">
                <ArrowUpwardIcon className="icon" />
                <div className="text-value-box">
                  <Typography variant="subtitle1">Total de Ingresos</Typography>
                  <Typography variant="h6">S/ {ingresosMesActual.toFixed(2)}</Typography>
                </div>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className="panel-item egresos">
                <ArrowDownwardIcon className="icon" />
                <div className="text-value-box">
                  <Typography variant="subtitle1">Total de Egresos</Typography>
                  <Typography variant="h6">S/ {egresosMesActual.toFixed(2)}</Typography>
                </div>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box className="panel-item saldo-total">
                <ArrowUpwardIcon className="icon" />
                <div className="text-value-box">
                  <Typography variant="subtitle1">Saldo Total</Typography>
                  <Typography variant="h6">S/ {saldoMesActual.toFixed(2)}</Typography>
                </div>
              </Box>
            </Grid>
          </Grid>
          <Box mt={4} style={{ backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '8px' }}>
            <FinancialBarChart monthlyData={monthlyData} />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box>
            <DepartamentosConDeuda theme={theme} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
