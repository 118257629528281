import { useState } from "react";
import axios from "axios";

const useCobranzas = (id_empresa, id_edificio) => {
  const [uploading, setUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [error, setError] = useState(null);

  const getHeaders = () => {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token no encontrado.");
    return { Authorization: `Bearer ${token}`, "Content-Type": "application/json" };
  };

  const uploadComprobante = async (imageFile) => {
    if (!imageFile) {
      setError("Por favor, selecciona una imagen.");
      return null;
    }

    if (!imageFile.type.startsWith("image/") || imageFile.size === 0) {
      setError("Por favor, selecciona un archivo de imagen válido.");
      return null;
    }

    setUploading(true);
    setError(null);

    try {
      // Generar el key único para la imagen
      const uniqueId = crypto.randomUUID();
      const fileKey = `pago_recibo_mantenimiento/${uniqueId}_${imageFile.name}`;

      console.log("Key generada para el archivo:", fileKey);

      // Solicitar URL pre-firmada del backend
      const presignedUrlResponse = await axios.post(
        `https://app.propia.pe/apiRest/s3/upload`,
        { fileKey },
        { headers: getHeaders() }
      );

      const presignedUrl = presignedUrlResponse.data;
      console.log("URL pre-firmada obtenida:", presignedUrl);

      // Subir la imagen a S3 usando la URL pre-firmada
      await axios.put(presignedUrl, imageFile, {
        headers: { "Content-Type": imageFile.type || "application/octet-stream" },
      });

      // Retornar la URL final de la imagen
      const uploadedUrl = presignedUrl.split("?")[0];
      setImageUrl(uploadedUrl);
      console.log("URL de la imagen subida:", uploadedUrl);
      return uploadedUrl;
    } catch (err) {
      console.error("Error al subir la imagen:", err.response?.data || err.message);
      setError(err.response?.data || err.message || "Error al subir la imagen.");
      return null;
    } finally {
      setUploading(false);
    }
  };

  const saveCobranza = async (cobranzaData, imageFile) => {
    try {
      let uploadedUrl = cobranzaData.url_comprobante_pago_recibo;

      // Subir la imagen si existe un archivo
      if (imageFile) {
        uploadedUrl = await uploadComprobante(imageFile);
        if (!uploadedUrl) throw new Error("Error al subir la imagen.");
      }

      const dataConComprobante = {
        ...cobranzaData,
        url_comprobante_pago_recibo: uploadedUrl, // Campo actualizado para el backend
      };

      console.log("Datos enviados al backend:", dataConComprobante);

      const response = await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/pago_recibo_mantenimiento/insertar`,
        dataConComprobante,
        { headers: getHeaders() }
      );

      console.log("Respuesta del backend:", response.data);
      return response.data;
    } catch (err) {
      console.error("Error al guardar la cobranza:", err.response?.data || err.message);
      setError(err.response?.data || err.message || "Error al guardar la cobranza.");
      return null;
    }
  };

  return {
    uploading,
    imageUrl,
    error,
    uploadComprobante,
    saveCobranza,
  };
};

export default useCobranzas;
