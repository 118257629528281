import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchSaldoInicial = (id_empresa, id_edificio) => {
  const [loadingSaldoInicial, setLoadingSaldoInicial] = useState(true);
  const [saldoInicialData, setSaldoInicialData] = useState([]);
  const [error, setError] = useState(null);

  const fetchSaldoInicial = async () => {
    setLoadingSaldoInicial(true);
    try {
      const token = localStorage.getItem('token'); // Obtener el token del localStorage

      const response = await axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/saldo_inicial`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log("Respuesta de API Saldo Inicial:", response.data);

      setSaldoInicialData(Array.isArray(response.data) ? response.data.map(item => ({
        id: `saldo-inicial-${item.id_saldo_inicial}`,
        id_empresa: item.id_empresa,
        id_edificio: item.id_edificio,
        descripcion: item.descripcion_saldo_inicial,
        fecha: item.fecha_saldo_inicial,
        monto: item.monto_saldo_inicial,
      })) : []);

      setError(null); // Limpiar errores si la solicitud es exitosa
    } catch (error) {
      console.error("Error fetching saldo inicial data:", error);
      setError("Hubo un error al obtener el saldo inicial.");
    } finally {
      setLoadingSaldoInicial(false);
    }
  };

  useEffect(() => {
    if (id_empresa && id_edificio) {
      fetchSaldoInicial();
    }
  }, [id_empresa, id_edificio]);

  return {
    loadingSaldoInicial,
    saldoInicialData,
    error,
    fetchSaldoInicial,
  };
};

export default useFetchSaldoInicial;
