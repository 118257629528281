import React, { useState } from "react";
import { Box, TextField, Button } from "@mui/material";
import axios from "axios";

const RegistroGastosMensuales = ({ idEdificio, onClose, onDataUpdate }) => {
  const [fecha, setFecha] = useState("");
  const [concepto, setConcepto] = useState("");
  const [importe, setImporte] = useState("");

  const handleRegistro = async (e) => {
    e.preventDefault();

    const gastoMensualData = {
      id_edificio: idEdificio,
      fecha_gasto_mensual: fecha,
      concepto_gasto_mensual: concepto,
      importe_gasto_mensual: importe,
    };

    try {
      await axios.post("https://app.propia.pe/apiRest/gastos_mensuales1/insertar", gastoMensualData);
      // ... más de tu lógica existente
      onDataUpdate(); // Llama a la función para actualizar los datos en el componente padre
      onClose(); // Cerrar el diálogo después de agregar
    } catch (error) {
      console.error("Error al agregar el gasto mensual:", error);
    }
  };

  const resetFields = () => {
    setFecha("");
    setConcepto("");
    setImporte("");
  };

  return (
    <Box m="20px">
      <h2>Registro de Gasto Mensual</h2>
      <form onSubmit={handleRegistro}>
        <TextField
          label="Fecha"
          type="date"
          fullWidth
          required
          value={fecha}
          onChange={(e) => setFecha(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Concepto"
          variant="outlined"
          fullWidth
          required
          value={concepto}
          onChange={(e) => setConcepto(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Importe"
          variant="outlined"
          fullWidth
          required
          value={importe}
          onChange={(e) => setImporte(e.target.value)}
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Agregar
        </Button>
      </form>
    </Box>
  );
};

export default RegistroGastosMensuales;
