import React from 'react';
import { Box, Typography } from "@mui/material";

const TotalIngresos = ({
  ingresosData, // Datos de los ingresos
  colors,
  customStyles = {}, // Para estilos personalizados del componente
  customTextStyles = {}, // Para el texto "Total de Ingresos"
  customValueStyles = {}, // Para el valor del total
  customText = "Total de Ingresos" // Texto por defecto
}) => {
  const totalIngresos = ingresosData.reduce((total, ingreso) => {
    const monto = parseFloat(ingreso.monto); 
    return !isNaN(monto) ? total + monto : total;
  }, 0);
  
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={1}
      bgcolor={colors.blueAccent[700]} // Este es el color por defecto, pero se puede sobrescribir con customStyles
      color="white"
      borderRadius={2}
      mt={2}
      width="200px" // Este ancho se puede ajustar según necesidades
      {...customStyles} // Aquí se aplican los estilos personalizados
    >
      <Typography variant="subtitle1" style={customTextStyles}>{customText}</Typography>
      <Typography variant="h6" style={customValueStyles}>S/ {totalIngresos.toFixed(2)}</Typography>
    </Box>
  );
};

export default TotalIngresos;
