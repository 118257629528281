import React, { useState } from 'react';
import {  Dialog,  DialogTitle,  Typography,  DialogContent,  DialogActions,  Button,  TextField,  Checkbox,
  FormControlLabel,
  Snackbar,
  Alert
} from '@mui/material';
import axios from 'axios';

const EmisionUnoRecibosDialog = ({ open, onClose, reciboParaEmitir, idEdificio, onEmitirRecibo }) => {
  const [fechaEmision, setFechaEmision] = useState('');
  const [fechaVencimiento, setFechaVencimiento] = useState('');
  const [aplicarMora, setAplicarMora] = useState(false);
  const [valorPorDia, setValorPorDia] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [error, setError] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const handleSubmit = async () => {
    if (!reciboParaEmitir) {
      setMensaje("No hay recibo para emitir o la variable es undefined.");
      setError(true);
      setAlertOpen(true);
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      setMensaje("Token de autorización no encontrado.");
      setError(true);
      setAlertOpen(true);
      return;
    }

    const reciboParaEmitirFormateado = {
      id_edificio: idEdificio,
      id_departamento: reciboParaEmitir.id,
      fecha_emision: fechaEmision,
      fecha_vencimiento: fechaVencimiento,
      estado: false,
      lectura_actual: reciboParaEmitir.lectura_actual !== "N/A" ? parseFloat(reciboParaEmitir.lectura_actual) : 0,
      lectura_anterior: reciboParaEmitir.lectura_anterior !== "N/A" ? parseFloat(reciboParaEmitir.lectura_anterior) : 0,
      monto_cuota: parseFloat(reciboParaEmitir.monto_cuota),
      total_recibo_mantenimiento: parseFloat(reciboParaEmitir.total_recibo_mantenimiento),
      consumo_m3: parseFloat(reciboParaEmitir.consumo_m3),
      costo_total: parseFloat(reciboParaEmitir.costo_total),
      aplicar_mora: aplicarMora,
      valor_por_dia_mora: aplicarMora ? parseFloat(valorPorDia) : 0
    };

    try {
      const response = await axios.post(
        `https://app.propia.pe/apiRest/empresas/${reciboParaEmitir.id_empresa}/edificios/${idEdificio}/recibos_emitidos`,
        [reciboParaEmitirFormateado],
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setMensaje('Recibo emitido correctamente.');
      setError(false);
      setAlertOpen(true);
      onEmitirRecibo(response.data);
      onClose();
    } catch (error) {
      setMensaje(`Error al emitir recibo: ${error.response ? error.response.data : error.message}`);
      setError(true);
      setAlertOpen(true);
    }
  };

  return (
    <Dialog open={open} onClose={() => { onClose(); setMensaje(''); setError(false); }}>
      <DialogTitle>Emisión de Recibo de Mantenimiento</DialogTitle>
      <DialogContent>
        <TextField
          label="Fecha de Emisión"
          type="date"
          value={fechaEmision}
          onChange={e => setFechaEmision(e.target.value)}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Fecha de Vencimiento"
          type="date"
          value={fechaVencimiento}
          onChange={e => setFechaVencimiento(e.target.value)}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
        <FormControlLabel
          control={<Checkbox checked={aplicarMora} onChange={e => setAplicarMora(e.target.checked)} />}
          label="Aplicar Mora después de la fecha límite"
        />
        {aplicarMora && (
          <TextField
            label="Valor por día de Mora (S/.)"
            type="number"
            value={valorPorDia}
            onChange={e => setValorPorDia(e.target.value)}
            fullWidth
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { onClose(); setMensaje(''); setError(false); }}>Cancelar</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">Emitir Recibo</Button>
      </DialogActions>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity={error ? "error" : "success"} variant="filled" sx={{ width: '100%' }}>
          {mensaje}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default EmisionUnoRecibosDialog;
