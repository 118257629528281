import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { format, parseISO, isValid } from 'date-fns';
import { es } from 'date-fns/locale';

const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontFamily: 'Helvetica',
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
    },
    leftColumn: {
        width: '33%',
        paddingRight: 10,
        textAlign: 'left',
    },
    mainHeader: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 5,
        borderBottomWidth: 1,
        borderBottomColor: 'black',
        textAlign: 'center',
    },
    detailText: {
        fontSize: 10,
        marginBottom: 2,
    },
    sectionHeader: {
        fontSize: 13,
        marginTop: 10,
        fontWeight: 'bold',
        color: 'black',
    },
    sectionTitleContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: 'black',
        marginBottom: 5,
    },
    sectionTitle: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    sectionValue: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    ingresosTitle: {
        borderBottomWidth: 1,
        borderBottomColor: 'black',
        marginBottom: 5,
        fontSize: 12,
        fontWeight: 'bold',
    },
    egresosTitle: {
        borderBottomWidth: 1,
        borderBottomColor: 'black',
        marginBottom: 5,
        fontSize: 12,
        fontWeight: 'bold',
    },
    subHeader: {
        fontSize: 10,
        marginVertical: 5,
        fontWeight: 'bold',
        color: 'black',
        textAlign: 'left',
        borderBottomWidth: 1,
        borderBottomColor: 'black',
        alignSelf: 'flex-start',
    },
    listItem: {
        fontSize: 8,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 5,
    },
    itemDetail: {
        width: '25%',
        textAlign: 'right',
    },
    itemDetailConcept: {
        width: '45%',
        textAlign: 'left',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    sumaIngresos: {
        fontSize: 11,
        fontWeight: 'bold',
        textAlign: 'right',
        marginBottom: 5,
        color: 'black',
        marginTop: 8,
    },
    sumaEgresos: {
        fontSize: 11,
        fontWeight: 'bold',
        textAlign: 'right',
        marginBottom: 10,
        color: 'black',
        marginTop: 8,
    },
    footerContainer: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
    },
    footer: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: 12,
        padding: 5,
        backgroundColor: "#E6E924",
        borderWidth: 1,
        borderColor: '#666',
        borderStyle: 'solid',
    },
    footerText: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    footerAmount: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'right',
    },
});

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return format(date, 'MM/yyyy', { locale: es });
};

const formatNumber = (number) => {
    return new Intl.NumberFormat('es-PE', { 
        style: 'currency', 
        currency: 'PEN', 
        minimumFractionDigits: 2 
    }).format(number).replace('PEN', 'S/.');
};

const InformeEconomicoPDF = ({
    totalIngresosMantenimiento,
    ingresosCuotaExtraData,
    ingresosOtrosData,
    ingresosCobranzaCuotasAtrasadasData,
    ingresosMorasData,
    personalData,
    serviciosData,
    mantenimientosData,  // Añadido para mantenimientos
    administracionData,
    gastosVariosData,
    gastosBancariosData,
    saldoInicialData,
    edificiosData,
    juntaPropietarioData,
    selectedMonth,
    selectedYear,
}) => {
    const totalIngresos = totalIngresosMantenimiento +
        ingresosCuotaExtraData.reduce((total, item) => total + item.monto, 0) +
        ingresosOtrosData.reduce((total, item) => total + item.monto, 0) +
        ingresosCobranzaCuotasAtrasadasData.reduce((total, item) => total + item.monto, 0) +
        ingresosMorasData.reduce((total, item) => total + item.monto, 0);

    const totalEgresos = personalData.reduce((total, item) => total + item.monto, 0) +
        serviciosData.reduce((total, item) => total + item.monto, 0) +
        mantenimientosData.reduce((total, item) => total + item.monto, 0) +  // Añadido para el total de mantenimientos
        administracionData.reduce((total, item) => total + item.monto, 0) +
        gastosVariosData.reduce((total, item) => total + item.monto, 0) +
        gastosBancariosData.reduce((total, item) => total + item.monto, 0);

    const totalSaldoInicial = saldoInicialData.reduce((total, item) => total + item.monto, 0);
    const saldoFinal = totalSaldoInicial + totalIngresos - totalEgresos;

    const date = new Date(selectedYear, selectedMonth - 1);
    const isValidDate = isValid(date);

    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.headerContainer}>
                    <View style={styles.leftColumn}>
                        <Text style={styles.detailText}>Edificio: {edificiosData.nombreEdificio}</Text>
                        <Text style={styles.detailText}>{edificiosData.direccionEdificio}</Text>
                        <Text style={styles.detailText}>{edificiosData.distritoEdificio}</Text>
                        <Text style={styles.detailText}>{juntaPropietarioData.nombreJuntaPropietario}</Text>
                    </View>
                </View>
                <View style={{ alignSelf: 'center' }}>
                    <Text style={styles.mainHeader}>
                        Informe Económico - {isValidDate ? capitalize(format(date, 'LLLL', { locale: es })) : 'Fecha inválida'} {selectedYear}
                    </Text>
                </View>

                <View style={styles.sectionHeader}>
                    <View style={styles.sectionTitleContainer}>
                        <Text style={styles.sectionTitle}>Saldo Inicial</Text>
                        <Text style={styles.sectionValue}>{formatNumber(totalSaldoInicial)}</Text>
                    </View>
                </View>

                <View style={styles.sectionHeader}>
                    <Text style={styles.ingresosTitle}>Ingresos</Text>
                    <View style={styles.listItem}>
                        <Text style={styles.itemDetailConcept}>Total Recibos de Mantenimiento</Text>
                        <Text style={styles.itemDetail}>{formatNumber(totalIngresosMantenimiento)}</Text>
                    </View>

                    <View style={styles.listItem}>
                        <Text style={styles.itemDetailConcept}>Cobranza Cuotas Atrasadas</Text>
                        <Text style={styles.itemDetail}>{formatNumber(ingresosCobranzaCuotasAtrasadasData.reduce((total, item) => total + item.monto, 0))}</Text>
                    </View>

                    <View style={styles.listItem}>
                        <Text style={styles.itemDetailConcept}>Moras</Text>
                        <Text style={styles.itemDetail}>{formatNumber(ingresosMorasData.reduce((total, item) => total + item.monto, 0))}</Text>
                    </View>

                    <View style={styles.listItem}>
                        <Text style={styles.itemDetailConcept}>Cuota Extra Ordinaria</Text>
                        <Text style={styles.itemDetail}>{formatNumber(ingresosCuotaExtraData.reduce((total, item) => total + item.monto, 0))}</Text>
                    </View>

                    <View style={styles.listItem}>
                        <Text style={styles.itemDetailConcept}>Otros Ingresos</Text>
                        <Text style={styles.itemDetail}>{formatNumber(ingresosOtrosData.reduce((total, item) => total + item.monto, 0))}</Text>
                    </View>

                    <Text style={styles.sumaIngresos}>Total Ingresos: {formatNumber(totalIngresos)}</Text>
                </View>

                <View style={styles.sectionHeader}>
                    <Text style={styles.egresosTitle}>Egresos</Text>
                    <Text style={styles.subHeader}>Remuneraciones</Text>
                    {personalData.map((item, index) => (
                        <View key={index} style={styles.listItem}>
                            <Text style={styles.itemDetailConcept}>{item.nombre} ({item.tipoPersonal})</Text>
                            <Text style={styles.itemDetail}>{formatDate(item.fecha)}</Text>
                            <Text style={styles.itemDetail}>{formatNumber(item.monto)}</Text>
                        </View>
                    ))}
                    <Text style={styles.subHeader}>Servicios</Text>
                    {serviciosData.map((item, index) => (
                        <View key={index} style={styles.listItem}>
                            <Text style={styles.itemDetailConcept}>{item.concepto}</Text>
                            <Text style={styles.itemDetail}>{formatDate(item.fecha)}</Text>
                            <Text style={styles.itemDetail}>{formatNumber(item.monto)}</Text>
                        </View>
                    ))}
                    <Text style={styles.subHeader}>Mantenimientos</Text>  {/* Nueva sección de mantenimientos */}
                    {mantenimientosData.map((item, index) => (
                        <View key={index} style={styles.listItem}>
                            <Text style={styles.itemDetailConcept}>{item.concepto}</Text>
                            <Text style={styles.itemDetail}>{formatDate(item.fecha)}</Text>
                            <Text style={styles.itemDetail}>{formatNumber(item.monto)}</Text>
                        </View>
                    ))}
                    <Text style={styles.subHeader}>Administración</Text>
                    {administracionData.map((item, index) => (
                        <View key={index} style={styles.listItem}>
                            <Text style={styles.itemDetailConcept}>{item.concepto}</Text>
                            <Text style={styles.itemDetail}>{formatDate(item.fecha)}</Text>
                            <Text style={styles.itemDetail}>{formatNumber(item.monto)}</Text>
                        </View>
                    ))}
                    <Text style={styles.subHeader}>Gastos Varios</Text>
                    {gastosVariosData.map((item, index) => (
                        <View key={index} style={styles.listItem}>
                            <Text style={styles.itemDetailConcept}>{item.concepto}</Text>
                            <Text style={styles.itemDetail}>{formatDate(item.fecha)}</Text>
                            <Text style={styles.itemDetail}>{formatNumber(item.monto)}</Text>
                        </View>
                    ))}
                    <Text style={styles.subHeader}>Gastos Bancarios</Text>
                    {gastosBancariosData.map((item, index) => (
                        <View key={index} style={styles.listItem}>
                            <Text style={styles.itemDetailConcept}>{item.concepto}</Text>
                            <Text style={styles.itemDetail}>{formatDate(item.fecha)}</Text>
                            <Text style={styles.itemDetail}>{formatNumber(item.monto)}</Text>
                        </View>
                    ))}
                    <Text style={styles.sumaEgresos}>Total Egresos: {formatNumber(totalEgresos)}</Text>
                </View>

                <View style={styles.footerContainer}>
                    <View style={styles.footer}>
                        <Text style={styles.footerText}>Saldo Final:</Text>
                        <Text style={styles.footerAmount}>{formatNumber(saldoFinal)}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default InformeEconomicoPDF;
