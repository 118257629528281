import React, { useEffect, useState, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import { ColorModeContext, tokens } from '../../theme';
import RegistroComunidad from './RegistroComunidad';
import ConfirmDeleteModal from '../ConfirmDeleteModal/ConfirmDeleteModal';
import { useTheme, Button, Card, CardContent, CardMedia, Typography, Modal, Box, Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const Cards = () => {
  const { id_empresa } = useParams();
  const [buildingList, setBuildingList] = useState([]);
  const [openComunidad, setOpenComunidad] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [currentBuildingToDelete, setCurrentBuildingToDelete] = useState(null);

  const colorMode = useContext(ColorModeContext);
  const theme = useTheme();
  const mode = colorMode ? colorMode.mode : 'light';
  const colors = tokens(mode);

  const nombre_empresa = localStorage.getItem('nombre_empresa') || '';

  useEffect(() => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    if (token) {
      fetchBuildings(token, role);
    } else {
      console.error('Usuario no autenticado');
    }
  }, [id_empresa]);

  const fetchBuildings = async (token, role) => {
    try {
      let response;
      if (role === "ROLE_SUPER_ADMIN") {
        response = await axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios`, {
          headers: { Authorization: `Bearer ${token}` },
        });
      } else if (role === "ROLE_SUB_ADMIN") {
        response = await axios.get(`https://app.propia.pe/apiRest/usuarios/edificiosAsignados`, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }
      if (response.status === 200) {
        setBuildingList(response.data);
      } else {
        console.error('No se pudo obtener la lista de edificios');
      }
    } catch (error) {
      console.error('Error al obtener la lista de edificios:', error);
    }
  };

  const handleOpenConfirmDelete = (id) => {
    setCurrentBuildingToDelete(id);
    setConfirmDeleteOpen(true);
  };

  const handleCloseConfirmDelete = () => {
    setConfirmDeleteOpen(false);
    setCurrentBuildingToDelete(null);
  };

  const handleDeleteBuilding = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/eliminar/${currentBuildingToDelete}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        setBuildingList(prevList => prevList.filter(building => building.id_edificio !== currentBuildingToDelete));
        handleCloseConfirmDelete();
      } else {
        console.error('No se pudo eliminar el edificio. Código de estado:', response.status);
      }
    } catch (error) {
      console.error('Error al eliminar el edificio:', error.response ? error.response.data : error.message);
    }
  };

  const handleAddComunidad = (newBuilding) => {
    setBuildingList(prevList => [...prevList, newBuilding]);
    setOpenComunidad(false);
  };

  return (
    <Box sx={{ bgcolor: theme.palette.background.default, p: 2 }}>
      <Header title="Lista de los Edificios" subtitle="Aquí encontrarás todos los edificios que tengas a cargo." />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
        <Button variant="contained" color="secondary" onClick={() => setOpenComunidad(true)} style={{ backgroundColor: colors.blueAccent[700] }}>
          Nueva Comunidad
        </Button>
      </Box>
      <Box sx={{ height: '65vh', maxHeight: '68vh', overflowY: 'auto', overflowX: 'hidden' }}>
        <Grid container spacing={3}>
          {buildingList.map((building, index) => (
            <Grid item xs={12} sm={6} md={4} key={building.id_edificio || index}>
              <Card
                elevation={3}
                sx={{
                  bgcolor: mode === 'light' ? '#fff' : colors.blueAccent[700],
                  borderRadius: 2,
                  overflow: 'hidden',
                  '&:hover .deleteIcon': { opacity: 1 },
                  position: 'relative',
                  color: 'white',
                }}
              >
                <Box sx={{ position: 'relative' }}>
                  <CardMedia
                    component="img"
                    height="180px"
                    image={building.url_imagen_edificio || 'https://via.placeholder.com/300x180'}
                    alt={building.nombre_edificio}
                    sx={{ objectFit: 'cover' }}
                  />
                  <IconButton
                    aria-label="delete"
                    className="deleteIcon"
                    onClick={() => handleOpenConfirmDelete(building.id_edificio)}
                    sx={{ position: 'absolute', right: 8, top: 8, color: 'white', opacity: 0, transition: 'opacity 0.3s' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
                <Link
                  to={`/empresa/${id_empresa}/${encodeURIComponent(nombre_empresa)}/edificios/${building.id_edificio}/${encodeURIComponent(building.nombre_edificio)}/dashboard`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  onClick={() => {
                    sessionStorage.setItem('currentBuilding', JSON.stringify(building));
                    sessionStorage.setItem('id_edificio', building.id_edificio);
                    sessionStorage.setItem('nombre_edificio', building.nombre_edificio);
                  }}
                >
                  <CardContent sx={{ color: 'white' }}>
                    <Typography variant="h5" align="center" gutterBottom>
                      {building.nombre_edificio}
                    </Typography>
                    <Typography variant="h6" align="center" color={theme.palette.text.primary} gutterBottom>
                      <LocationOnIcon sx={{ verticalAlign: 'bottom', mr: 0.5 }} />
                      {building.distrito_edificio}, {building.direccion_edificio}
                    </Typography>
                    <Typography variant="body1" align="center" color={theme.palette.text.secondary} gutterBottom>
                      Teléfono: {building.telefono_edificio}
                    </Typography>
                  </CardContent>
                </Link>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Modal open={openComunidad} onClose={() => setOpenComunidad(false)}>
        <Box sx={{ width: '80%', mx: 'auto', mt: 5, p: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
          <RegistroComunidad id_empresa={id_empresa} onAddBuilding={handleAddComunidad} onClose={() => setOpenComunidad(false)} />
        </Box>
      </Modal>
      <ConfirmDeleteModal
        open={confirmDeleteOpen}
        onClose={handleCloseConfirmDelete}
        onConfirm={handleDeleteBuilding}
        buildingName={buildingList.find(building => building.id_edificio === currentBuildingToDelete)?.nombre_edificio || 'este edificio'}
      />
    </Box>
  );
};

export default Cards;
