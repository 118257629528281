import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";

const PedidosDialog = ({ open, onClose, egresosData = [], loading }) => {
  // Formatea valores numéricos como moneda
  const formatCurrency = (value) =>
    new Intl.NumberFormat("es-PE", {
      style: "currency",
      currency: "PEN",
    }).format(value);

  // Mostrar spinner mientras se cargan los datos
  if (loading) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogContent>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
          >
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth scroll="paper">
      <DialogTitle align="center">Lista de Egresos del Mes</DialogTitle>
      <DialogContent dividers>
        <Box mb={2}>
          <Typography variant="h6" gutterBottom>
            Detalle de Egresos
          </Typography>
          {egresosData.length === 0 ? (
            <Typography variant="body1" color="textSecondary">
              No hay egresos para el mes seleccionado.
            </Typography>
          ) : (
            <Table size="small">
              <TableBody>
                {egresosData.map((egreso, index) => (
                  <TableRow key={index}>
                    <TableCell>{egreso.concepto}</TableCell>
                    <TableCell align="right">
                      {egreso.fecha
                        ? format(parseISO(egreso.fecha), "dd/MM/yyyy")
                        : "Fecha no disponible"}
                    </TableCell>
                    <TableCell align="right">
                      {formatCurrency(egreso.monto)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>

        {egresosData.some((egreso) => egreso.url) && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Imágenes de los Comprobantes
            </Typography>
            {egresosData.map(
              (egreso, index) =>
                egreso.url && (
                  <Box key={index} mb={2}>
                    <Typography variant="subtitle1">
                      {`${index + 1}. ${egreso.concepto}`}
                    </Typography>
                    <Box
                      component="img"
                      src={egreso.url}
                      alt={`Comprobante de ${egreso.concepto}`}
                      sx={{
                        width: "100%",
                        maxHeight: "400px",
                        objectFit: "contain",
                        border: "1px solid #ccc",
                      }}
                    />
                  </Box>
                )
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PedidosDialog;
