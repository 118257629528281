import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTheme, Snackbar, Button, Dialog, Box, Alert } from '@mui/material';
import RegistroAsignacionMasivo from './FormularioAsignacion';
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const Asignacion = () => {
  const { id_empresa, id_edificio } = useParams();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    fetchData();
  }, [id_empresa, id_edificio]);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');

      if (!token) {
        setError("No se encontró el token de autenticación.");
        return;
      }

      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.get(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/asignaciones`, 
        { headers }
      );
      const asignacionData = Array.isArray(response.data) ? response.data : [];

      const [propietariosResponse, departamentosResponse, cocherasResponse, depositosResponse] = await Promise.all([
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/propietarios`, { headers }),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos`, { headers }),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/cocheras`, { headers }),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/depositos`, { headers })
      ]);

      const propietarios = propietariosResponse.data || [];
      const departamentos = departamentosResponse.data || [];
      const cocheras = cocherasResponse.data || [];
      const depositos = depositosResponse.data || [];

      const combinedData = asignacionData.map((asignacion, index) => {
        const propietario = propietarios.find(p => p.id_propietario === asignacion.id_propietario);
        const departamento = departamentos.find(d => d.id_departamento === asignacion.id_departamento);
        const cochera = cocheras.find(c => c.id_cochera === asignacion.id_cochera);
        const deposito = depositos.find(d => d.id_deposito === asignacion.id_deposito);

        return {
          ...asignacion,
          id: index + 1,
          nombre_propietario: propietario ? propietario.nombre_propietario : "N/A",
          apellido_propietario: propietario ? propietario.apellido_propietario : "N/A",
          numero_departamento: departamento ? departamento.numero_departamento : "N/A",
          numero_cochera: cochera ? cochera.numero_cochera : "N/A",
          numero_deposito: deposito ? deposito.numero_deposito : "N/A",
          email_propietario: propietario ? propietario.email_propietario : "N/A",
          celular_propietario: propietario ? propietario.celular_propietario : "N/A",
        };
      });

      setData(combinedData);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
      if (error.response && error.response.status === 403) {
        setError("No tienes permiso para acceder a estos datos. Verifica tu autenticación.");
      } else {
        setError("Hubo un error al obtener los datos.");
      }
    }
  };

  const handleAddSuccess = () => {
    fetchData();
  };

  return (
    <Box sx={{ p: 3 }}>
      <Header title="Asignación" subtitle="Lista de las asignaciones del edificio" />

      {error && (
        <Snackbar open={true} autoHideDuration={6000} onClose={() => setError(null)}>
          <Alert onClose={() => setError(null)} severity="error" sx={{ backgroundColor: colors.red[600] || 'red' }}>
            {error}
          </Alert>
        </Snackbar>
      )}

      <Box display="flex" justifyContent="space-between" alignItems="center" m="0 0 20px 0">
        <Button
          variant="contained"
          onClick={() => setOpen(true)}
          sx={{ backgroundColor: colors.blueAccent[700], '&:hover': { backgroundColor: colors.blueAccent[600] } }}
        >
          Agregar Asignación
        </Button>
      </Box>

      <Box m="10px 0 0 0" height="70vh">
        <DataGrid
          rows={data}
          columns={[
            { field: "numero_departamento", headerName: "Número Departamento", flex: 1 },
            { field: "numero_cochera", headerName: "Número Cochera", flex: 1 },
            { field: "numero_deposito", headerName: "Número Depósito", flex: 1 },
            { field: "nombre_propietario", headerName: "Nombre del Propietario", flex: 1 },
            { field: "apellido_propietario", headerName: "Apellido del Propietario", flex: 1 },
            { field: "email_propietario", headerName: "Email", flex: 1 },
            { field: "celular_propietario", headerName: "Teléfono", flex: 1 },
          ]}
          components={{
            Toolbar: GridToolbar,
          }}
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.blueAccent[700],
              borderBottom: 'none',
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.primary[400],
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
              backgroundColor: colors.blueAccent[700],
            },
            '& .MuiCheckbox-root': {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        />
      </Box>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
        <RegistroAsignacionMasivo idEdificio={id_edificio} onAddSuccess={handleAddSuccess} />
      </Dialog>
    </Box>
  );
};

export default Asignacion;
