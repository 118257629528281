import React from 'react';
import { Box, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts';

const FinancialBarChart = ({ monthlyData }) => {
  const series = [
    { name: "Ingresos", data: monthlyData.map(month => month.ingresos) },
    { name: "Egresos", data: monthlyData.map(month => month.egresos) }
  ];

  return (
    <Box sx={{ padding: '20px', borderRadius: '8px' }}>
      <Typography variant="h6" gutterBottom>Ingresos y Egresos</Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
          <Box sx={{ width: '20px', height: '20px', backgroundColor: '#00BFFF', marginRight: '5px' }}></Box>
          <Typography>Ingresos</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '20px', height: '20px', backgroundColor: '#FF6347', marginRight: '5px' }}></Box>
          <Typography>Egresos</Typography>
        </Box>
      </Box>
      <BarChart
        series={series}
        height={300}
        xAxis={[
          {
            data: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            scaleType: 'band'
          }
        ]}
        yAxis={[
          {
            position: 'left',
            format: '{value} S/',
            scaleType: 'linear',
            nice: true,
          }
        ]}
        margin={{ top: 10, bottom: 50, left: 40, right: 10 }}
        tooltip={{
          formatter: ({ seriesName, value }) => `${seriesName}: S/ ${value.toFixed(2)}`
        }}
        colors={['#00BFFF', '#FF6347']}  // Celeste y rojo
      />
    </Box>
  );
}

export default FinancialBarChart;
