import React from 'react';
import { Box, Typography } from "@mui/material";

const TotalImportes = ({
  filteredData,
  colors,
  customStyles,
  customTextStyles = {}, // Estilos personalizados para el texto "Egresos"
  customValueStyles = {}, // Estilos personalizados para el valor numérico
  customText = "Egresos" // Texto personalizable
}) => {
  // Calcula el total sumando 'importe_gasto_mensual' de cada elemento en 'filteredData'
  const totalImportes = filteredData.reduce(
    (total, gasto) => total + (parseFloat(gasto.monto) || 0), 0  // Changed to 'monto' from 'importe_gasto_mensual'
  );
  

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      p={1}
      bgcolor={colors.blueAccent[700]} // Utiliza el color de acento azul definido
      color="white" // El color del texto es blanco
      borderRadius={2}
      mt={2}
      width="150px" // Ancho fijo, considera ajustarlo según sea necesario
      {...customStyles} // Aplica estilos personalizados
    >
      <Typography variant="subtitle1" style={customTextStyles}>{customText}</Typography>
      <Typography variant="h6" style={customValueStyles}>S/ {totalImportes.toFixed(2)}</Typography>
    </Box>
  );
};

export default TotalImportes;
