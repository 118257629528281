import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Snackbar, Alert } from '@mui/material';
import axios from 'axios';

const EditarInformacionEdificio = ({
  id_empresa,
  id_edificio,
  building,
  cuentasBancarias,
  juntaPropietarios,
  tipoCuentas = [],
  onClose,
  onSave,
  onError,
}) => {
  const [formData, setFormData] = useState({
    nombre_edificio: '',
    tipo_edificio: '',
    direccion_edificio: '',
    distrito_edificio: '',
    telefono_edificio: '',
    fecha_construccion: '',
    cantidad_departamentos: 0,
    cantidad_sotanos: 0,
    cantidad_cocheras: 0,
    cantidad_pisos: 0,
    estado: true,
  });

  const [cuentasBancariasData, setCuentasBancariasData] = useState([]);
  const [juntaPropietariosData, setJuntaPropietariosData] = useState({ nombre_junta_propietario: '', ruc: '' });
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [openAlert, setOpenAlert] = useState(false);
  const [error, setError] = useState(null);

  // Cargar datos iniciales al abrir el modal
  useEffect(() => {
    setFormData({
      nombre_edificio: building?.nombreEdificio || '',
      tipo_edificio: building?.tipoPropiedad || '',
      direccion_edificio: building?.direccionEdificio || '',
      distrito_edificio: building?.distritoEdificio || '',
      telefono_edificio: building?.telefono || '',
      fecha_construccion: building?.fechaConstruccion || '',
      cantidad_departamentos: building?.cantidadDepartamentos || 0,
      cantidad_sotanos: building?.cantidadSotanos || 0,
      cantidad_cocheras: building?.cantidadCocheras || 0,
      cantidad_pisos: building?.cantidadPisos || 0,
      estado: building?.estado !== undefined ? building.estado : true,
    });

    setCuentasBancariasData(cuentasBancarias.length > 0 ? cuentasBancarias : [{ nombre_banco: '', id_tipo_cuenta: '', numero_cuenta: '', numero_cuenta_cci: '' }]);
    setJuntaPropietariosData(juntaPropietarios || { nombre_junta_propietario: '', ruc: '' });
  }, [building, cuentasBancarias, juntaPropietarios]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCuentasChange = (index, field, value) => {
    const updatedCuentas = [...cuentasBancariasData];
    updatedCuentas[index] = { ...updatedCuentas[index], [field]: value };
    setCuentasBancariasData(updatedCuentas);
  };

  const handleJuntaChange = (field, value) => {
    setJuntaPropietariosData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    try {
      let token = localStorage.getItem('token');
      if (token && !token.startsWith('Bearer ')) {
        token = `Bearer ${token}`;
      }
      const headers = { Authorization: token };

      // Modificar el edificio
      const edificioResponse = await axios.put(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/modificar`,
        formData,
        { headers }
      );

      if (edificioResponse.status !== 200) {
        throw new Error('Error al modificar el edificio');
      }

      const updatedCuentasBancarias = [];

      // Modificar o insertar cuentas bancarias
      for (let cuenta of cuentasBancariasData) {
        const cuentaBancaria = {
          ...cuenta,
          id_edificio,
        };

        let cuentaResponse;
        if (cuenta.id_cuenta_bancaria) {
          cuentaResponse = await axios.put(
            `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/cuentas_bancarias/modificar`,
            cuentaBancaria,
            { headers }
          );
        } else {
          cuentaResponse = await axios.post(
            `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/cuentas_bancarias/insertar`,
            cuentaBancaria,
            { headers }
          );
        }

        if (cuentaResponse.status !== 200 && cuentaResponse.status !== 201) {
          throw new Error('Error al modificar/insertar la cuenta bancaria');
        }

        updatedCuentasBancarias.push(cuentaResponse.data);
      }

      // Modificar la junta de propietarios
      const juntaResponse = await axios.put(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/junta_propietarios/modificar`,
        {
          ...juntaPropietariosData,
          id_edificio,
        },
        { headers }
      );

      if (juntaResponse.status !== 200) {
        throw new Error('Error al modificar la junta de propietarios');
      }

      setAlertMessage('Edificio editado correctamente');
      setAlertSeverity('success');
      setOpenAlert(true);
      onSave({
        ...formData,
        cuentasBancarias: updatedCuentasBancarias,
        juntaPropietarios: juntaResponse.data,
      });
    } catch (error) {
      console.error('Error al modificar los datos:', error);
      setAlertMessage('Hubo un error al editar el edificio: ' + error.message);
      setAlertSeverity('error');
      setOpenAlert(true);
      setError(error.message);
      onError(error.message);
    }
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <Box
      sx={{
        maxWidth: '100%',
        height: { xs: '100vh', sm: '80vh', md: '70vh', lg: '60vh' },
        overflowY: 'auto',
        padding: '1rem',
        bgcolor: 'background.paper',
        borderRadius: 2,
      }}
    >
      <Typography variant="h5" gutterBottom>
        Editar Información del Edificio
      </Typography>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      <TextField
        label="Nombre del Edificio"
        name="nombre_edificio"
        value={formData.nombre_edificio}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Tipo de Edificio"
        name="tipo_edificio"
        value={formData.tipo_edificio}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Dirección"
        name="direccion_edificio"
        value={formData.direccion_edificio}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Distrito"
        name="distrito_edificio"
        value={formData.distrito_edificio}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Teléfono"
        name="telefono_edificio"
        value={formData.telefono_edificio}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Fecha de Construcción"
        name="fecha_construccion"
        value={formData.fecha_construccion}
        onChange={handleChange}
        type="date"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        label="Cantidad de Departamentos"
        name="cantidad_departamentos"
        value={formData.cantidad_departamentos}
        onChange={handleChange}
        fullWidth
        margin="normal"
        type="number"
      />
      <TextField
        label="Cantidad de Sótanos"
        name="cantidad_sotanos"
        value={formData.cantidad_sotanos}
        onChange={handleChange}
        fullWidth
        margin="normal"
        type="number"
      />
      <TextField
        label="Cantidad de Cocheras"
        name="cantidad_cocheras"
        value={formData.cantidad_cocheras}
        onChange={handleChange}
        fullWidth
        margin="normal"
        type="number"
      />
      <TextField
        label="Cantidad de Pisos"
        name="cantidad_pisos"
        value={formData.cantidad_pisos}
        onChange={handleChange}
        fullWidth
        margin="normal"
        type="number"
      />
      <TextField
        label="Estado"
        name="estado"
        select
        value={formData.estado}
        onChange={handleChange}
        fullWidth
        margin="normal"
        SelectProps={{
          native: true,
        }}
      >
        <option value={true}>Activo</option>
        <option value={false}>Inactivo</option>
      </TextField>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Información de Cuentas Bancarias
        </Typography>
        {cuentasBancariasData.map((cuenta, index) => (
          <Box key={index} sx={{ mb: 3 }}>
            <TextField
              label="Nombre del Banco"
              value={cuenta.nombre_banco}
              fullWidth
              margin="normal"
              onChange={(e) => handleCuentasChange(index, 'nombre_banco', e.target.value)}
            />
            <TextField
              select
              value={cuenta.id_tipo_cuenta}
              fullWidth
              margin="normal"
              onChange={(e) => handleCuentasChange(index, 'id_tipo_cuenta', e.target.value)}
              SelectProps={{
                native: true,
              }}
            >
              <option value="" disabled>
                Selecciona el tipo de cuenta
              </option>
              {tipoCuentas.map((tipo) => (
                <option key={tipo.id_tipo_cuenta} value={tipo.id_tipo_cuenta}>
                  {tipo.nombre_tipo_cuenta}
                </option>
              ))}
            </TextField>
            <TextField
              label="Número de Cuenta"
              value={cuenta.numero_cuenta}
              fullWidth
              margin="normal"
              onChange={(e) => handleCuentasChange(index, 'numero_cuenta', e.target.value)}
            />
            <TextField
              label="Número de Cuenta CCI"
              value={cuenta.numero_cuenta_cci}
              fullWidth
              margin="normal"
              onChange={(e) => handleCuentasChange(index, 'numero_cuenta_cci', e.target.value)}
            />
          </Box>
        ))}
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Junta de Propietarios
        </Typography>
        <TextField
          label="Nombre de la Junta de Propietarios"
          value={juntaPropietariosData?.nombre_junta_propietario || ''}
          fullWidth
          margin="normal"
          onChange={(e) => handleJuntaChange('nombre_junta_propietario', e.target.value)}
        />
        <TextField
          label="RUC"
          value={juntaPropietariosData?.ruc || ''}
          fullWidth
          margin="normal"
          onChange={(e) => handleJuntaChange('ruc', e.target.value)}
        />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Guardar
        </Button>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancelar
        </Button>
      </Box>

      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditarInformacionEdificio;
