import React, { useState, useMemo } from 'react';
import { Box, CircularProgress, Select, MenuItem, InputLabel, Button, Typography, useTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Alert } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import useFetchIngresos from '../Hook/HookIngresos';
import useFetchData from '../Hook/hookEgresos';
import useFetchSaldoInicial from '../Hook/HookSaldoInicial';
import { format, parseISO, isValid } from 'date-fns';
import { tokens } from '../../theme';

const CierreDeMes = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { id_empresa, id_edificio } = useParams(); // Obtener id_empresa e id_edificio desde la URL
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [alert, setAlert] = useState({ open: false, type: '', message: '' });

    const { loadingSaldoInicial, saldoInicialData } = useFetchSaldoInicial(id_empresa, id_edificio);
    const { loading: loadingIngresos, ingresosRecibosData, ingresosOtrosData, ingresosCuotaExtraData, ingresosMorasData } = useFetchIngresos(selectedMonth, selectedYear, id_empresa, id_edificio);
    const { loading: loadingEgresos, administracionData, serviciosData, personalData, pagosPersonalData, gastosBancariosData, gastosVariosData, mantenimientosData } = useFetchData(selectedMonth, selectedYear, id_empresa, id_edificio);

    const calculateTotal = (data) => {
        return data.reduce((total, item) => total + (item.monto || 0), 0);
    };

    const filterByDate = (dataArray) => {
        return dataArray.filter(item => {
            const itemDate = parseISO(item.fecha);
            return isValid(itemDate) &&
                itemDate.getMonth() + 1 === selectedMonth &&
                itemDate.getFullYear() === selectedYear;
        });
    };

    const filteredSaldoInicial = useMemo(() => {
        const filteredData = filterByDate(saldoInicialData);
        return calculateTotal(filteredData);
    }, [saldoInicialData, selectedMonth, selectedYear]);

    const totalIngresos = calculateTotal(filterByDate([...ingresosRecibosData, ...ingresosOtrosData, ...ingresosCuotaExtraData, ...ingresosMorasData]));
    const totalEgresos = calculateTotal(filterByDate([...administracionData, ...serviciosData, ...personalData, ...pagosPersonalData, ...gastosBancariosData, ...gastosVariosData, ...mantenimientosData]));
    const saldoFinal = filteredSaldoInicial + totalIngresos - totalEgresos;

    const handleCloseMonth = async () => {
        try {
            const nextMonth = selectedMonth === 12 ? 1 : selectedMonth + 1;
            const nextYear = selectedMonth === 12 ? selectedYear + 1 : selectedYear;
            const token = localStorage.getItem('token'); // Obtener el token de localStorage

            const response = await fetch(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/saldo_inicial/insertar`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`, // Incluir token en el header
                },
                body: JSON.stringify({
                    id_edificio,
                    descripcion_saldo_inicial: `Saldo inicial de ${nextMonth}/${nextYear}`,
                    fecha_saldo_inicial: `${nextYear}-${String(nextMonth).padStart(2, '0')}-01`,
                    monto_saldo_inicial: saldoFinal,
                }),
            });

            if (response.ok) {
                setAlert({ open: true, type: 'success', message: 'El mes ha sido cerrado y el saldo inicial del próximo mes ha sido actualizado.' });
            } else {
                setAlert({ open: true, type: 'error', message: 'Hubo un error al cerrar el mes.' });
            }
        } catch (error) {
            console.error('Error al cerrar el mes:', error);
            setAlert({ open: true, type: 'error', message: 'Ocurrió un error al intentar cerrar el mes.' });
        }
    };

    const handleConfirmCloseMonth = () => {
        setConfirmDialogOpen(true);
    };

    const handleCancelClose = () => {
        setConfirmDialogOpen(false);
    };

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
        handleCloseMonth();
    };

    const handleCloseAlert = () => {
        setAlert({ ...alert, open: false });
    };

    const columnsIngresos = [
        { field: 'numero_departamento', headerName: 'Nº Departamento', flex: 1, minWidth: 130 },
        { field: 'fecha', headerName: 'Fecha de Pago', flex: 1, minWidth: 180, valueFormatter: ({ value }) => format(parseISO(value), 'dd/MM/yyyy') },        
        { field: 'metodo_pago', headerName: 'Método de Pago', flex: 1, minWidth: 150 },
        { field: 'tipoIngreso', headerName: 'Tipo de Ingreso', flex: 1, minWidth: 150 },
        { field: 'monto', headerName: 'Monto', flex: 1, minWidth: 110 },
    ];

    const columnsEgresos = [        
        { field: 'tipo', headerName: 'Tipo', flex: 1, minWidth: 150 },
        { field: 'fecha', headerName: 'Fecha', flex: 1, minWidth: 180, valueFormatter: ({ value }) => format(parseISO(value), 'dd/MM/yyyy') },        
        { field: 'concepto', headerName: 'Concepto', flex: 1, minWidth: 150 },
        { field: 'monto', headerName: 'Monto', flex: 1, minWidth: 110 },
    ];

    const monthOptions = Array.from({ length: 12 }, (_, index) => ({
        value: index + 1,
        label: new Date(2000, index).toLocaleString('default', { month: 'long' }),
    }));

    const yearOptions = Array.from({ length: 10 }, (_, index) => ({
        value: new Date().getFullYear() - index,
        label: new Date().getFullYear() - index,
    }));

    return (
        <Box m=" 0px 20px">
            <Typography variant="h3" gutterBottom color={colors.grey[100]}>
                Cierre de Mes
            </Typography>

            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} flexWrap="wrap">
                <Box display="flex" gap="1rem" alignItems="center" flexWrap="wrap">
                    <Box display="flex" gap="0.5rem" alignItems="center">
                        <InputLabel>MES:</InputLabel>
                        <Select
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                            size="small"
                            sx={{ minWidth: 120 }}
                        >
                            {monthOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>

                    <Box display="flex" gap="0.5rem" alignItems="center">
                        <InputLabel>AÑO:</InputLabel>
                        <Select
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                            size="small"
                            sx={{ minWidth: 120 }}
                        >
                            {yearOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConfirmCloseMonth}
                    style={{ backgroundColor: colors.blueAccent[700] }}
                    disabled={loadingSaldoInicial || loadingIngresos || loadingEgresos}
                    sx={{ mt: { xs: 2, md: 0 } }}  // Adjust margin for responsiveness
                >
                    Cerrar Mes
                </Button>
            </Box>

            <Snackbar
                open={alert.open}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleCloseAlert} severity={alert.type} sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>

            {loadingSaldoInicial || loadingIngresos || loadingEgresos ? (
                <CircularProgress sx={{ display: 'flex', justifyContent: 'center', my: 5 }} />
            ) : (
                <>
                    <Box mb={1} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h5" color={colors.grey[100]}>Saldo Inicial</Typography>
                        <Typography variant="subtitle1" color={colors.grey[100]}>S/.{filteredSaldoInicial.toFixed(2)}</Typography>
                    </Box>

                    <Box mb={4} sx={{ flexGrow: 1, height: 300 }}>
                        <Typography variant="h5" color={colors.grey[100]}>Ingresos</Typography>
                        <DataGrid
                            rows={filterByDate([...ingresosRecibosData, ...ingresosOtrosData, ...ingresosCuotaExtraData, ...ingresosMorasData])}
                            columns={columnsIngresos}
                            autoHeight={false}                           
                            sx={{
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: colors.blueAccent[700],
                                    color: colors.grey[900],
                                    padding: '3px 8px', 
                                },
                                '& .MuiDataGrid-cell': {
                                    color: colors.grey[100],
                                    padding: '3px 8px',
                                },
                                '& .MuiDataGrid-virtualScroller': {
                                    overflowX: 'hidden',
                                    overflowY: 'auto',
                                },
                                '& .MuiDataGrid-footerContainer': {  
                                    display: 'none',
                                },
                                '& .MuiTablePagination-root': {
                                    color: theme.palette.mode === 'light' ? colors.grey[900] : colors.grey[100],
                                },
                            }}
                        />

                        <Typography variant="subtitle1" align="right" color={colors.grey[100]}>Total Ingresos: S/.{totalIngresos.toFixed(2)}</Typography>
                    </Box>

                    <Box mb={4} sx={{ flexGrow: 1, height: 300 }}>
                        <Typography variant="h5" color={colors.grey[100]}>Egresos</Typography>
                        <DataGrid
                            rows={filterByDate([...administracionData, ...serviciosData, ...personalData, ...pagosPersonalData, ...gastosBancariosData, ...gastosVariosData, ...mantenimientosData])}
                            columns={columnsEgresos}
                            autoHeight={false}
                            pagination={false}
                            sx={{
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: colors.blueAccent[700],
                                    color: colors.grey[900],
                                },
                                '& .MuiDataGrid-cell': {
                                    color: colors.grey[100],
                                },
                                '& .MuiDataGrid-virtualScroller': {
                                    overflowX: 'hidden',
                                    overflowY: 'auto',
                                },
                                '& .MuiDataGrid-footerContainer': {  
                                    display: 'none',
                                },
                                '& .MuiTablePagination-root': {
                                    color: theme.palette.mode === 'light' ? colors.grey[900] : colors.grey[100],
                                },
                            }}
                        />
                        <Typography variant="subtitle1" align="right" color={colors.grey[100]}>Total Egresos: S/.{totalEgresos.toFixed(2)}</Typography>
                    </Box>

                    <Typography variant="h5" align="right" gutterBottom color={colors.grey[100]} mt={7}>
                        Saldo Final: S/.{saldoFinal.toFixed(2)}
                    </Typography>
                </>
            )}

            <Dialog
                open={confirmDialogOpen}
                onClose={handleCancelClose}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">Confirmar Cierre de Mes</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        ¿Estás seguro de que deseas cerrar el mes? El saldo final será tomado como el saldo inicial para el siguiente mes.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelClose} color="primary">Cancelar</Button>
                    <Button onClick={handleConfirmDialogClose} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CierreDeMes;
