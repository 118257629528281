import React, { useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import Handsontable from 'handsontable';
import 'handsontable/dist/handsontable.full.css';
import { useTheme } from '@mui/material/styles';
import { tokens } from "../../theme";

const FormularioPorcentajeParticipacionMasivo = ({ hotTableRef, departamentos }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    if (!Array.isArray(departamentos)) {
        console.error('departamentos debe ser un array');
        return;
    }

    if (hotTableRef.current) {
        // Crear los datos iniciales mostrando numero_departamento en lugar de id_departamento
        const initialData = departamentos.map(dep => [
            dep.numero_departamento,
            dep.porcentaje_participacion || ''
        ]);

        const hot = new Handsontable(hotTableRef.current, {
            data: initialData,
            rowHeaders: true,
            colHeaders: ['Número de Departamento', 'Porcentaje de Participación'],
            columns: [
                { type: 'text' },
                { type: 'numeric', numericFormat: { pattern: '0,0[0000]' } }
            ],
            dropdownMenu: true,
            filters: true,
            manualRowResize: true,
            manualColumnResize: true,
            contextMenu: true,
            stretchH: 'all',
            width: '100%',
            height: 500,
            minRows: departamentos.length,
            licenseKey: 'non-commercial-and-evaluation',
            className: 'htCenter htMiddle',
        });

        hotTableRef.current.hotInstance = hot;

        return () => {
            hot.destroy();
        };
    }
}, [departamentos, hotTableRef]);


  return (
    <Box>
      <Box sx={{ overflow: 'auto', height: '500px' }}>
        <div ref={hotTableRef}></div>
      </Box>
    </Box>
  );
};

export default FormularioPorcentajeParticipacionMasivo;
