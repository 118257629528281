import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme, Box, Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, FormControl, InputLabel, Paper, CircularProgress } from "@mui/material";
import { tokens } from "../../theme";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FormularioConceptos from '../formularioConceptos/formularioConceptos';
import FormularioSubconceptos from '../formularioSubConceptos/formulariosSubConceptos';
import EditarConcepto from '../formularioConceptos/EditarConceptos';
import useFetchGastos from '../Hook/HookPresupuestoAnual';

const PresupuestoAnualProyectado = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id_empresa, id_edificio } = useParams(); // Obtener IDs desde los parámetros de la URL
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [showModal, setShowModal] = useState(false);
  const [showSubconceptoModal, setShowSubconceptoModal] = useState(false);
  const [showEditConceptoModal, setShowEditConceptoModal] = useState(false);
  const [conceptoAEditar, setConceptoAEditar] = useState(null);

  // Hook para manejar los datos de gastos
  const { gastos, setGastos, loading, calcularTotalGeneral, calcularGastosMensuales, fetchData } = useFetchGastos(id_empresa, id_edificio, selectedYear);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleOpenSubconceptoModal = () => setShowSubconceptoModal(true);
  const handleCloseSubconceptoModal = () => setShowSubconceptoModal(false);

  const handleEditConcepto = (event, concepto) => {
    event.stopPropagation();
    setConceptoAEditar(concepto);
    setShowEditConceptoModal(true);
  };

  const handleDeleteConcepto = (event, concepto) => {
    event.stopPropagation();
    console.log('Eliminar concepto:', concepto);
    // Implementar lógica de eliminación aquí
  };

  const handleEditSubconcepto = (event, subconcepto) => {
    event.stopPropagation();
    console.log('Editar subconcepto:', subconcepto);
    // Implementar lógica de edición aquí
  };

  const handleDeleteSubconcepto = (event, subconcepto) => {
    event.stopPropagation();
    console.log('Eliminar subconcepto:', subconcepto);
    // Implementar lógica de eliminación aquí
  };

  const handleOpenEditConceptoModal = (concepto) => {
    setConceptoAEditar(concepto);
    setShowEditConceptoModal(true);
  };

  const handleCloseEditConceptoModal = () => {
    setShowEditConceptoModal(false);
    setConceptoAEditar(null);
  };

  const toggleSubconceptos = (index) => {
    const newGastos = [...gastos];
    newGastos[index] = { ...newGastos[index], open: !newGastos[index].open };
    setGastos(newGastos);
  };

  const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

  const totalGeneralMemoized = useMemo(() => calcularTotalGeneral(), [gastos]);
  const gastosMensualesMemoized = useMemo(() => calcularGastosMensuales(), [totalGeneralMemoized]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box m="20px">
      <Typography variant="h2">Presupuesto Anual Proyectado</Typography>      
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
        <Box display="flex" alignItems="center">
          <Typography mr={2}>AÑO:</Typography>
          <FormControl variant="outlined">
            <InputLabel>Año</InputLabel>
            <Select
              value={selectedYear}
              onChange={(event) => setSelectedYear(event.target.value)}
              label="Año"
            >
              {Array.from({ length: 10 }, (_, index) => {
                const year = new Date().getFullYear() - index;
                return <MenuItem key={year} value={year}>{year}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Box>
        <Box display="flex" alignItems="center">
          <Button variant="contained" color="primary" onClick={handleOpenModal} style={{ marginRight: theme.spacing(2), backgroundColor: colors.blueAccent[700] }}>Agregar Concepto</Button>
          <Button variant="contained" color="primary" onClick={handleOpenSubconceptoModal} style={{ backgroundColor: colors.blueAccent[700] }}>Agregar Subconcepto</Button>
        </Box>
      </Box>

      <Paper style={{ maxHeight: '60vh', overflow: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow style={{ backgroundColor: theme.palette.mode === 'dark' ? colors.blueAccent[900] : colors.blueAccent[100] }}>
              <TableCell style={{ width: '72px' }}></TableCell>
              <TableCell>Concepto</TableCell>
              {meses.map(mes => (
                <TableCell key={mes}>{mes}</TableCell>
              ))}
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gastos.map((gasto, index) => (
              <React.Fragment key={index}>
                <TableRow onClick={() => toggleSubconceptos(index)} style={{ cursor: 'pointer', backgroundColor: theme.palette.mode === 'dark' ? colors.blueAccent[900] : colors.blueAccent[200] }}>
                  <TableCell>
                    <EditIcon fontSize="small" onClick={(event) => handleEditConcepto(event, gasto.concepto)} style={{ cursor: 'pointer', marginRight: theme.spacing(1) }} />
                    <DeleteIcon fontSize="small" onClick={(event) => handleDeleteConcepto(event, gasto.concepto)} style={{ cursor: 'pointer' }} />
                  </TableCell>
                  <TableCell>{gasto.concepto}</TableCell>
                  {meses.map((mes) => (
                    <TableCell key={mes}></TableCell>
                  ))}
                  <TableCell>{gasto.subconceptos.reduce((acc, sub) => acc + Object.values(sub.valores).reduce((a, c) => a + c, 0), 0).toFixed(2)}</TableCell>
                </TableRow>
                {gasto.open && gasto.subconceptos.map((subconcepto, subIndex) => (
                  <TableRow key={subIndex}>
                    <TableCell>
                      <EditIcon fontSize="small" onClick={(event) => handleEditSubconcepto(event, subconcepto.nombre)} style={{ cursor: 'pointer', marginRight: theme.spacing(1) }} />
                      <DeleteIcon fontSize="small" onClick={(event) => handleDeleteSubconcepto(event, subconcepto.nombre)} style={{ cursor: 'pointer' }} />
                    </TableCell>
                    <TableCell>{subconcepto.nombre}</TableCell>
                    {meses.map((mes) => (
                      <TableCell key={mes}>{parseFloat(subconcepto.valores[mes]).toFixed(2)}</TableCell>
                    ))}
                    <TableCell>{Object.values(subconcepto.valores).reduce((acc, curr) => acc + curr, 0).toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Box>
          <Typography variant="body1" mb={2}>
            <strong>Total General:</strong> <span style={{ backgroundColor: colors.blueAccent[700], color: theme.palette.primary.contrastText, padding: theme.spacing(0.5, 1), borderRadius: theme.spacing(0.5) }}>{totalGeneralMemoized.toFixed(2)}</span>
          </Typography>
          <Typography variant="body1">
            <strong>Gastos Mensual:</strong> <span style={{ backgroundColor: colors.blueAccent[700], color: theme.palette.primary.contrastText, padding: theme.spacing(0.5, 1), borderRadius: theme.spacing(0.5) }}>{gastosMensualesMemoized.toFixed(2)}</span>
          </Typography>
        </Box>
      </Box>

      {/* Modales de gestión de conceptos y subconceptos */}
      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>Agregar Concepto</DialogTitle>
        <DialogContent>
          <FormularioConceptos id_empresa={id_empresa} id_edificio={id_edificio} onSuccess={fetchData} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleCloseModal}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showSubconceptoModal} onClose={handleCloseSubconceptoModal}>
        <DialogTitle>Agregar Subconcepto</DialogTitle>
        <DialogContent>
          <FormularioSubconceptos id_empresa={id_empresa} id_edificio={id_edificio} onSuccess={fetchData} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleCloseSubconceptoModal}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showEditConceptoModal} onClose={handleCloseEditConceptoModal}>
        <DialogTitle>Editar Concepto</DialogTitle>
        <DialogContent>
          <EditarConcepto
            open={showEditConceptoModal}
            onClose={handleCloseEditConceptoModal}
            concepto={conceptoAEditar}
            onSuccess={() => {
              fetchData(); // Recargar los conceptos tras el éxito
              handleCloseEditConceptoModal(); // Cerrar el modal tras el éxito
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PresupuestoAnualProyectado;
