import React from 'react';
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

const styles = StyleSheet.create({
  receiptContainer: {
    padding: 15,
    backgroundColor: "#f8f9fa",
  },
  receipt: {
    padding: 15,
    marginBottom: 15,
    borderRadius: 5,
  },
  receiptHeader: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 15,
    border: "1px solid #3367b0",
    padding: 10,
    borderRadius: 5,
  },
  headerTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#000",
    textAlign: "left",
  },
  headerDate: {
    fontSize: 12,
    color: "#000",
    textAlign: "left",
    marginBottom: 4,
  },
  headerGrid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  headerSection: {
    width: "45%",
    padding: 3,
  },
  detailValue: {
    fontSize: 9,
    color: "#212529",
    marginBottom: 2,
  },
  detailValueHighlighted: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#000000",
    marginBottom: 4,
  },
  subTitleText: {
    fontSize: 9,
    fontWeight: "bold",
    color: "black",
    marginBottom: 4,
  },
  paymentDetailsTable: {
    flexDirection: "column",
    marginBottom: 10,
    border: "1px solid #dee2e6",
    borderRadius: 5,
  },
  paymentDetailRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 4,
    borderBottom: "1px solid #dee2e6",
  },
  paymentDetailLabel: {
    fontSize: 9,
    fontWeight: "bold",
    color: "#212529",
  },
  paymentDetailValue: {
    fontSize: 9,
    color: "#212529",
  },
  totalMaintenanceValue: {
    marginTop: 10,
    borderRadius: 5,
    marginBottom: 10,
    backgroundColor: "#faa550",
  },
  totalMaintenanceLabel: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#050505",
  },
  totalMaintenanceDetailValue: {
    fontSize: 10,
    color: "#050505",
    fontWeight: "bold",
  },
  tableSection: {
    marginBottom: 8,
    marginTop: 4,
    border: "1px solid black",
    borderRadius: 5,
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#3367b0",
    color: "#ffffff",
  },
  tableCellHeader: {
    fontSize: 8,
    flex: 1,
    fontWeight: "bold",
    color: "#ffffff",
    padding: 4,
    textAlign: "center",
  },
  tableCell: {
    fontSize: 8,
    flex: 1,
    color: "#212529",
    textAlign: "center",
    padding: 4,
    borderBottom: "1px solid #dee2e6",
  },
  accountData: {
    fontSize: 8,
    color: "#212529",
    padding: 2,
  },
  accountSection: {
    marginTop: 5,
    marginBottom: 8,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  footer: {
    marginTop: 8,
    textAlign: "center",
    fontSize: 8,
    color: "#212529",
  },
  image: {
    width: 90, // Ajustar el ancho de la imagen
    height: 90, // Ajustar la altura de la imagen
    marginLeft: 10, // Margen izquierdo para separarla del texto
  },
  accountInfoContainer: {
    width: "70%", // Ajusta el ancho de la sección de texto
  },
  imageContainer: {
    width: "30%", // Ajusta el ancho del contenedor de la imagen
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  return date.toLocaleDateString();
};

const formatNumber = (number) => {
  return new Intl.NumberFormat('es-PE', {
    style: 'currency',
    currency: 'PEN',
    minimumFractionDigits: 2,
  }).format(number).replace('PEN', 'S/.');
};

const RecibosEmitidosPDF = ({ recibo }) => {
  const mesAnoEmision = capitalizeFirstLetter(format(parseISO(recibo.fecha_emision), 'MMMM yyyy', { locale: es }));

  return (
    <View style={styles.receipt}>
      <View style={styles.receiptHeader}>
        <View style={styles.headerGrid}>
          <View style={styles.headerSection}>
            <Text style={styles.detailValueHighlighted}>Junta de Propietarios</Text>
            <Text style={styles.detailValueHighlighted}>Edificio {recibo.nombre_edificio || 'Desconocido'}</Text>
            <Text style={styles.detailValue}>{recibo.direccion_edificio || 'Desconocido'}</Text>
            <Text style={styles.detailValue}>{recibo.distrito_edificio || 'Desconocido'}</Text>
          </View>
          <View style={styles.headerSection}>
            <Text style={styles.headerTitle}>Recibo de Mantenimiento</Text>
            <Text style={styles.headerDate}>{mesAnoEmision}</Text>
            <Text style={styles.detailValue}>Número de Recibo: {recibo.numero_recibo}</Text>
            <Text style={styles.detailValue}>Fecha de Emisión: {formatDate(recibo.fecha_emision)}</Text>
            <Text style={styles.detailValue}>Fecha de Vencimiento: {formatDate(recibo.fecha_vencimiento)}</Text>
          </View>
          <View style={styles.headerSection}></View>
          <View style={styles.headerSection}>
            <Text style={styles.detailValue}>Nombre: {recibo.nombre_propietario}</Text>
            <Text style={styles.detailValue}>Apellido: {recibo.apellido_propietario}</Text>
            <Text style={styles.detailValue}>Departamento: {recibo.numero_departamento}</Text>
          </View>
        </View>
      </View>

      <Text style={styles.subTitleText}>A.- Conceptos de la cuota del Mes:</Text>
      <View style={styles.paymentDetailsTable}>
        <View style={styles.paymentDetailRow}>
          <Text style={styles.paymentDetailLabel}>Cuota Mantenimiento:</Text>
          <Text style={styles.paymentDetailValue}>{formatNumber(recibo.monto_cuota)}</Text>
        </View>
        <View style={styles.paymentDetailRow}>
          <Text style={styles.paymentDetailLabel}>Monto Agua:</Text>
          <Text style={styles.paymentDetailValue}>{formatNumber(recibo.costo_total)}</Text>
        </View>
      </View>

      <View style={styles.totalMaintenanceValue}>
        <View style={styles.paymentDetailRow}>
          <Text style={styles.totalMaintenanceLabel}>Total Recibo Mantenimiento:</Text>
          <Text style={styles.totalMaintenanceDetailValue}>{formatNumber(recibo.total_recibo_mantenimiento)}</Text>
        </View>
      </View>

      <Text style={styles.subTitleText}>Consumo de Agua</Text>
      <View style={styles.tableSection}>
        <View style={styles.tableHeader}>
          <Text style={styles.tableCellHeader}>Lectura Anterior</Text>
          <Text style={styles.tableCellHeader}>Lectura Actual</Text>
          <Text style={styles.tableCellHeader}>Consumo (m³)</Text>
          <Text style={styles.tableCellHeader}>Costo Total (Soles)</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableCell}>{recibo.lectura_anterior}</Text>
          <Text style={styles.tableCell}>{recibo.lectura_actual}</Text>
          <Text style={styles.tableCell}>{recibo.consumo_m3}</Text>
          <Text style={styles.tableCell}>{formatNumber(recibo.costo_total)}</Text>
        </View>
      </View>

      <View style={styles.accountSection}>
        {/* Sección de información de la cuenta */}
        <View style={styles.accountInfoContainer}>
          <Text style={styles.subTitleText}>Datos de la Cuenta</Text>
          <Text style={styles.accountData}>Nombre: {recibo.nombre_junta_propietario}</Text>
          <Text style={styles.accountData}>RUC: {recibo.ruc}</Text>
          <Text style={styles.accountData}>Banco: {recibo.nombre_banco}</Text>
          <Text style={styles.accountData}>Número de Cuenta: {recibo.numero_cuenta}</Text>
          <Text style={styles.accountData}>Número de Cuenta CCI: {recibo.numero_cuenta_cci}</Text>
          {recibo.tipoCuenta === "Cuenta Recaudadora" && (
            <Text style={styles.accountData}>Código: {recibo.numero_departamento}</Text>
          )}
        </View>

         {/* Imagen del contómetro */}
         {recibo.url_imagen_contometro && (
          <View style={styles.imageContainer}>
            <Image style={styles.image} src={recibo.url_imagen_contometro} />
          </View>
        )}
      </View>

      {/* Mensaje de comprobante de pago */}
      <View style={styles.footer}>
        <Text>Enviar el comprobante de pago al correo cobranzas@cariqlo.com</Text>
      </View>

     
    </View>
  );
};

export default RecibosEmitidosPDF;
