import { useState, useRef } from 'react';
import axios from 'axios';

const useStep2 = (id_empresa, id_edificio) => {
  const [departamentos, setDepartamentos] = useState([]);
  const hotTableRefAsignacion = useRef(null);

  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Token de autorización no encontrado.');
    }
    return { Authorization: `Bearer ${token}` };
  };

  const saveAsignacion = async () => {
    if (!hotTableRefAsignacion.current || !hotTableRefAsignacion.current.hotInstance) {
      console.error('Handsontable instance is not ready');
      return false;
    }

    const dataToSave = hotTableRefAsignacion.current.hotInstance.getData();
    const departamentosData = [];
    const propietariosData = [];
    const cocherasData = [];
    const depositosData = [];

    try {
      for (let row of dataToSave) {
        if (!row[0] || !row[1]) continue;

        const departamento = {
          id_edificio,
          id_empresa,
          numero_departamento: parseInt(row[0]),
          piso_departamento: 1,
          vista_departamento: 'Vista Desconocida',
          tipo_departamento: 'Tipo Desconocido',
          tipo_uso: 'Uso Desconocido',
          area: 0.0,
          numero_habitaciones: 1,
          numero_banios: 1,
          estado: true,
        };
        departamentosData.push(departamento);

        const propietario = {
          id_edificio,
          id_empresa,
          nombre_propietario: row[1],
          apellido_propietario: row[2] || 'Apellido Desconocido',
          email_propietario: row[3] || 'email@desconocido.com',
          celular_propietario: row[4] || '000000000',
          tipo_propietario: 'Propietario',
          fecha_nacimiento: '1990-01-01',
          genero_propietario: 'Masculino',
          estado: true,
        };
        propietariosData.push(propietario);

        const cochera = {
          id_edificio,
          id_empresa,
          numero_cochera: parseInt(row[5]) || 0,
          piso_cochera: 'Sótano 1',
          area_cochera: 15.0,
          estado: true,
        };
        cocherasData.push(cochera);

        const deposito = {
          id_edificio,
          id_empresa,
          numero_deposito: parseInt(row[6]) || 0,
          piso_deposito: 'Sótano 1',
          area_deposito: 5.0,
          estado: true,
        };
        depositosData.push(deposito);
      }

      // Guardar departamentos
      await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos/insertar`,
        departamentosData,
        { headers: getAuthHeaders() }
      );

      // Guardar propietarios
      await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/propietarios/insertar`,
        propietariosData,
        { headers: getAuthHeaders() }
      );

      // Guardar cocheras
      await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/cocheras/insertar`,
        cocherasData,
        { headers: getAuthHeaders() }
      );

      // Guardar depósitos
      await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/depositos/insertar`,
        depositosData,
        { headers: getAuthHeaders() }
      );

      // Obtener la lista actualizada de departamentos
      const response = await axios.get(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos`,
        { headers: getAuthHeaders() }
      );

      if (response.status === 200) {
        setDepartamentos(response.data);
      }

      return true;
    } catch (error) {
      console.error('Error al guardar asignaciones:', error);
      return false;
    }
  };

  return {
    departamentos,
    setDepartamentos,
    hotTableRefAsignacion,
    saveAsignacion,
  };
};

export default useStep2;
