import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './css/inicioSesion.css'; 

const InicioSesion = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);  
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setErrorMessage("");
        setLoading(true);
    
        try {
            const response = await axios.post("https://app.propia.pe/apiRest/usuarios/autenticar", {
                email,
                password
            });
    
            if (response.data && response.data.token) {
                const token = response.data.token;
                const userRole = response.data.rol.toUpperCase();
                const id_empresa = response.data.id_empresa; // Puede ser null para ROLE_ADMIN_MAESTRO
                
                // Limpiar cualquier dato de edificio previo
                sessionStorage.removeItem("currentBuilding");
                sessionStorage.removeItem("id_edificio");
                sessionStorage.removeItem("nombre_edificio");

                // Almacenar token, rol y empresa en localStorage
                localStorage.setItem("token", token);
                localStorage.setItem("role", userRole);
                localStorage.setItem("id_empresa", id_empresa); // Puede ser null

                // Redireccionar según el rol del usuario
                if (userRole === "ROLE_ADMIN_MAESTRO") {
                    navigate(`/admin-console`);
                } else if (userRole === "ROLE_SUPER_ADMIN" || userRole === "ROLE_SUB_ADMIN") {
                    // Si hay un id_empresa, obtener los detalles de la empresa
                    if (id_empresa) {
                        const empresaResponse = await axios.get(`https://app.propia.pe/apiRest/empresas/obtener/${id_empresa}`, {
                            headers: { Authorization: `Bearer ${token}` }
                        });
        
                        const empresa = empresaResponse.data;
                        localStorage.setItem("nombre_empresa", empresa.nombre_empresa); // Almacenar nombre_empresa
                        navigate(`/empresa/${empresa.id_empresa}/${empresa.nombre_empresa}/home`);
                    } else {
                        setErrorMessage("No se ha podido encontrar la empresa asociada.");
                    }
                } else {
                    setErrorMessage("Rol no autorizado.");
                }

                setLoading(false);
            } else {
                setErrorMessage("Error en la autenticación.");
                setLoading(false);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setErrorMessage("Credenciales inválidas.");
            } else {
                setErrorMessage("Error al iniciar sesión. Por favor, intenta más tarde.");
            }
            setLoading(false);
        }
    };
    
    return (
        <div className="login-container">
            <div className="login-content">
                <div className="login-image">
                    <img src="/assets/inicioSesion.jpg" alt="Visual Login" />
                </div>
                <div className="login-form">
                    <h2>Iniciar Sesión</h2>
                    <form onSubmit={handleLogin}>
                        <div className="form-group">
                            <label htmlFor="email">Correo electrónico</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                placeholder="Ingresa tu correo"
                                disabled={loading} 
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Contraseña</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                placeholder="Ingresa tu contraseña"
                                disabled={loading} 
                            />
                        </div>
                        {loading && <p>Cargando...</p>} 
                        {errorMessage && <p className="error-message">{errorMessage}</p>} 
                        <button type="submit" className="btn-login" disabled={loading}> 
                            {loading ? "Cargando..." : "Iniciar Sesión"}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default InicioSesion;
