import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchAndCalculate = (selectedMonth, selectedYear, id_empresa, id_edificio) => {
  const [loading, setLoading] = useState(true);
  const [lecturas, setLecturas] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [consumoAguaData, setConsumoAguaData] = useState([]);
  const [consumosAguaSoles, setConsumosAguaSoles] = useState([]);
  const [consumoTotalM3, setConsumoTotalM3] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, [selectedMonth, selectedYear, id_empresa, id_edificio]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error("Token no encontrado. Por favor, inicia sesión.");

      const [
        lecturaResponse,
        departamentosResponse,
        consumoAguaResponse,
        consumoAguaSolesResponse
      ] = await Promise.all([
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/lecturas_agua`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/departamentos`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/consumos_agua`, {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/consumos_agua_soles`, {
          headers: { Authorization: `Bearer ${token}` },
        })
      ]);

      setLecturas(lecturaResponse.data);
      setDepartamentos(departamentosResponse.data);
      setConsumoAguaData(consumoAguaResponse.data);
      setConsumosAguaSoles(consumoAguaSolesResponse.data);

      calcularTotalConsumoM3(
        lecturaResponse.data,
        departamentosResponse.data,
        selectedMonth,
        selectedYear
      );

      setLoading(false);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
      setError(
        error.response?.data?.message || "Hubo un error al obtener los datos."
      );
      setLoading(false);
    }
  };

  const calcularTotalConsumoM3 = (lecturas, departamentos, selectedMonth, selectedYear) => {
    if (!lecturas.length || !departamentos.length) {
      setConsumoTotalM3(0);
      return;
    }

    const mesAnterior = selectedMonth - 1 === 0 ? 12 : selectedMonth - 1;
    const añoAnterior = selectedMonth - 1 === 0 ? selectedYear - 1 : selectedYear;

    const total = departamentos.reduce((acumulado, dep) => {
      const lecturaActual = lecturas.find(l => 
        l.id_departamento === dep.id_departamento &&
        new Date(l.fecha_lectura).getMonth() + 1 === selectedMonth &&
        new Date(l.fecha_lectura).getFullYear() === selectedYear
      );

      const lecturaAnterior = lecturas.find(l => 
        l.id_departamento === dep.id_departamento &&
        new Date(l.fecha_lectura).getMonth() + 1 === mesAnterior &&
        new Date(l.fecha_lectura).getFullYear() === añoAnterior
      );

      const consumoPorM3Dep = lecturaActual 
        ? lecturaActual.lectura_agua - (lecturaAnterior ? lecturaAnterior.lectura_agua : 0)
        : 0;

      return acumulado + consumoPorM3Dep;
    }, 0);

    setConsumoTotalM3(total);
  };

  const getCostoTotalByDepartamento = (id_departamento) => {
    const consumo = consumosAguaSoles.find(c => c.id_departamento === id_departamento);
    return consumo ? consumo.costo_total : 0;
  };

  return {
    loading,
    error,
    lecturas,
    departamentos,
    consumoAguaData,
    consumosAguaSoles,
    consumoTotalM3,
    getCostoTotalByDepartamento,
    fetchData
  };
};

export default useFetchAndCalculate;
