import React, { useState, useCallback } from 'react';
import { Box, Card, CardContent, CardActions, CircularProgress, Grid, IconButton, InputLabel, MenuItem, Select, Typography, Alert } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import InformeEconomicoDialog from './InformeEconomicoDialog';
import InformeEconomicoPDF from './InformeEconomicoPDF';
import FlujoDialog from './FlujoDialog'; 
import useFetchData from '../Hook/hookEgresos';
import useFetchIngresos from '../Hook/HookIngresos';
import useInfoEdificios from '../Hook/HookInfoEdificios';
import useFetchSaldoInicial from "../Hook/HookSaldoInicial";
import PedidosDialog from "./PedidosDialog";
import PedidosPDF from './PedidosPDF'; // Importa correctamente PedidosPDF


const Informes = () => {
  const { id_empresa, id_edificio } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [openFlujoDialog, setOpenFlujoDialog] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [openPedidosDialog, setOpenPedidosDialog] = useState(false);

const handleOpenPedidosDialog = () => setOpenPedidosDialog(true);
const handleClosePedidosDialog = () => setOpenPedidosDialog(false);


  const {
    loading: loadingEgresos,
    administracionData,
    serviciosData,
    personalData,
    pagosPersonalData,
    gastosBancariosData,
    gastosVariosData,
    mantenimientosData,
  } = useFetchData(selectedMonth, selectedYear, id_empresa, id_edificio);

  const {
    loading: loadingEdificios,
    edificiosData,
    juntaPropietarioData,
  } = useInfoEdificios(id_empresa, id_edificio);

  const { 
    ingresosRecibosData,
    ingresosOtrosData,
    ingresosCuotaExtraData,
    ingresosMorasData,
    loading: loadingIngresos,
  } = useFetchIngresos(selectedMonth, selectedYear, id_empresa, id_edificio);

  const {
    loadingSaldoInicial,
    saldoInicialData,
    error: errorSaldoInicial,
  } = useFetchSaldoInicial(id_empresa, id_edificio);

  const isLoading = [
    loadingEgresos,
    loadingIngresos,
    loadingEdificios,
    loadingSaldoInicial,
  ].some(Boolean);

  

  

  const monthOptions = Array.from({ length: 12 }, (_, index) => ({
    value: index + 1,
    label: format(new Date(2000, index), 'LLLL', { locale: es }),
  }));

  const yearOptions = Array.from({ length: 10 }, (_, index) => ({
    value: new Date().getFullYear() - index,
    label: new Date().getFullYear() - index,
  }));

  const filterDataByDate = useCallback((dataArray) => {
    return dataArray.filter(item => {
      if (!item.fecha) return false; // Ignorar si no hay fecha
      const itemDate = parseISO(item.fecha);
      if (isNaN(itemDate)) return false; // Ignorar fechas inválidas
      const isSameMonth = selectedMonth === (itemDate.getMonth() + 1);
      const isSameYear = selectedYear === itemDate.getFullYear();
      return isSameMonth && isSameYear; // Filtrar por mes y año seleccionados
    });
  }, [selectedMonth, selectedYear]);
  
  
  

  // Filtrar los datos de egresos específicamente para Pedidos
  const allEgresosData = [
    ...administracionData,
    ...serviciosData,
    ...personalData,
    ...gastosBancariosData,
    ...gastosVariosData,
    ...mantenimientosData,
  ];
  
  const filteredEgresosData = filterDataByDate(allEgresosData);
  
  
  

  const handleDownloadPedidosPDF = useCallback(async () => {
    try {
      const doc = (
        <PedidosPDF egresosData={filteredEgresosData} selectedMonth={selectedMonth} selectedYear={selectedYear} />
      );
      const blob = await pdf(doc).toBlob();
      saveAs(blob, `Pedidos_${selectedYear}-${selectedMonth}.pdf`);
    } catch (error) {
      console.error("Error generando el PDF de Pedidos:", error);
      alert("Ocurrió un error al generar el PDF.");
    }
  }, [filteredEgresosData, selectedMonth, selectedYear]);
  

  const administracionFilteredData = filterDataByDate(administracionData);
  const serviciosFilteredData = filterDataByDate(serviciosData);
  const personalFilteredData = filterDataByDate(personalData);
  const gastosVariosFilteredData = filterDataByDate(gastosVariosData);
  const ingresosRecibosFilteredData = filterDataByDate(ingresosRecibosData).filter(item => !item.es_pago_atrasado);
  const ingresosOtrosFilteredData = filterDataByDate(ingresosOtrosData);
  const ingresosCuotaExtraFilteredData = filterDataByDate(ingresosCuotaExtraData);
  const ingresosCobranzaCuotasAtrasadasData = filterDataByDate(ingresosRecibosData).filter(item => item.es_pago_atrasado);
  const ingresosMorasFilteredData = filterDataByDate(ingresosMorasData);
  const gastosBancariosFilteredData = filterDataByDate(gastosBancariosData);
  const mantenimientosFilteredData = filterDataByDate(mantenimientosData);
  const saldoInicialFilteredData = filterDataByDate(saldoInicialData);

  const pagosPersonalFilteredData = filterDataByDate(pagosPersonalData).map(item => ({
    ...item,
    nombre: item.nombre,
    tipoPersonal: item.tipoPersonal,
  }));

  const totalIngresosMantenimiento = ingresosRecibosFilteredData.reduce((total, item) => total + item.monto, 0);

  const cardColors = {
    economico: '#1e88e5',
    ingresosEgresos: '#43a047',
    porDefinir: '#ffb300',
    flujos: '#a0439a',
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenFlujoDialog = () => {
    setOpenFlujoDialog(true);
  };

  const handleCloseFlujoDialog = () => {
    setOpenFlujoDialog(false);
  };

  const handleDownloadPDF = useCallback(async () => {
    if (isLoading) {
      alert('Please wait, data is still loading.');
      return;
    }

    try {
      const doc = (
        <InformeEconomicoPDF
          totalIngresosMantenimiento={totalIngresosMantenimiento}
          ingresosCuotaExtraData={ingresosCuotaExtraFilteredData}
          ingresosOtrosData={ingresosOtrosFilteredData}
          ingresosCobranzaCuotasAtrasadasData={ingresosCobranzaCuotasAtrasadasData}
          ingresosMorasData={ingresosMorasFilteredData}
          personalData={pagosPersonalFilteredData}
          serviciosData={serviciosFilteredData}
          administracionData={administracionFilteredData}
          gastosVariosData={gastosVariosFilteredData}
          gastosBancariosData={gastosBancariosFilteredData}
          mantenimientosData={mantenimientosFilteredData}
          saldoInicialData={saldoInicialFilteredData}
          edificiosData={edificiosData}
          juntaPropietarioData={juntaPropietarioData}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
        />
      );
      const blob = await pdf(doc).toBlob();
      saveAs(blob, `InformeEconomico_${selectedYear}-${selectedMonth}.pdf`);
    } catch (error) {
      console.error("Failed to generate PDF:", error);
      alert("An error occurred while generating the PDF. Check the console for more information.");
    }
  }, [
    isLoading,
    totalIngresosMantenimiento,
    ingresosCuotaExtraFilteredData,
    ingresosOtrosFilteredData,
    ingresosCobranzaCuotasAtrasadasData,
    ingresosMorasFilteredData,
    pagosPersonalFilteredData, 
    serviciosFilteredData,
    administracionFilteredData,
    gastosVariosFilteredData,
    gastosBancariosFilteredData,
    mantenimientosFilteredData,
    saldoInicialFilteredData,
    edificiosData,
    juntaPropietarioData,
    selectedMonth,
    selectedYear,
  ]);

  return (
    <Box sx={{ padding: '20px' }}>
      {errorSaldoInicial && <Alert severity="error">{`Error fetching saldo inicial data: ${errorSaldoInicial}`}</Alert>}
      <Box display="flex" gap="1rem" marginBottom="1rem" justifyContent="flex-start">
        <InputLabel>Mes:</InputLabel>
        <Select
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
          size="small"
        >
          {monthOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <InputLabel>Año:</InputLabel>
        <Select
          value={selectedYear}
          onChange={(e) => setSelectedYear(parseInt(e.target.value))}
          size="small"
        >
          {yearOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Card elevation={3} style={{ backgroundColor: cardColors.economico }}>
            <CardContent>
              <Typography variant="h5" align="center" style={{ color: '#fff' }}>
                Informe económico
              </Typography>
            </CardContent>
            <CardActions disableSpacing style={{ justifyContent: 'center', paddingBottom: '16px' }}>
              <IconButton aria-label="previsualizar" style={{ color: '#fff' }} onClick={handleOpenDialog}>
                <VisibilityIcon />
              </IconButton>
              <IconButton aria-label="descargar PDF" style={{ color: '#fff' }} onClick={handleDownloadPDF}>
                <GetAppIcon />
              </IconButton>
            </CardActions>
          </Card>
        </Grid>

        {/* Otros cards */}
        <Grid item xs={12} sm={4}>
          <Card elevation={3} style={{ backgroundColor: cardColors.ingresosEgresos }}>
            <CardContent>
              <Typography variant="h5" align="center" style={{ color: '#fff' }}>
                Ingresos y egresos
              </Typography>
            </CardContent>
            <CardActions disableSpacing style={{ justifyContent: 'center', paddingBottom: '16px' }}>
              <IconButton aria-label="previsualizar" style={{ color: '#fff' }} onClick={handleOpenDialog}>
                <VisibilityIcon />
              </IconButton>
              <IconButton aria-label="descargar PDF" style={{ color: '#fff' }} onClick={handleDownloadPDF}>
                <GetAppIcon />
              </IconButton>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card elevation={3} style={{ backgroundColor: cardColors.porDefinir }}>
            <CardContent>
              <Typography variant="h5" align="center" style={{ color: "#fff" }}>
                Pedidos
              </Typography>
            </CardContent>
            <CardActions style={{ justifyContent: "center", paddingBottom: "16px" }}>
              <IconButton style={{ color: "#fff" }} onClick={handleOpenPedidosDialog}>
                <VisibilityIcon />
              </IconButton>
              <IconButton style={{ color: "#fff" }} onClick={handleDownloadPedidosPDF}>
                <GetAppIcon />
              </IconButton>
            </CardActions>
          </Card>
        </Grid>


        <Grid item xs={12} sm={4}>
          <Card elevation={3} style={{ backgroundColor: cardColors.flujos }}>
            <CardContent>
              <Typography variant="h5" align="center" style={{ color: '#fff' }}>
                Flujos
              </Typography>
            </CardContent>
            <CardActions disableSpacing style={{ justifyContent: 'center', paddingBottom: '16px' }}>
              <IconButton aria-label="previsualizar" style={{ color: '#fff' }} onClick={handleOpenFlujoDialog}>
                <VisibilityIcon />
              </IconButton>
              <IconButton aria-label="descargar PDF" style={{ color: '#fff' }} onClick={handleDownloadPDF}>
                <GetAppIcon />
              </IconButton>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      {isLoading && <CircularProgress style={{ display: 'block', margin: '0 auto' }} />}

      <InformeEconomicoDialog
        open={openDialog}
        onClose={handleCloseDialog}
        administracionData={administracionFilteredData}
        serviciosData={serviciosFilteredData}
        personalData={pagosPersonalFilteredData}
        gastosVariosData={gastosVariosFilteredData}
        ingresosRecibosData={ingresosRecibosFilteredData}
        ingresosOtrosData={ingresosOtrosFilteredData}
        ingresosCuotaExtraData={ingresosCuotaExtraFilteredData}
        ingresosCobranzaCuotasAtrasadasData={ingresosCobranzaCuotasAtrasadasData}
        ingresosMorasData={ingresosMorasFilteredData}
        gastosBancariosData={gastosBancariosFilteredData}
        mantenimientosData={mantenimientosFilteredData}
        saldoInicialData={saldoInicialFilteredData}
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
      />

      <PedidosDialog
        open={openPedidosDialog}
        onClose={handleClosePedidosDialog}
        egresosData={filteredEgresosData}
        loading={isLoading}
      />



      <FlujoDialog
        open={openFlujoDialog}
        onClose={handleCloseFlujoDialog}
        ingresosRecibosData={ingresosRecibosData}
        ingresosCuotaExtraData={ingresosCuotaExtraData}
        ingresosOtrosData={ingresosOtrosData}
        ingresosCobranzaCuotasAtrasadasData={ingresosCobranzaCuotasAtrasadasData}
        ingresosMorasData={ingresosMorasData}
        personalData={personalData}
        serviciosData={serviciosData}
        administracionData={administracionData}
        gastosVariosData={gastosVariosData}
        gastosBancariosData={gastosBancariosData}
        mantenimientosData={mantenimientosData}
        saldoInicialData={saldoInicialData}
        selectedYear={selectedYear}
      />
    </Box>
  );
};

export default Informes;
