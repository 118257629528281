import React, { useRef, useEffect, useState } from 'react';
import Handsontable from 'handsontable';
import 'handsontable/dist/handsontable.full.css';
import axios from 'axios';

const RegistroMasivo = ({ idEdificio }) => {
    const hotTableRef = useRef(null);
    const [hotInstance, setHotInstance] = useState(null);

    useEffect(() => {
        const initialData = new Array(15).fill(0).map(() => ['', '', '', '', '', '', '', '', '']);

        const hot = new Handsontable(hotTableRef.current, {
            data: initialData,
            rowHeaders: true,
            colHeaders: [
                'Número de Departamento', 'Piso del Departamento', 'Vista del Departamento', 
                'Tipo de Departamento', 'Tipo de Uso', 'Área', 'Número de Habitaciones', 
                'Número de Baños'
            ],
            columns: [
                {}, {}, {}, {}, {}, {}, {}, {}
            ],
            dropdownMenu: true,
            filters: true,
            manualRowResize: true,
            manualColumnResize: true,
            contextMenu: true,
            stretchH: 'all',
            width: '100%',
            height: 500,
            minRows: 30,
            minCols: 9,
            licenseKey: 'non-commercial-and-evaluation'
        });

        setHotInstance(hot);

        return () => {
            hot.destroy();
        };
    }, []);

    const handleSaveData = async () => {
        const dataToSave = hotInstance.getData();
        try {
            for (let row of dataToSave) {
                // Asegúrate de que cada fila tenga un valor para el id_edificio
                if (!row[0]) continue; // Si no hay número de departamento, no intentes guardar esa fila

                const departamentoData = {
                    id_edificio: idEdificio, // Ahora se pasa directamente al nivel superior
                    numero_departamento: row[0],
                    piso_departamento: row[1],
                    vista_departamento: row[2],
                    tipo_departamento: row[3],
                    tipo_uso: row[4],
                    area: row[5],
                    numero_habitaciones: row[6],
                    numero_baños: row[7],
                    estado: true
                };
                
                await axios.post('https://app.propia.pe/apiRest/departamentos/insertar', departamentoData);
            }
            alert('Datos agregados exitosamente');
        } catch (error) {
            console.error('Error al agregar los datos:', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div>
            <form id="frm_importar_residentes">
                <div className="modal-body" style={{ overflow: 'auto', height: '500px' }}>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div ref={hotTableRef}></div>
                        </div>
                    </div>
                </div>
                <button type="button" onClick={handleSaveData}>Guardar Datos</button>
            </form>
        </div>
    );
};

export default RegistroMasivo;
