import { useState } from "react";
import axios from "axios";

const usePagosPersonalBase = (id_empresa, id_edificio) => {
  const [uploading, setUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [error, setError] = useState(null);

  const getHeaders = () => {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token no encontrado.");
    return { Authorization: `Bearer ${token}`, "Content-Type": "application/json" };
  };

  const uploadBoucher = async (imageFile) => {
    if (!imageFile) {
      setError("Por favor, selecciona una imagen.");
      return null;
    }

    if (!imageFile.type.startsWith("image/") || imageFile.size === 0) {
      setError("Por favor, selecciona un archivo de imagen válido.");
      return null;
    }

    setUploading(true);
    setError(null);

    try {
      const uniqueId = crypto.randomUUID();
      const fileKey = `pagos_personal_base/${uniqueId}_${imageFile.name}`;

      // Obtener URL pre-firmada desde el backend
      const presignedUrlResponse = await axios.post(
        `https://app.propia.pe/apiRest/s3/upload`,
        { fileKey },
        { headers: getHeaders() }
      );

      const presignedUrl = presignedUrlResponse.data;

      // Subir imagen a S3
      await axios.put(presignedUrl, imageFile, {
        headers: { "Content-Type": imageFile.type || "application/octet-stream" },
      });

      const uploadedUrl = presignedUrl.split("?")[0];
      setImageUrl(uploadedUrl);
      return uploadedUrl;
    } catch (err) {
      setError(err.response?.data || err.message || "Error al subir el boucher de pago.");
      return null;
    } finally {
      setUploading(false);
    }
  };

  const savePagoPersonalBase = async (pagoData, imageFile) => {
    try {
      let uploadedUrl = pagoData.url_boucher_pago;

      // Subir la imagen si existe un archivo
      if (imageFile) {
        uploadedUrl = await uploadBoucher(imageFile);
        if (!uploadedUrl) throw new Error("Error al subir el boucher de pago.");
      }

      const dataConBoucher = {
        ...pagoData,
        url_boucher_pago: uploadedUrl,
      };

      const response = await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/pagos_personal_base/insertar`,
        dataConBoucher,
        { headers: getHeaders() }
      );

      return response.data;
    } catch (err) {
      setError(err.response?.data || err.message || "Error al guardar el pago de personal.");
      return null;
    }
  };

  const modificarPagoPersonalBase = async (pagoData, imageFile) => {
    try {
      if (!pagoData.id_pago_base) {
        throw new Error("El campo 'id_pago_base' es obligatorio para modificar un pago.");
      }

      let uploadedUrl = pagoData.url_boucher_pago;

      if (imageFile) {
        uploadedUrl = await uploadBoucher(imageFile);
        if (!uploadedUrl) throw new Error("Error al subir el boucher de pago.");
      }

      const dataConBoucher = {
        ...pagoData,
        url_boucher_pago: uploadedUrl,
      };

      const response = await axios.put(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/pagos_personal_base/modificar`,
        dataConBoucher,
        { headers: getHeaders() }
      );

      return response.data;
    } catch (err) {
      setError(err.response?.data || err.message || "Error al modificar el pago de personal.");
      return null;
    }
  };

  return {
    uploading,
    imageUrl,
    error,
    uploadBoucher,
    savePagoPersonalBase,
    modificarPagoPersonalBase,
  };
};

export default usePagosPersonalBase;
