import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, Select, MenuItem, InputLabel, useTheme, CircularProgress } from '@mui/material';
import axios from 'axios';
import { tokens } from '../../theme';
import { useFetchEmpresas } from '../Hook/HookEmpresas';
import useFetchPersonalData from '../Hook/HookPersonal';
import useServiciosUpload from "../Hook/egresos/useServiciosUpload";
import usePagosPersonalBase from "../Hook/egresos/usePagosPersonalBase";
import useGastosBancariosUpload from "../Hook/egresos/useGastosBancariosUpload";
import useMantenimientosUpload from "../Hook/egresos/useMantenimientosUpload";
import useGastosMensualesUpload from "../Hook/egresos/useGastosMensualesUpload"; // Hook para gastos mensuales
import EmpresasForm from './EmpresasForm';
import ServiciosForm from './ServiciosForm';
import GastosBancariosForm from './GastosBancariosForm';
import GastosVariosForm from './GastosVariosForm';
import MantenimientoForm from './MantenimientoForm';
import GastosMensualesForm from './GastosVariosForm'; // Formulario de gastos mensuales
import RemuneracionesForm from './RemuneracionesForm';

const RegistroEgresosDialog = ({ open, onClose, id_empresa, id_edificio, onAddSuccess, setAlert }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [registroTipo, setRegistroTipo] = useState('');
    const [formData, setFormData] = useState({});
    const [tiposServicios, setTiposServicios] = useState([]);
    const [comprobante, setComprobante] = useState(null); // Manejo del archivo comprobante
    const [loading, setLoading] = useState(false);

    const { empresas, fetchEmpresas } = useFetchEmpresas(setAlert);
    const { personal, fetchAllPersonalData } = useFetchPersonalData(id_empresa, id_edificio);
    const { saveServicioPublico, uploading: uploadingServicio } = useServiciosUpload(id_empresa, id_edificio);
    const { savePagoPersonalBase, uploading: uploadingPersonal } = usePagosPersonalBase(id_empresa, id_edificio);
    const { saveGastoBancario, uploading: uploadingGastosBancarios } = useGastosBancariosUpload(id_empresa, id_edificio);
    const { saveMantenimiento, uploading: uploadingMantenimiento } = useMantenimientosUpload(id_empresa, id_edificio);
    const { saveGastosMensuales, uploading: uploadingGastosMensuales } = useGastosMensualesUpload(id_empresa, id_edificio);

    useEffect(() => {
        if (!registroTipo) return;

        switch (registroTipo) {
            case 'Empresas':
                fetchEmpresas(localStorage.getItem('token'));
                break;
            case 'Servicios':
                fetchTiposServicios();
                break;
            case 'Remuneraciones':
                fetchAllPersonalData();
                break;
            default:
                break;
        }
    }, [registroTipo, id_empresa, id_edificio]);

    const fetchTiposServicios = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('Token de autorización no encontrado.');
            const response = await axios.get('https://app.propia.pe/apiRest/tipo_servicios/obtener', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setTiposServicios(response.data);
        } catch (error) {
            console.error('Error al obtener los tipos de servicios:', error);
            setAlert({ open: true, severity: 'error', message: 'Error al obtener los tipos de servicios.' });
        }
    };

    const handleRegistroTipoChange = (event) => {
        setRegistroTipo(event.target.value);
        setFormData({});
        setComprobante(null); // Resetear el archivo seleccionado
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (file) => {
        setComprobante(file);
    };

    const validateFormData = () => {
        if (!registroTipo) {
            setAlert({ open: true, severity: 'warning', message: 'Por favor, selecciona un tipo de registro.' });
            return false;
        }
    
        switch (registroTipo) {
            case 'Servicios':
                if (!formData.id_tipo_servicio || !formData.fecha_pago_servicio_publico || !formData.monto_servicio_publico || !comprobante) {
                    setAlert({ open: true, severity: 'warning', message: 'Completa todos los campos obligatorios para Servicios Públicos.' });
                    return false;
                }
                break;
            case 'Remuneraciones':
                if (!formData.id_personal || !formData.fecha_pago || !formData.monto_base || !comprobante) {
                    setAlert({ open: true, severity: 'warning', message: 'Completa todos los campos obligatorios para Remuneraciones.' });
                    return false;
                }
                break;
            case 'Gastos Bancarios':
                if (!formData.descripcion?.trim() || !formData.fecha_gasto || !formData.monto_gasto || !comprobante) {
                    setAlert({ open: true, severity: 'warning', message: 'Completa todos los campos obligatorios para Gastos Bancarios.' });
                    return false;
                }
                break;
            case 'Mantenimiento':
                if (!formData.tipo_mantenimiento?.trim() || !formData.concepto_mantenimiento?.trim() || !formData.fecha_mantenimiento || !formData.importe_mantenimiento || !comprobante) {
                    setAlert({ open: true, severity: 'warning', message: 'Completa todos los campos obligatorios para Mantenimiento.' });
                    return false;
                }
                break;
            case 'Gastos Mensuales':
                if (!formData.concepto_gasto_mensual?.trim() || !formData.fecha_gasto_mensual || !formData.importe_gasto_mensual || !comprobante) {
                    setAlert({ open: true, severity: 'warning', message: 'Completa todos los campos obligatorios para Gastos Mensuales.' });
                    return false;
                }
                break;
            default:
                break;
        }
        return true;
    };

    const handleSubmit = async () => {
        if (!validateFormData()) return;
        setLoading(true);

        try {
            let result;
            if (registroTipo === 'Servicios') {
                result = await saveServicioPublico(formData, comprobante);
            } else if (registroTipo === 'Remuneraciones') {
                result = await savePagoPersonalBase(formData, comprobante);
            } else if (registroTipo === 'Gastos Bancarios') {
                result = await saveGastoBancario(formData, comprobante);
            } else if (registroTipo === 'Mantenimiento') {
                result = await saveMantenimiento(formData, comprobante);
            } else if (registroTipo === 'Gastos Mensuales') {
                result = await saveGastosMensuales([formData], comprobante);
            }

            if (result) {
                onAddSuccess();
                onClose();
                setAlert({ open: true, severity: 'success', message: 'Egreso registrado exitosamente.' });
            }
        } catch (error) {
            console.error('Error al registrar el egreso:', error);
            setAlert({ open: true, severity: 'error', message: 'Error al registrar el egreso.' });
        } finally {
            setLoading(false);
        }
    };

    const renderFormFields = () => {
        switch (registroTipo) {
            case 'Empresas':
                return <EmpresasForm empresas={empresas} formData={formData} handleInputChange={handleInputChange} />;
            case 'Servicios':
                return <ServiciosForm tiposServicios={tiposServicios} formData={formData} handleInputChange={handleInputChange} handleFileChange={handleFileChange} />;
            case 'Remuneraciones':
                return <RemuneracionesForm personal={personal} formData={formData} handleInputChange={handleInputChange} handleFileChange={handleFileChange} />;
            case 'Gastos Bancarios':
                return <GastosBancariosForm formData={formData} handleInputChange={handleInputChange} handleFileChange={handleFileChange} />;
            case 'Gastos Varios':
                return <GastosVariosForm formData={formData} handleInputChange={handleInputChange} handleFileChange={handleFileChange} />;
            case 'Mantenimiento':
                return <MantenimientoForm formData={formData} handleInputChange={handleInputChange} handleFileChange={handleFileChange} />;
            case 'Gastos Mensuales':
                return <GastosMensualesForm formData={formData} handleInputChange={handleInputChange} handleFileChange={handleFileChange} />;
            default:
                return null;
        }
    };

    return (
        <Modal open={open} onClose={() => { onClose(); setRegistroTipo(''); setFormData({}); setComprobante(null); }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <h2>Registrar Egreso</h2>
                <InputLabel>Tipo de Registro</InputLabel>
                <Select value={registroTipo} onChange={handleRegistroTipoChange} fullWidth margin="dense">
                    <MenuItem value="Empresas">Empresas</MenuItem>
                    <MenuItem value="Servicios">Servicios</MenuItem>
                    <MenuItem value="Remuneraciones">Remuneraciones</MenuItem>
                    <MenuItem value="Mantenimiento">Mantenimiento</MenuItem>
                    <MenuItem value="Gastos Bancarios">Gastos Bancarios</MenuItem>                   
                    <MenuItem value="Gastos Mensuales">Gastos Varios</MenuItem>
                </Select>
                {renderFormFields()}
                <Box mt={2}>
                    {loading || uploadingServicio || uploadingPersonal || uploadingGastosBancarios || uploadingMantenimiento || uploadingGastosMensuales ? (
                        <CircularProgress />
                    ) : (
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            style={{ backgroundColor: colors.blueAccent[700], color: colors.primary.contrastText }}
                            fullWidth
                        >
                            Guardar
                        </Button>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default RegistroEgresosDialog;
