import React, { useState } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';

const PagarDeuda = ({ deuda, cerrarModal, onPagoExitoso }) => {
  const [montoPagado, setMontoPagado] = useState('');
  const [fechaPago, setFechaPago] = useState('');

  const handleSubmit = async () => {
    // Verificar que se hayan ingresado el monto a pagar y la fecha de pago
    if (!montoPagado || !fechaPago) {
      alert("Por favor, ingrese el monto a pagar y la fecha de pago.");
      return;
    }

    // Calcular el nuevo saldo pendiente y el estado de la deuda
    const pago = parseFloat(montoPagado);
    const nuevoMontoPagado = parseFloat(deuda.monto_pagado) + pago;
    const nuevoSaldo = Math.max(0, parseFloat(deuda.monto_original) + parseFloat(deuda.monto_mora) - nuevoMontoPagado);
    const estadoDeuda = nuevoSaldo <= 0;
    const estado = nuevoSaldo <= 0 ? 'pagada' : (nuevoMontoPagado > 0 ? 'parcialmente_pagada' : 'pendiente');


    // Preparar la deuda actualizada
    const deudaActualizada = {
        ...deuda,
        monto_pagado: nuevoMontoPagado,
        saldo_pendiente: nuevoSaldo,
        monto_mora: nuevoSaldo > 0 ? deuda.monto_mora : 0, // Establecer monto_mora a 0 si no hay saldo pendiente
        fecha_ultimo_pago: fechaPago,
      estado_deuda: estadoDeuda,
      estado: estado
    };

    // Enviar la deuda actualizada al servidor
    try {
      await axios.put('https://app.propia.pe/apiRest/deudas/modificar', deudaActualizada);
      if (onPagoExitoso) {
        onPagoExitoso(); // Actualizar la lista de deudas en el componente padre
      }
      cerrarModal(); // Cerrar el modal de pago
    } catch (error) {
      console.error('Error al actualizar la deuda:', error);
      // Manejar el error adecuadamente
    }
  };

  return (
    <Dialog open={Boolean(deuda)} onClose={cerrarModal}>
      <DialogTitle>Pagar Deuda</DialogTitle>
      <DialogContent>
        <TextField
          label="Monto a Pagar"
          type="number"
          fullWidth
          value={montoPagado}
          onChange={(e) => setMontoPagado(e.target.value)}
          required
        />
        <TextField
          label="Fecha de Pago"
          type="date"
          fullWidth
          value={fechaPago}
          onChange={(e) => setFechaPago(e.target.value)}
          required
          InputLabelProps={{ shrink: true }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={cerrarModal}>Cancelar</Button>
        <Button onClick={handleSubmit} color="primary">Pagar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PagarDeuda;
