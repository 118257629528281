import { useState } from 'react';
import axios from 'axios';

export const useFetchEmpresas = (setAlert) => {
    const [empresas, setEmpresas] = useState([]);

    const fetchEmpresas = async (token) => {
        try {
            if (!token) throw new Error("Token de autorización no encontrado.");
            const response = await axios.get('https://app.propia.pe/apiRest/empresas/obtener', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setEmpresas(response.data);
        } catch (error) {
            console.error("Error al obtener las empresas:", error);
            setAlert({ open: true, severity: 'error', message: 'Error al obtener las empresas.' });
        }
    };

    return { empresas, fetchEmpresas };
};
