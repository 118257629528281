import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardMedia,
} from "@mui/material";
import axios from "axios";
                      

const EgresosImagenDialog = ({ open, onClose, egreso, onUploadSuccess }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);                           

  useEffect(() => {
    // Configurar la URL de previsualización cuando se abre el modal
    setPreviewUrl(egreso?.url || "");
  }, [egreso]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file)); // Mostrar previsualización
      setError(null); // Limpiar errores previos al seleccionar un nuevo archivo
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setError("Por favor, selecciona una imagen.");
      return;
    }

    try {
      setUploading(true);
      setError(null);

      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token de autorización no encontrado.");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };

      // Crear un objeto FormData para enviar la imagen al backend
      const formData = new FormData();
      formData.append("file", selectedFile);

      // Subir la imagen al backend
      const response = await axios.post(
        `https://app.propia.pe/apiRest/egresos/${egreso?.id}/upload`,
        formData,
        { headers }
      );

      if (response.data?.url) {
        setPreviewUrl(response.data.url); // Actualizar la previsualización con la nueva URL
        onUploadSuccess(response.data.url); // Notificar éxito al componente padre
        setSelectedFile(null); // Limpiar archivo seleccionado
      } else {
        throw new Error("No se recibió una URL válida del servidor.");
      }
    } catch (err) {
      console.error("Error al subir la imagen:", err);
      setError("Error al subir la imagen. Verifica tu conexión o inténtalo nuevamente.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
  <DialogTitle>
    {previewUrl ? "Actualizar Imagen del Egreso" : "Subir Imagen del Egreso"}
  </DialogTitle>
  <DialogContent>
    {uploading ? (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    ) : error ? (
      <Alert severity="error">{error}</Alert>
    ) : (
      <Box display="flex" flexDirection="column" alignItems="center" gap="20px">
        {/* Mostrar previsualización o mensaje de ausencia de imagen */}
        <Card sx={{ width: '100%', maxWidth: 600, marginBottom: "1rem" }}>
          {previewUrl ? (
            <CardMedia
              component="img"
              sx={{
                height: "400px",
                objectFit: "contain", // Asegura que toda la imagen sea visible
                backgroundColor: "#f0f0f0", // Fondo neutro para imágenes con transparencia
              }}
              image={previewUrl}
              alt={`Comprobante del egreso - ${egreso?.tipo || "Desconocido"}`}
            />
          ) : (
            <Box
              sx={{
                height: 400,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#f0f0f0",
              }}
            >
              <Typography color="textSecondary">
                No hay imagen disponible para este egreso.
              </Typography>
            </Box>
          )}
        </Card>

        {/* Input para seleccionar archivo */}
        <input
          accept="image/*"
          type="file"
          style={{ display: "none" }}
          id="upload-button"
          onChange={handleFileChange}
        />
        <label htmlFor="upload-button">
          <Button variant="outlined" component="span" color="primary">
            Seleccionar Imagen
          </Button>
        </label>
      </Box>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={onClose} color="secondary">
      Cancelar
    </Button>
    <Button
      onClick={handleUpload}
      color="primary"
      disabled={!selectedFile || uploading}
    >
      {uploading ? <CircularProgress size={24} /> : "Subir"}
    </Button>
  </DialogActions>
</Dialog>

  );
};

export default EgresosImagenDialog;
