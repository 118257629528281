import React from 'react';
import { Box, Typography, colors } from '@mui/material';
import { styled, useTheme } from '@mui/system';

const List = styled('ul')(({ theme }) => ({
  marginTop: theme.spacing(2),
  paddingLeft: theme.spacing(4),
}));

const ListItem = styled('li')(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const Home = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  const Title = styled(Typography)({
    fontSize: '2.5rem',
    fontWeight: 'bold',
    color: mode === 'dark' ? colors.grey[300] : colors.blue[700],
  });

  const Description = styled(Typography)({
    fontSize: '1.5rem',
    paddingTop: '0.5rem',    
    color: mode === 'dark' ? colors.grey[300] : colors.blue[700],
    
  });

  const StyledList = styled(List)({
    fontSize: '1rem',    
    color: mode === 'dark' ? colors.grey[300] : colors.blue[700],
  });

  return (
    <Box m="30px">
      <Title variant="h2" color="primary">
        Bienvenido Rogger!
      </Title>
      <Description variant="body1" color="primary">
        Soy Sofia tu asistente impulsada con inteligencia artificial, soy el mejor software para administradores de edificios y condominios.
        Ahora podrás gestionar la contabilidad y la administración de tus edificios desde un solo lugar.
      </Description>
      <StyledList>
        <ListItem>Emite recibos de mantenimiento</ListItem>
        <ListItem>Registra ingresos y egresos</ListItem>
        <ListItem>Lleva el control del flujo de caja</ListItem>
        <ListItem>Administra una lista de deudores</ListItem>
        <ListItem>Programa el calendario de mantenimiento</ListItem>
        <ListItem>Disfruta de una aplicación para propietarios y mucho más</ListItem>
      </StyledList>
      <Description variant="h3" color="primary">
        Más información en admintech@gmail.com.
      </Description>
    </Box>
  );
};

export default Home;
