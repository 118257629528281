import { useState, useEffect } from 'react';
import axios from 'axios';

const useInfoEdificios = (id_empresa, id_edificio) => {
  const [loading, setLoading] = useState(true);
  const [edificiosData, setEdificiosData] = useState(null);
  const [juntaPropietarioData, setJuntaPropietarioData] = useState(null);
  const [cuentasBancariasData, setCuentasBancariasData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      if (!id_empresa) {
        setError("Faltan parámetros de empresa.");
        setLoading(false);
        return;
      }

      let token = localStorage.getItem('token');
      if (!token) {
        setError("Token de autorización no encontrado. Por favor, inicia sesión nuevamente.");
        setLoading(false);
        return;
      }

      if (!token.startsWith('Bearer ')) {
        token = `Bearer ${token}`;
      }

      try {
        const headers = { Authorization: token };

        const [
          edificiosResponse,
          juntaPropietariosResponse,
          cuentasBancariasResponse,
          tiposCuentasResponse
        ] = await Promise.all([
          axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios`, { headers }),
          axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/junta_propietarios`, { headers }),
          axios.get(`https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/${id_edificio}/cuentas_bancarias`, { headers }),
          axios.get('https://app.propia.pe/apiRest/tipo_cuentas/obtener', { headers })
        ]);

        // Procesar la información del edificio
        const edificioInfo = id_edificio
          ? edificiosResponse.data.find(edificio => edificio.id_edificio === parseInt(id_edificio)) || {}
          : edificiosResponse.data || {};

        // Procesar la información de la junta de propietarios (primer elemento del array)
        const juntaPropietarioInfo = juntaPropietariosResponse.data[0] || {};

        // Procesar las cuentas bancarias y los tipos de cuenta (primer elemento del array)
        const cuentaBancaria = cuentasBancariasResponse.data[0] || {};
        
        let cuentaBancariaInfo = {};
        if (Object.keys(cuentaBancaria).length > 0) {
          const tipoCuenta = tiposCuentasResponse.data.find(
            tipo => tipo.id_tipo_cuenta === cuentaBancaria.id_tipo_cuenta
          );
          cuentaBancariaInfo = {
            nombreBanco: cuentaBancaria.nombre_banco || 'No disponible',
            numeroCuenta: cuentaBancaria.numero_cuenta || 'No disponible',
            numeroCuentaCCI: cuentaBancaria.numero_cuenta_cci || 'No disponible',
            tipoCuenta: tipoCuenta ? tipoCuenta.nombre_tipo_cuenta : 'Desconocido'
          };
        }

        setEdificiosData({
          nombreEdificio: edificioInfo.nombre_edificio || 'No disponible',
          direccionEdificio: edificioInfo.direccion_edificio || 'No disponible',
          distritoEdificio: edificioInfo.distrito_edificio || 'No disponible',
          tipoPropiedad: edificioInfo.tipoPropiedad?.nombre_tipo_propiedad || 'No disponible',
          telefono: edificioInfo.telefono_edificio || 'No disponible',
          fechaConstruccion: edificioInfo.fecha_construccion || 'No disponible',
          cantidadDepartamentos: edificioInfo.cantidad_departamentos || 'No disponible',
          cantidadSotanos: edificioInfo.cantidad_sotanos || 'No disponible',
          cantidadCocheras: edificioInfo.cantidad_cocheras || 'No disponible',
          cantidadPisos: edificioInfo.cantidad_pisos || 'No disponible',
          urlImagen: edificioInfo.url_imagen_edificio || ''
        });

        setJuntaPropietarioData({
          nombreJuntaPropietario: juntaPropietarioInfo.nombre_junta_propietario || 'No disponible',
          ruc: juntaPropietarioInfo.ruc || 'No disponible'
        });

        setCuentasBancariasData(cuentaBancariaInfo);
      } catch (err) {
        if (err.response && err.response.status === 403) {
          setError("No tienes permisos para acceder a este recurso. Verifica tu sesión.");
        } else {
          setError("Error al obtener los datos. Revisa tu conexión o el estado de la API.");
        }
        console.error("Error al obtener los datos:", err);
      } finally {
        setLoading(false);
      }
    };

    if (id_empresa) {
      fetchData();
    }
  }, [id_empresa, id_edificio]);

  return {
    loading,
    edificiosData,
    juntaPropietarioData,
    cuentasBancariasData,
    error
  };
};

export default useInfoEdificios;
