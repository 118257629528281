import React, { useState } from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, Box, CircularProgress, Alert, IconButton, Modal, Snackbar } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { tokens } from "../../theme";
import BuildingIcon from '@mui/icons-material/Business';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PhoneIcon from '@mui/icons-material/Phone';
import EventIcon from '@mui/icons-material/Event';
import HomeIcon from '@mui/icons-material/Home';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LayersIcon from '@mui/icons-material/Layers';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import GroupIcon from '@mui/icons-material/Group';
import BankIcon from '@mui/icons-material/AccountBalance';
import EditIcon from '@mui/icons-material/Edit';
import Edificio1 from '../../scenes/assets/edificios/Edificio1.jpg';
import EditarInformacionEdificio from './EditarInformacionEdificio';
import useInfoEdificios from '../Hook/HookInfoEdificios'; // Asegúrate de que la ruta sea correcta

const EdificioInformacion = () => {
  const { id_empresa, id_edificio } = useParams();
  const theme = useTheme();
  const { mode } = theme.palette;
  const colors = tokens(mode);

  const [openModal, setOpenModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [openAlert, setOpenAlert] = useState(false);

  // Hook para obtener la información del edificio
  const { loading, edificiosData, juntaPropietarioData, cuentasBancariasData, error } = useInfoEdificios(id_empresa, id_edificio);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleSave = () => {
    setAlertMessage('Edificio editado correctamente');
    setAlertSeverity('success');
    setOpenAlert(true);
    handleCloseModal();
  };

  const handleError = (errorMessage) => {
    setAlertMessage(errorMessage);
    setAlertSeverity('error');
    setOpenAlert(true);
  };

  const handleCloseAlert = () => setOpenAlert(false);

  // Muestra el estado de carga mientras se obtienen los datos
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  // Muestra un mensaje de error si hubo un problema al obtener los datos
  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, padding: '2rem', overflowY: 'auto', bgcolor: colors.background }}>
      <Card sx={{ maxWidth: 1200, margin: '0 auto', borderRadius: 2, boxShadow: 3, position: 'relative' }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={5} md={4}>
            <CardMedia
              component="img"
              sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
              image={edificiosData?.urlImagen || Edificio1}
              alt={edificiosData?.nombreEdificio || 'Imagen del edificio'}
            />
          </Grid>
          <Grid item xs={12} sm={7} md={8}>
            <CardContent>
              <Box sx={{ mb: 2, position: 'relative' }}>
                <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: colors.primary }}>
                  {edificiosData?.nombreEdificio || 'Nombre del Edificio'}
                </Typography>
                <IconButton
                  sx={{ position: 'absolute', top: 0, right: 0 }}
                  onClick={handleOpenModal}
                >
                  <EditIcon />
                </IconButton>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <BuildingIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Nombre del Edificio: {edificiosData?.nombreEdificio || 'No disponible'}
                  </Typography>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <ApartmentIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Dirección: {edificiosData?.direccionEdificio || 'No disponible'}
                  </Typography>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <LocationCityIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Distrito: {edificiosData?.distritoEdificio || 'No disponible'}
                  </Typography>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <PhoneIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Teléfono: {edificiosData?.telefono || 'No disponible'}
                  </Typography>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <EventIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Fecha de Construcción: {edificiosData?.fechaConstruccion || 'No disponible'}
                  </Typography>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <HomeIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Cantidad de Departamentos: {edificiosData?.cantidadDepartamentos || 'No disponible'}
                  </Typography>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    <LayersIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Cantidad de Sótanos: {edificiosData?.cantidadSotanos || 'No disponible'}
                  </Typography>
                </Grid>
              </Grid>
              <Box sx={{ mt: 4 }}>
                    <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', color: colors.primary }}>
                      Información de Cuentas Bancarias
                    </Typography>
                    {cuentasBancariasData ? (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" color="textSecondary">
                          <BankIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                          Nombre del Banco: {cuentasBancariasData.nombreBanco || 'No disponible'}
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                          <AccountBalanceWalletIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                          Tipo de Cuenta: {cuentasBancariasData.tipoCuenta || 'No disponible'}
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                          Número de Cuenta: {cuentasBancariasData.numeroCuenta || 'No disponible'}
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                          Número de Cuenta CCI: {cuentasBancariasData.numeroCuentaCCI || 'No disponible'}
                        </Typography>
                      </Box>
                ) : (
                  <Typography variant="body1">No hay cuentas bancarias asociadas a este edificio.</Typography>
                )}
              </Box>
              <Box sx={{ mt: 4 }}>
                    <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', color: colors.primary }}>
                      Junta de Propietarios
                    </Typography>
                    {juntaPropietarioData ? (
                      <>
                        <Typography variant="h6" color="textSecondary" gutterBottom>
                          <GroupIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                          Nombre: {juntaPropietarioData.nombreJuntaPropietario || 'No disponible'}
                        </Typography>
                        <Typography variant="h6" color="textSecondary" gutterBottom>
                          <GroupIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                          RUC: {juntaPropietarioData.ruc || 'No disponible'}
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="body1">No se encontró información de la junta de propietarios.</Typography>
                    )}
                  </Box>
            </CardContent>
          </Grid>
        </Grid>
      </Card>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{ width: 500, bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24, p: 4, mx: 'auto', mt: '10%' }}>
          <EditarInformacionEdificio 
            building={edificiosData}
            juntaPropietarios={juntaPropietarioData}
            cuentasBancarias={cuentasBancariasData}
            onClose={handleCloseModal}
            onSave={handleSave}
            onError={handleError}
          />
        </Box>
      </Modal>

      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EdificioInformacion;
