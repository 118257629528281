import { useState, useEffect } from 'react';
import axios from 'axios';

const useStep1 = (id_empresa) => {
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [tipoEdificio, setTipoEdificio] = useState('');
  const [nombreEdificio, setNombreEdificio] = useState('');
  const [direccionEdificio, setDireccionEdificio] = useState('');
  const [distritoEdificio, setDistritoEdificio] = useState('');
  const [telefonoEdificio, setTelefonoEdificio] = useState('');
  const [fechaConstruccion, setFechaConstruccion] = useState('');
  const [cantidadDepartamentos, setCantidadDepartamentos] = useState(0);
  const [cantidadSotanos, setCantidadSotanos] = useState(0);
  const [cantidadCocheras, setCantidadCocheras] = useState(0);
  const [cantidadPisos, setCantidadPisos] = useState(0);
  const [id_edificio, setIdEdificio] = useState(null);
  const [tiposPropiedad, setTiposPropiedad] = useState([]);

  const getHeaders = () => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('Token no encontrado.');
    return { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
  };

  const fetchTiposPropiedad = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://app.propia.pe/apiRest/tipo_propiedad/obtener', {
        headers: getHeaders(),
      });
      setTiposPropiedad(response.data || []);
    } catch (error) {
      console.error('Error al obtener tipos de propiedad:', error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTiposPropiedad();
  }, []);

  const saveBuilding = async (imagenFile) => {
    if (!tipoEdificio || !nombreEdificio || !direccionEdificio || !distritoEdificio || !telefonoEdificio || !fechaConstruccion) {
      alert('Por favor, completa todos los campos obligatorios.');
      return false;
    }

    let imageUrl = '';

    if (imagenFile) {
      try {
        if (!imagenFile.type.startsWith('image/') || imagenFile.size === 0) {
          alert('Por favor, selecciona un archivo de imagen válido.');
          return false;
        }

        setUploading(true);
        const uniqueId = crypto.randomUUID();
        const fileKey = `edificios/${uniqueId}_${imagenFile.name}`;

        const presignedUrlResponse = await axios.post(
          'https://app.propia.pe/apiRest/s3/upload',
          { fileKey },
          { headers: getHeaders() }
        );

        const presignedUrl = presignedUrlResponse.data;

        await axios.put(presignedUrl, imagenFile, {
          headers: { 'Content-Type': imagenFile.type || 'application/octet-stream' },
        });

        imageUrl = presignedUrl.split('?')[0];
      } catch (error) {
        console.error('Error al subir la imagen:', error.response?.data || error.message);
        alert('Error al subir la imagen.');
        return false;
      } finally {
        setUploading(false);
      }
    }

    const edificioData = {
      tipoPropiedad: { id_tipo_propiedad: tipoEdificio },
      nombre_edificio: nombreEdificio,
      direccion_edificio: direccionEdificio,
      distrito_edificio: distritoEdificio,
      telefono_edificio: telefonoEdificio,
      fecha_construccion: fechaConstruccion,
      cantidad_departamentos: cantidadDepartamentos,
      cantidad_sotanos: cantidadSotanos,
      cantidad_cocheras: cantidadCocheras,
      cantidad_pisos: cantidadPisos,
      url_imagen_edificio: imageUrl,
      estado: true,
    };

    try {
      const response = await axios.post(
        `https://app.propia.pe/apiRest/empresas/${id_empresa}/edificios/insertar`,
        edificioData,
        { headers: getHeaders() }
      );

      if (response.status === 201) {
        setIdEdificio(response.data.id_edificio);
        return true;
      } else {
        console.error('Error en la respuesta del servidor:', response);
        return false;
      }
    } catch (error) {
      console.error('Error al guardar el edificio:', error.response?.data || error.message);
      return false;
    }
  };

  return {
    loading,
    tipoEdificio,
    setTipoEdificio,
    nombreEdificio,
    setNombreEdificio,
    direccionEdificio,
    setDireccionEdificio,
    distritoEdificio,
    setDistritoEdificio,
    telefonoEdificio,
    setTelefonoEdificio,
    fechaConstruccion,
    setFechaConstruccion,
    cantidadDepartamentos,
    setCantidadDepartamentos,
    cantidadSotanos,
    setCantidadSotanos,
    cantidadCocheras,
    setCantidadCocheras,
    cantidadPisos,
    setCantidadPisos,
    id_edificio,
    tiposPropiedad,
    saveBuilding,
    fetchTiposPropiedad,
  };
};

export default useStep1;
