import React, { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import axios from 'axios';

const FormularioEdificios = () => {
  const [tipoEdificio, setTipoEdificio] = useState('');
  const [nombreEdificio, setNombreEdificio] = useState('');
  const [direccionEdificio, setDireccionEdificio] = useState('');
  const [distritoEdificio, setDistritoEdificio] = useState('');
  const [telefonoEdificio, setTelefonoEdificio] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const edificioData = {
      tipo_edificio: tipoEdificio,
      nombre_edificio: nombreEdificio,
      direccion_edificio: direccionEdificio,
      distrito_edificio: distritoEdificio,
      telefono_edificio: telefonoEdificio,
      estado: true,
    };

    try {
      await axios.post('https://app.propia.pe/apiRest/edificios/insertar', edificioData);
      // Restablecer los campos después de enviar el formulario
      setTipoEdificio('');
      setNombreEdificio('');
      setDireccionEdificio('');
      setDistritoEdificio('');
      setTelefonoEdificio('');
      alert('Edificio agregado exitosamente');
    } catch (error) {
      console.error('Error al agregar el edificio:', error);
    }
  };

  return (
    <Box m="20px">
      <h2>Agregar Edificio</h2>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Tipo de Edificio"
          variant="outlined"
          fullWidth
          required
          value={tipoEdificio}
          onChange={(e) => setTipoEdificio(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Nombre de Edificio"
          variant="outlined"
          fullWidth
          required
          value={nombreEdificio}
          onChange={(e) => setNombreEdificio(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Dirección de Edificio"
          variant="outlined"
          fullWidth
          required
          value={direccionEdificio}
          onChange={(e) => setDireccionEdificio(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Distrito de Edificio"
          variant="outlined"
          fullWidth
          required
          value={distritoEdificio}
          onChange={(e) => setDistritoEdificio(e.target.value)}
          margin="normal"
        />
        <TextField
          label="Teléfono de Edificio"
          variant="outlined"
          fullWidth
          required
          value={telefonoEdificio}
          onChange={(e) => setTelefonoEdificio(e.target.value)}
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Agregar
        </Button>
      </form>
    </Box>
  );
};

export default FormularioEdificios;
