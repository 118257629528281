import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField, Button, DialogActions } from '@mui/material';
import axios from "axios";

const EditarCocheras = ({ open, onClose, cochera, onSuccess }) => {
  // Estado inicial basado en la cochera seleccionada
  const [cocheraData, setCocheraData] = useState({
    id_cochera: '',
    id_edificio: '',
    numero_cochera: '',
    piso_cochera: '',
    area_cochera: '',
    estado: true, // Asumiendo que quieres mantener este campo
  });

  // Actualizar el estado con los datos de la cochera seleccionada cuando cambie
  useEffect(() => {
    if (cochera) {
      setCocheraData(cochera);
    }
  }, [cochera]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCocheraData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      await axios.put("https://app.propia.pe/apiRest/cocheras/modificar", cocheraData);
      onSuccess(); // Notificar al componente padre para actualizar la lista
      onClose(); // Cerrar el diálogo
    } catch (error) {
      console.error("Error al modificar la cochera:", error);
      alert("Error al modificar la cochera. Por favor, intente de nuevo.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Editar Cochera</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="numero_cochera"
          label="Número de Cochera"
          type="number"
          fullWidth
          variant="outlined"
          value={cocheraData.numero_cochera}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="piso_cochera"
          label="Piso de Cochera"
          type="text"
          fullWidth
          variant="outlined"
          value={cocheraData.piso_cochera}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="area_cochera"
          label="Área de Cochera (m2)"
          type="number"
          fullWidth
          variant="outlined"
          value={cocheraData.area_cochera}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditarCocheras;
